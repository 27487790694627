


































import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VariantModalDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  setup(_, { emit }) {
    function close() {
      emit('input', false);
    }

    return {
      close,
    };
  },
});
