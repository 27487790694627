import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CustomerDetailsFragmentDoc } from './fragments';
export type LoginMutationVariables = Types.Exact<{
  identityString: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', response?: { __typename?: 'CustomerToken', token?: string | null | undefined, refreshToken: string, token_expires_at: string, refresh_token_expires_at: string } | null | undefined };

export type RegisterMutationVariables = Types.Exact<{
  input: Types.CreateCustomerInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', response?: { __typename?: 'CustomerOutput', customer: { __typename?: 'Customer', id?: number | null | undefined, email?: string | null | undefined, phone_number: string } } | null | undefined };

export type IdentityQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IdentityQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', firstname?: string | null | undefined, lastname?: string | null | undefined, email?: string | null | undefined, dob?: string | null | undefined, gender?: number | null | undefined, phone_number: string, national_id?: string | null | undefined, is_national_id_verified?: boolean | null | undefined, id?: number | null | undefined, wishlists: Array<{ __typename?: 'Wishlist', id?: string | null | undefined } | null | undefined>, seller_data?: { __typename?: 'SellerData', number_of_products_as_seller?: number | null | undefined, rating?: string | null | undefined } | null | undefined, raya_elite_information?: { __typename?: 'RayaEliteInformation', status?: Types.RayaEliteStatus | null | undefined, corporate?: { __typename?: 'Corporate', name?: string | null | undefined, id: number, image_url?: string | null | undefined, mobile_image_url?: string | null | undefined, assigned_seller_ids?: Array<number | null | undefined> | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type RevokeTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RevokeTokenMutation = { __typename?: 'Mutation', response?: { __typename?: 'RevokeCustomerTokenOutput', result: boolean } | null | undefined };

export type RequestResetPasswordPhoneMutationVariables = Types.Exact<{
  identityString: Types.Scalars['String'];
}>;


export type RequestResetPasswordPhoneMutation = { __typename?: 'Mutation', response?: boolean | null | undefined };

export type ResetPasswordMutationVariables = Types.Exact<{
  newPassword: Types.Scalars['String'];
  token: Types.Scalars['String'];
  identityString: Types.Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', response?: string | null | undefined };

export type ResendVerficationCodeMutationVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String'];
  phoneOnly?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type ResendVerficationCodeMutation = { __typename?: 'Mutation', response: boolean };

export type VerifyPhoneNumberMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
  phoneNumber: Types.Scalars['String'];
}>;


export type VerifyPhoneNumberMutation = { __typename?: 'Mutation', response: { __typename?: 'CustomerToken', refreshToken: string, token?: string | null | undefined, refresh_token_expires_at: string, token_expires_at: string } };

export type EditCustomerPhoneNumberMutationVariables = Types.Exact<{
  old: Types.Scalars['String'];
  new: Types.Scalars['String'];
}>;


export type EditCustomerPhoneNumberMutation = { __typename?: 'Mutation', response: boolean };

export type IsSocialAccountQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type IsSocialAccountQuery = { __typename?: 'Query', response: boolean };

export type LoginWithProviderMutationVariables = Types.Exact<{
  token: Types.Scalars['String'];
  provider: Types.SocialProviderEnum;
}>;


export type LoginWithProviderMutation = { __typename?: 'Mutation', response?: { __typename?: 'CustomerToken', token?: string | null | undefined, refreshToken: string, token_expires_at: string, refresh_token_expires_at: string } | null | undefined };

export type SendOtpToGuestUserMutationVariables = Types.Exact<{
  cartId: Types.Scalars['String'];
}>;


export type SendOtpToGuestUserMutation = { __typename?: 'Mutation', response?: { __typename?: 'SendOtpToGuestUserOutput', message: string, otp_sent: boolean } | null | undefined };

export type VerifyGuestUserOtpMutationVariables = Types.Exact<{
  cartId: Types.Scalars['String'];
  otp: Types.Scalars['String'];
}>;


export type VerifyGuestUserOtpMutation = { __typename?: 'Mutation', response?: { __typename?: 'VerifyGuestUserCartOtpOutput', success: boolean } | null | undefined };

export declare const Login: import("graphql").DocumentNode;
export declare const Register: import("graphql").DocumentNode;
export declare const Identity: import("graphql").DocumentNode;
export declare const RevokeToken: import("graphql").DocumentNode;
export declare const RequestResetPasswordPhone: import("graphql").DocumentNode;
export declare const ResetPassword: import("graphql").DocumentNode;
export declare const ResendVerficationCode: import("graphql").DocumentNode;
export declare const VerifyPhoneNumber: import("graphql").DocumentNode;
export declare const EditCustomerPhoneNumber: import("graphql").DocumentNode;
export declare const IsSocialAccount: import("graphql").DocumentNode;
export declare const LoginWithProvider: import("graphql").DocumentNode;
export declare const sendOtpToGuestUser: import("graphql").DocumentNode;
export declare const VerifyGuestUserOtp: import("graphql").DocumentNode;

export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"identityString"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"generateCustomerToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"identityString"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"token_expires_at"}},{"kind":"Field","name":{"kind":"Name","value":"refresh_token_expires_at"}}]}}]}}]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Register"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateCustomerInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"createCustomer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"phone_number"}}]}}]}}]}}]} as unknown as DocumentNode<RegisterMutation, RegisterMutationVariables>;
export const IdentityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Identity"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"customerDetails"}}]}}]}},...CustomerDetailsFragmentDoc.definitions]} as unknown as DocumentNode<IdentityQuery, IdentityQueryVariables>;
export const RevokeTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RevokeToken"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"revokeCustomerToken"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"result"}}]}}]}}]} as unknown as DocumentNode<RevokeTokenMutation, RevokeTokenMutationVariables>;
export const RequestResetPasswordPhoneDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RequestResetPasswordPhone"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"identityString"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"requestPasswordResetSMS"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone"},"value":{"kind":"Variable","name":{"kind":"Name","value":"identityString"}}}]}]}}]} as unknown as DocumentNode<RequestResetPasswordPhoneMutation, RequestResetPasswordPhoneMutationVariables>;
export const ResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"newPassword"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"identityString"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"otpResetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"newPassword"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"otp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"phone_number"},"value":{"kind":"Variable","name":{"kind":"Name","value":"identityString"}}}]}}]}]}}]} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResendVerficationCodeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResendVerficationCode"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phoneNumber"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phoneOnly"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}},"defaultValue":{"kind":"BooleanValue","value":false}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"resendOTP"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"phone_number"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phoneNumber"}}},{"kind":"Argument","name":{"kind":"Name","value":"skip_email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phoneOnly"}}}]}]}}]} as unknown as DocumentNode<ResendVerficationCodeMutation, ResendVerficationCodeMutationVariables>;
export const VerifyPhoneNumberDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"VerifyPhoneNumber"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"phoneNumber"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"verifyCustomer"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"otp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}},{"kind":"Argument","name":{"kind":"Name","value":"username"},"value":{"kind":"Variable","name":{"kind":"Name","value":"phoneNumber"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"refresh_token_expires_at"}},{"kind":"Field","name":{"kind":"Name","value":"token_expires_at"}}]}}]}}]} as unknown as DocumentNode<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>;
export const EditCustomerPhoneNumberDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EditCustomerPhoneNumber"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"old"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"new"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"editCustomerPhoneNumber"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"old_phone_number"},"value":{"kind":"Variable","name":{"kind":"Name","value":"old"}}},{"kind":"Argument","name":{"kind":"Name","value":"new_phone_number"},"value":{"kind":"Variable","name":{"kind":"Name","value":"new"}}}]}]}}]} as unknown as DocumentNode<EditCustomerPhoneNumberMutation, EditCustomerPhoneNumberMutationVariables>;
export const IsSocialAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"IsSocialAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"isSocialAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"username"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}]}]}}]} as unknown as DocumentNode<IsSocialAccountQuery, IsSocialAccountQueryVariables>;
export const LoginWithProviderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LoginWithProvider"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"provider"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SocialProviderEnum"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"logInWithSocialProvider"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"socialProvider"},"value":{"kind":"Variable","name":{"kind":"Name","value":"provider"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"token_expires_at"}},{"kind":"Field","name":{"kind":"Name","value":"refresh_token_expires_at"}}]}}]}}]} as unknown as DocumentNode<LoginWithProviderMutation, LoginWithProviderMutationVariables>;
export const SendOtpToGuestUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"sendOtpToGuestUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"sendOtpToGuestUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"cart_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"otp_sent"}}]}}]}}]} as unknown as DocumentNode<SendOtpToGuestUserMutation, SendOtpToGuestUserMutationVariables>;
export const VerifyGuestUserOtpDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"VerifyGuestUserOtp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"otp"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"verifyGuestUserOtp"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"cart_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"otp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"otp"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<VerifyGuestUserOtpMutation, VerifyGuestUserOtpMutationVariables>;