




































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { useEventBus } from '~/features/events';
import { CustomizableCheckboxValue, PriceTypeEnum } from '~/graphql-types.gen';

export default defineComponent({
  props: {
    insuranceOption: {
      type: Object as () => CustomizableCheckboxValue | null,
      required: true,
    },
    productPrice: {
      type: Number,
      required: true,
    },
  },
  components: {
    ValidationObserver,
  },
  setup(props) {
    const { emit: emitEvent } = useEventBus();

    const insurancePrice = computed(() =>
      props.insuranceOption?.price_type === PriceTypeEnum.Percent
        ? props.productPrice * ((props.insuranceOption?.price || 0) / 100)
        : props.insuranceOption?.price
    );

    return {
      insurancePrice,
      emitEvent,
    };
  },
});
