export default function GlobalAppAlertsPlugin({ store }, inject) {
  function success(message, opts = {}) {
    store.dispatch('alerts/create', { message, type: 'success', ...opts });
  }

  function error(message, opts = {}) {
    store.dispatch('alerts/create', { message, type: 'error', ...opts });
  }

  function info(message, opts = {}) {
    store.dispatch('alerts/create', { message, type: 'info', ...opts });
  }

  function warn(message, opts = {}) {
    store.dispatch('alerts/create', { message, type: 'warning', ...opts });
  }

  // Allows providing a `call-to-action` text and callback
  function withCta({ cta, ctaText }) {
    return {
      success(message) {
        return success(message, { cta, ctaText });
      },
      error(message) {
        return error(message, { cta, ctaText });
      },
      info(message) {
        return info(message, { cta, ctaText });
      },
    };
  }

  const alerts = { withCta };

  inject('success', success);
  inject('error', error);
  inject('warn', warn);
  inject('info', info);
  inject('alerts', alerts);
}
