// move to next input when current input at its max length

export function handleMoveToNextInput(e: any) {
  const target = e.srcElement || e.target;
  const maxLength = parseInt(target.attributes.maxlength.value, 10);
  const myLength = target.value.length;
  if (myLength >= maxLength) {
    let next = target;
    while ((next = next.nextElementSibling)) {
      if (next == null) break;
      if (next.tagName.toLowerCase() === 'input') {
        next.focus();
        next.click();

        break;
      }
    }
  }
  // Move to previous field if empty (user pressed backspace)
  else if (myLength === 0) {
    let previous = target;
    while ((previous = previous.previousElementSibling)) {
      if (previous == null) break;
      if (previous.tagName.toLowerCase() === 'input') {
        previous.focus();
        previous.click();
        break;
      }
    }
  }
}
