import Vue from 'vue';
import GMap from "\u002Fbuilds\u002Frobustastudio\u002Fraya\u002Fb2c\u002Fraya-b2c-frontend\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMap.vue";
import GMapMarker from "\u002Fbuilds\u002Frobustastudio\u002Fraya\u002Fb2c\u002Fraya-b2c-frontend\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapMarker.vue";
import GMapCircle from "\u002Fbuilds\u002Frobustastudio\u002Fraya\u002Fb2c\u002Fraya-b2c-frontend\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapCircle.vue";
import GMapInfoWindow from "\u002Fbuilds\u002Frobustastudio\u002Fraya\u002Fb2c\u002Fraya-b2c-frontend\u002Fnode_modules\u002Fnuxt-gmaps\u002Fcomponents\u002FGMapInfoWindow.vue";
export default(context, inject) =>{
  Vue.component('GMap', GMap);
  Vue.component('GMapMarker', GMapMarker);
  Vue.component('GMapCircle', GMapCircle);
  Vue.component('GMapInfoWindow', GMapInfoWindow);
  inject('GMaps', {apiKey: "AIzaSyBsZVq7oEAPKo8k5XOFxMM4_v731Rtb12o", loaded: false, libraries: undefined})
}