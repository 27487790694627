

























import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { useAuth } from '~/features/auth';
import { useEventBus } from '~/features/events';
import { TRACKING_EVENTS } from '~/features/trackingHandlers';

export default defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: String,
      required: true,
    },
    mobileImage: {
      type: String,
      required: false,
      default: '',
    },
    href: {
      type: String,
      default: '/',
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    priority: {
      type: String,
      default: 'auto',
      validator: (value: string) => ['auto', 'high', 'low'].includes(value),
    },
    decoding: {
      type: String,
      default: 'async',
      validator: (value: string) => ['sync', 'async', 'auto'].includes(value),
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    linkType: {
      type: String,
      default: '',
    },
    cmsKeyEn: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { emit } = useEventBus();
    const { $config } = useContext();
    const { isLoggedIn } = useAuth();

    function trackBannerClicked() {
      const urlArray = props.href.split('/');
      const banner_category = urlArray[urlArray.length - 1];
      emit(TRACKING_EVENTS.BANNER_CLICKED as any, { banner_name: props.title, banner_category });
    }

    function extractInternalAppLink(link: string) {
      if (!link) return link;

      const pattern = new RegExp(`^${$config.appURL}/(en|ar)/(.*)$`);

      const match = link.match(pattern);

      // If there is a match, return the part after "en/"
      if (match) {
        return '/' + match[2];
      } else {
        // If no match, return the full link
        return link;
      }
    }
    const computedHref = computed(() => {
      if (props.linkType === 'cms_page') {
        return `/cms/${props.cmsKeyEn.slice(0, -3)}`;
      }
      if (props.linkType === 'utilities') {
        return isLoggedIn.value ? '/account/bills' : '/bills';
      }
      return extractInternalAppLink(props.href);
    });

    return {
      trackBannerClicked,
      computedHref,
    };
  },
});
