//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppButton',
  props: {
    as: {
      type: String,
      default: 'button',
    },
    /**
     * controls different button styles
     * e.g. default, secondary, inverted, white, outline, danger
     */
    variant: {
      type: String,
      default: 'default',
    },
    ariaLabel: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
