










import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'EliteNavBar',
  setup() {
    const navBarItems = computed(() => {
      return [
        {
          labelKey: 'home',
          url: '/elite-index',
        },
        {
          labelKey: 'myInstallments',
          url: '/account/installments',
        },
        {
          labelKey: 'eliteStore',
          url: '/elite-store',
        },
        {
          labelKey: 'storeLocator',
          url: '/stores',
        },
        {
          labelKey: 'profile',
          url: '/account',
        },
      ];
    });

    return {
      navBarItems,
    };
  },
});
