import mitt from 'mitt';
import type { Handler } from 'mitt';
import { onUnmounted, onMounted } from '@nuxtjs/composition-api';
import { TRACKING_EVENTS } from './trackingHandlers';
import { PAYMENT_METHODS_EVENTS } from './paymentMethods';

let emitter!: ReturnType<typeof mitt>;

type AppEvent =
  | 'ITEM_ADDED'
  | 'TOGGLE_ASIDE_CART'
  | 'TOGGLE_SIDE_MENU'
  | 'AUTH_REQUIRED'
  | 'VERIFY_OTP'
  | 'CLOSE_VERIFY_OTP'
  | 'ACCOUNT_GOT_VERIFIED'
  | 'TOGGLE_SEARCH_MENU'
  | 'SEARCH'
  | 'ORDER_RATED'
  | 'TOGGLE_PAYMENT_PLANS'
  | 'SHOW_LIMIT_MODAL'
  | 'SHOW_INSURANCE_MODAL'
  | 'INSURANCE_CONFIRMED'
  | 'INSURANCE_CONFIRMATION_CANCELLED'
  | TRACKING_EVENTS
  | PAYMENT_METHODS_EVENTS
  | 'CREDIT_LIMIT_ERROR'
  | 'PENDING_APPROVAL'
  | 'CART_VERIFIED'
  | 'CART_DATA_MERGED'
  | 'LOGOUT'
  | 'TOGGLE_ELITE_STORE'
  | 'IS_PRODUCT_PAGE';

export function useEventBus() {
  if (!emitter && !process.server) {
    emitter = mitt();
  }

  function emit(evt: AppEvent, data?: any) {
    if (!emitter || process.server) return;

    return emitter.emit(evt, data);
  }

  function on(evt: AppEvent, cb: Handler<any>) {
    if (!emitter || process.server) return;

    return emitter.on(evt, cb);
  }

  function off(evt: AppEvent, cb: Handler<any>) {
    if (!emitter || process.server) return;

    return emitter.off(evt, cb);
  }

  return {
    emit,
    on,
    off,
  };
}

export function useEvent(evt: AppEvent, callback: Handler<any>) {
  const { on, off } = useEventBus();

  onMounted(() => {
    on(evt, callback);
  });

  onUnmounted(() => {
    off(evt, callback);
  });
}
