import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FlashsaleQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FlashsaleQuery = { __typename?: 'Query', flashsale_offers: Array<{ __typename?: 'Offer', button_text?: string | null | undefined, description?: string | null | undefined, id: number, is_dimmed?: boolean | null | undefined, offer_url?: string | null | undefined, title?: string | null | undefined, link_object_url_key?: string | null | undefined, startTime?: string | null | undefined, endTime?: string | null | undefined, image: string, mobileImage: string } | null | undefined> };

export declare const Flashsale: import("graphql").DocumentNode;

export const FlashsaleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Flashsale"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flashsale_offers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"button_text"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","alias":{"kind":"Name","value":"startTime"},"name":{"kind":"Name","value":"flash_sale_start_time_timezone_adjusted"}},{"kind":"Field","alias":{"kind":"Name","value":"endTime"},"name":{"kind":"Name","value":"flash_sale_end_time_timezone_adjusted"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"is_dimmed"}},{"kind":"Field","name":{"kind":"Name","value":"offer_url"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"link_object_url_key"}},{"kind":"Field","alias":{"kind":"Name","value":"image"},"name":{"kind":"Name","value":"image_url"}},{"kind":"Field","alias":{"kind":"Name","value":"mobileImage"},"name":{"kind":"Name","value":"responsive_image_url"}}]}}]}}]} as unknown as DocumentNode<FlashsaleQuery, FlashsaleQueryVariables>;