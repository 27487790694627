let fetchFn = process.server ? global.fetch : window.fetch;

// Polyfills the fetch API
if (!fetchFn && process.server) {
  fetchFn = require('node-fetch');
}

if (!process.server && !fetchFn) {
  fetchFn = require('unfetch').default;
}

const fetch = fetchFn;

interface JsonRequestInit {
  headers: Record<string, string>;
  body?: Record<string, any>;
  method: 'get' | 'post' | 'patch' | 'put' | 'delete';
}

export function makeJsonRequest(
  uri: string,
  { method, headers, body }: JsonRequestInit = { method: 'get', headers: {} }
) {
  const reqHeaders = mergeHeaders(headers);

  return fetch(uri, {
    method,
    headers: reqHeaders,
    body: body ? JSON.stringify(body) : undefined,
  })
    .catch(err => {
      throw new Error(err);
    })
    .then(res => res.json());
}

function mergeHeaders(headers: Record<string, any> | Headers) {
  return {
    'content-type': 'application/json',
    ...headers,
  };
}

export const fetchRequestFactory = (url: string) => async <
  T extends unknown /* type of request */,
  U extends unknown /* type of response */
>(
  method: string,
  body?: T,
  headers?: Record<string, string>
): Promise<U> => {
  return (
    await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(headers || {}),
      },
      body: body ? JSON.stringify(body) : undefined,
    })
  ).json() as Promise<U>;
};
