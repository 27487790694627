import { computed, ssrRef, useContext } from '@nuxtjs/composition-api';
import LRUCache from 'lru-cache';

// Default TTL of 5 minutes (in milliseconds)
const CACHE_TTL = 25 * 60 * 1000; // 25 minutes  * 60 (convert to seconds) * 1000 (convert to milliseconds)

/**
 * Creates a cache-able SSR ref, the caching only occurs on server-side, client side is unaffected.
 * Helps speed up expensive operations of resources that can be stale for some time like categories and store config.
 * @param key
 * @param value
 * @param ttl
 */
export function useCachedSsrRef<T>(key: string, value: T, ttl: number = CACHE_TTL) {
  const ctx = useContext();
  const ssrContext = ctx.ssrContext as typeof ctx['ssrContext'] & { $serverCache: LRUCache<string, any> };
  const ref = ssrRef(value, key);
  const serverCache = ssrContext?.$serverCache;

  if (!serverCache) {
    return ref;
  }

  if (serverCache.has(key)) {
    ref.value = serverCache.get(key);
  }

  // wrap the SSR ref with computed prop that updates the cache value
  const wrapper = computed<T>({
    get() {
      return ref.value;
    },
    set(value) {
      if (process.server) {
        ssrContext.$serverCache.set(key, value, ttl);
      }

      ref.value = value;
    },
  });

  return wrapper;
}

export function useClearCache() {
  const ctx = useContext();
  const ssrContext = ctx.ssrContext as typeof ctx['ssrContext'] & { $serverCache: LRUCache<string, any> };
  const serverCache = ssrContext?.$serverCache;

  function clear() {
    if (process.server) {
      serverCache.keys().forEach(key => {
        serverCache.del(key);
      });
    }
  }

  return {
    clear,
  };
}
