// import Router from 'vue-router';
import Vue from 'vue';
import AppLink from '@/components/AppLink';

Vue.component('AppLink', AppLink);

// // Takes a path and a locale string and applies the locale prefix if necessary.
// function localizePath(path, locale) {
//   // If a path based object check if its not null and if it has a path prop.
//   if (typeof path === 'object' && path && path.path) {
//     // Localize the string path in the object
//     const newPath = localizePath(path.path, locale);

//     // Return a new localized path object.
//     return {
//       ...path,
//       path: newPath,
//     };
//   }

//   if (typeof path !== 'string' || path.indexOf(`/${locale}`) === 0) {
//     return path;
//   }

//   let localizedPath = path;
//   if (locale && locale !== 'en') {
//     localizedPath = `/${locale}${path}`;
//   }

//   return localizedPath;
// }

// // Finds the current locale from the current router object.
// function resolveLocale(router) {
//   return 'en';
// }

// // Save refs to original router methods.
// const push = Router.prototype.push;
// const replace = Router.prototype.replace;

// // Override the router.push to localize the new path.
// Router.prototype.push = function (path, onComplete, onError) {
//   const locale = resolveLocale(this);

//   return push.call(this, localizePath(path, locale), onComplete, onError);
// };

// // Override the router.replace to localize the new path.
// Router.prototype.replace = function (path, onComplete, onError) {
//   const locale = resolveLocale(this);

//   return replace.call(this, localizePath(path, locale), onComplete, onError);
// };

// export default function UpdateRedirect(ctx) {
//   const redirect = ctx.redirect;
//   ctx.redirect = function localizedRedirect(...args) {
//     const locale = resolveLocale(ctx.app.router);
//     // figure out which part of the args is the URL as the first argument can occasionally be a status code
//     // @example redirect('/path');
//     // @example redirect(302, '/path');
//     const pathIdx = typeof args[0] === 'number' ? 1 : 0;
//     // localize the path
//     args[pathIdx] = localizePath(args[pathIdx], locale);

//     return redirect(...args);
//   };
// }
