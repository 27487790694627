import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { getUser } from '~/features/auth';

export default defineNuxtMiddleware(async function GuestMiddleware({ redirect, app: { i18n } }) {
  const user = await getUser();
  const locale = (i18n as VueI18n).locale;

  if (user) {
    redirect(`/${locale}/account`);
  }
});
