export default {
  egp: 'ج م',
  EGP: 'ج م',
  phone: 'رقم الهاتف',
  email: 'البريد الالكتروني',
  password: 'كلمة المرور',
  fullName: 'الاسم الكامل',
  category: 'الفئة',
  comparisonExceeded: 'لا يمكنك المقارنة بـ 4 عناصر',
  comparisonExceededDescription: 'إذا أضفت هذا المنتج سيتم إزالته الأول',
  invalidComparison: 'المنتجات يجب أن تكون من نفس الفئة',
  invalidComparisonDescription: 'لا يمكنك مقارنة المنتجات من فئات مختلفة، سيتم إزالة المنتجات الأخرى',
  addProduct: 'إضافة منتج',
  validation: {
    required: 'هذا الحقل مطلوب',
    phone: 'يجب أن يحتوي رقم الهاتف على 11 رقم و يبدأ بـ 01',
    email: 'يجب أن يكون البريد الإلكتروني صحيح',
    min: 'يجب أن يحتوي هذا الحقل على الأقل {length} أحرف',
    max: 'يجب ألا يتجاوز هذا الحقل {length} أحرف',
    confirmed: 'هذا الحقل غير متطابق مع حقل التأكيد',
    alpha_spaces: 'يجب أن يحتوي هذا الحقل على أحرف ومسافات فقط',
    max_value: 'يجب ألا يتجاوز هذا الحقل {max}',
    min_value: 'يجب أن يكون هذا الحقل على الأقل أحرف',
    fullName: 'يجب أن يحتوي هذا الحقل على الاسم الأول والاسم الأخير',
    numeric: 'يجب أن يحتوي هذا الحقل على أرقام فقط',
    floor: 'يجب أن يكون الدور بين ٠ إلي ١٥',
    password: 'كلمة السر يجب أن تكون 8 أحرف على الأقل و يجب ان تحتوي على حرف، رقم و رمز خاص',
    nationalId: 'يجب أن يكون رقم البطاقة صحيح',
    nationalIdPicture: 'يرجى رفع الصوره بصيغه jpg أو png',
    supportingDocs: 'يرجى رفع المستندات بصيغه jpg أو png أو pdf',
    arabicName: 'يجب أن يحتوي هذا الحقل على أحرف عربية فقط',
    arabicAlphaNumericWithSymbols: 'يجب أن يحتوي هذا الحقل على أحرف عربية وأرقام ورموز فقط',
    salaryRange: 'يجب أن يكون الراتب بين {min} و {max} جنيه',
    month_year: 'يجب أن يكون هذا الحقل تاريخ صحيح بالصيغة شهر/سنة',
    cc_expiry_date: 'يرجى تحديد تاريخ الانتهاء',
    cc_number: 'هناك خطأ في أول ٦ أرقام تم إدخالهم، برجاء التأكد من رقم الكارت',
    requiredExpiryDate: 'يرجى تحديد تاريخ الانتهاء',
    requiredCardNumber: 'هناك خطأ في أول ٦ أرقام تم إدخالهم، برجاء التأكد من رقم الكارت',
    digits: 'هذا الحقل يجب ان يتكون من {length} ارقام',
    futureDate: 'يجب أن يكون هذا الحقل تاريخ مستقبلي',
    pastDate: 'يجب أن يكون هذا الحقل تاريخ ماضي',
    checkout: {
      requiredShippingMethod: 'يجب عليك اختيار طريقه التوصيل ',
      orderSubmitted: 'تم تنفيذ طلبك بنجاح',
      requiredPaymentMethod: 'يجب عليك اختيار طريقه  الدفع ',
    },
    email_phone: 'يجب أن يكون البريد الإلكتروني أو رقم الهاتف صحيح',
    existing_phone: 'رقم الهاتف مستخدم مسبقا',
    greaterThan: 'الحد الأدنى للدفع المقدم هو {min} جنيه',
    lessThan: 'الحد الأقصى للدفع المقدم هو {max} جنيه',
    iban: 'يرجى إدخال الIBAN بشكل صحيح',
  },
  seo: {
    title: 'Raya Shop',
    description: 'مرحبا بكم في راية شوب',
    categoryTitle: 'أشتري {name} | من راية شوب',
  },
  title: {
    category: ': شراء على الانترنت بأفضل الأسعار في مصر | راية شوب',
    product: ': شراء على الانترنت بأفضل الأسعار في مصر | راية شوب',
  },
  mostPopular: 'الأكثر شعبية',
  fromLowToHigh: 'من اصغر إلى أكبر',
  fromHighToLow: 'من أكبر إلى اصغر',
  toBeCalculated: 'يتم احتسابها لاحقًا',
  free: 'مجانا',
  floor: 'دور {floorNumber}',
  apartment: 'شقة {number}',
  steps: {
    createAccount: 'إنشاء حساب',
    shippingInformation: 'معلومات الشحن',
    paymentPlan: 'خطة الدفع',
    paymentInformation: ' معلومات الدفع',
    installmentsApplication: 'استماره التقسيط',
  },
  cart: {
    cartIsMerging: 'يتم تركيب السلة',
    loggedInRequired: 'يجب عليك التسجيل لتنفيذ المهمه',
    mergedSuccessfully: 'تم تحديث السلة بنجاح',
    genericError: ' ﻻ يمكن دمج السله حاليا السبب : {reason}',
  },
  or: 'أو',
  stockError: 'غير متوفر',
  qtyUnavailable: 'الكمية المطلوبة غير متوفرة، من فضلك قم باختيار كمية أقل',
  maxAllowedQty: 'تتجاوز الكمية المطلوبة الحد الأقصى للكمية المسموح بها في عربة التسوق',
  productsOutOfStock: 'بعض المنتجات في السلة غير متوفرة',
  invalidItemCategory: 'لا يمكنك مقارنة المنتجات من فئات مختلفة',
  discountAsc: 'الخصم تصاعدي',
  discountDesc: 'الخصم تنازلي',
  account: 'الحساب',
  wrongOtp:
    'كلمة المرور لمرة واحدة (OTP) التي أدخلتها غير صحيحة ، يرجى المحاولة مرة أخرى أو يرجى الاتصال بالرقم 16671.',
  error: 'خطأ',
};
