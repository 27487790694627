import { useContext, useMeta, unref } from '@nuxtjs/composition-api';
import { ProductData } from './product';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Category } from './categories';
import { MaybeReactive } from '~/types/utils';
import { keysOf } from '~/utils/collections';
import { useStoreConfig } from '~/features/storeConfig';
import { hash } from '~/utils/text';

interface SeoComposableOptions {
  title?: string | null;
  description?: string | null;
  image?: string | null;
  keywords?: string | null;
  type?: string | null;
}

/**
 * Generates common meta tags for any page.
 */
export function useSeoMeta(opts?: MaybeReactive<SeoComposableOptions>) {
  const { $config, route, app } = useContext();
  const { defaultTitle, defaultDescription, defaultKeywords, title_prefix, title_suffix } = useStoreConfig();
  const currentLocale = (app.i18n as any).locale || 'en';
  const alternateLocale = currentLocale === 'en' ? 'ar-EG' : 'en-US';

  useMeta(() => {
    const { title, description, image, keywords, type = 'website' } = unref(opts || ({} as any));

    const properties = {
      description: description || defaultDescription.value,
      'og:title': title || defaultTitle.value,
      'og:description': description || defaultDescription.value,
      'og:site_name': currentLocale === 'en-US' ? 'Raya B2C' : 'راية',
      'og:type': `og:${type}`,
      'og:locale': currentLocale === 'en' ? 'en-US' : 'ar-EG',
      'og:locale:alternate': alternateLocale,
      'og:url': `${$config.appURL}${route.value.path}`,
      'og:image': image || require('@/assets/img/raya-logo.png'),
      'twitter:card': 'summary_large_image',
      'twitter:title': title || defaultTitle.value,
      'twitter:description': description || defaultDescription.value,
      'twitter:image': image,
      keywords: keywords || defaultKeywords.value,
    };

    const meta = keysOf(properties)
      .filter(prop => properties[prop])
      .map(prop => {
        return {
          hid: prop,
          name: prop,
          property: prop,
          content: properties[prop] as string,
        };
      });

    return {
      title: title || defaultTitle.value,
      titleTemplate: `${title_prefix.value || ''} %s ${title_suffix.value || ''}`.trim(),
      meta,
    };
  });
}

/**
 * Generates common SEO links for pages.
 */
export function useSeoLinks() {
  const { $config, route, app } = useContext();
  const currentLocale = (app.i18n as any).locale || 'en';

  const seoLinks = [
    // Informs crawlers that this page is original page that holds this content
    {
      hid: 'canonical',
      rel: 'canonical',
      href: `${$config.appURL}${route.value.path}`,
    },
    // Tells the crawler that there is an alternate `ar` locale mirror page for this page
    {
      hid: 'alternate-hreflang-ar',
      rel: 'alternate',
      hreflang: 'ar',
      // adds the `ar` prefix only if the current locale is English so we don't end up with double `ar/ar`
      href: `${$config.appURL}${currentLocale === 'en' ? '/ar' : ''}${route.value.path}`,
    },
    // Tells the crawler that there is an alternate `en` locale mirror page for this page
    {
      hid: 'alternate-hreflang-en',
      rel: 'alternate',
      hreflang: 'en',
      // removes the `ar` prefix so it correctly links to the English version
      href: `${$config.appURL}${route.value.path.replace(/^\/ar\//, '/')}`,
    },
  ];

  useMeta({
    link: seoLinks,
    htmlAttrs: { dir: currentLocale === 'en' ? 'ltr' : 'rtl', lang: currentLocale },
  });
}

export function useJsonLd(schema: MaybeReactive<Record<string, any>>) {
  useMeta(() => {
    const minifiedString = JSON.stringify(unref(schema), null, process.env.NODE_ENV === 'production' ? '' : 2);
    const innerHTML = `\n${minifiedString}\n`;
    const hid = `jsonld-${hash(minifiedString)}`;

    return {
      script: [
        {
          innerHTML,
          type: 'application/ld+json',
          hid,
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        [hid]: ['innerHTML'],
      },
    };
  });
}

export function useMetaDataResolver() {
  const {
    categoriesSeoTemplateDescription,
    categoriesSeoTemplateKeywords,
    categoriesSeoTemplateTitle,
    productsSeoTemplateDescription,
    productsSeoTemplateKeywords,
    productsSeoTemplateTitle,
  } = useStoreConfig();

  function productTemplateMapper(productDate: ProductData): Record<string, string | number | undefined | null> {
    return {
      name: productDate.name,
      sku: productDate.sku,
      price: productDate.price,
      brand: productDate.brand?.name,
      stock: productDate.stock,
      meta_title: productDate.meta_title,
      meta_description: productDate.meta_description,
    };
  }

  function categoryTemplateMapper(category: Category): Record<string, string | number | undefined | null> {
    return {
      name: category.name,
      description: category.meta_description,
      slug: category.url_key,
      meta: category.meta_title,
      meta_description: category.meta_description,
      meta_keywords: category.meta_keywords,
      url: category.url,
    };
  }

  function templateMapperToString(
    template: string | null,
    data: Record<string, string | number | undefined | null>
  ): string {
    const re = new RegExp(
      Object.keys(data)
        .map(key => `{${key}}`)
        .join('|'),
      'gi'
    );

    return (
      template?.replace(re, function (matched: string) {
        return data[matched.replace(/\{|\}/gi, '')]?.toString() ?? '';
      }) || ''
    );
  }

  function resolveProductTitleMetaData(product: ProductData): string {
    return templateMapperToString(productsSeoTemplateTitle.value, productTemplateMapper(product));
  }

  function resolveProductDescriptionMetaData(product: ProductData): string {
    return templateMapperToString(productsSeoTemplateDescription.value, productTemplateMapper(product));
  }

  function resolveProductKeywordsMetaData(product: ProductData): string {
    return templateMapperToString(productsSeoTemplateKeywords.value, productTemplateMapper(product));
  }

  function resolveCategoryTitleMetaData(category: Category): string {
    return templateMapperToString(categoriesSeoTemplateTitle.value, categoryTemplateMapper(category));
  }

  function resolveCategoryDescriptionMetaData(category: Category): string {
    return templateMapperToString(categoriesSeoTemplateDescription.value, categoryTemplateMapper(category));
  }

  function resolveCategoryKeywordsMetaData(category: Category): string {
    return templateMapperToString(categoriesSeoTemplateKeywords.value, categoryTemplateMapper(category));
  }

  return {
    resolveCategoryDescriptionMetaData,
    resolveCategoryTitleMetaData,
    resolveCategoryKeywordsMetaData,
    resolveProductDescriptionMetaData,
    resolveProductTitleMetaData,
    resolveProductKeywordsMetaData,
  };
}
