import { getCurrentInstance, onMounted, useContext } from '@nuxtjs/composition-api';
import { IVueI18n } from 'vue-i18n/types';
import useCookies from './cookies';

interface I18nApi {
  $t: IVueI18n['t'];
  $tc: IVueI18n['tc'];
  $d: IVueI18n['d'];
  $n: IVueI18n['n'];
  $te: IVueI18n['te'];
  $i18n: IVueI18n;
}

export function useI18n() {
  const vm = getCurrentInstance()?.proxy as I18nApi;

  vm.$i18n.pluralizationRules = {
    ar: (choice: number) => {
      if (choice === 0) {
        return 0;
      }

      if (choice === 1) {
        return 1;
      }

      if (choice >= 2 && choice <= 10) {
        return 2;
      }
      return 3;
    },
  };

  function t(keyPath: string, values?: Record<string, any> | any[]) {
    return vm.$t(keyPath, values);
  }

  function tc(keyPath: string, choice: number, values?: Record<string, any> | any[]) {
    return vm.$tc(keyPath, choice, values);
  }

  /**
   * Formats a date value to a specific format
   * Available formats are declared in i18n `dateTimeFormats` options in nuxt.config.js
   */
  function d(value: number | Date, format?: 'short') {
    return vm.$d(value, format);
  }

  /**
   * Formats a number value to a specific format
   * Available formats are declared in i18n `numberFormats` options in nuxt.config.js
   */
  function n(value: number, format: 'currency' | 'decimal' | 'percent' | 'plain') {
    return vm.$n(value, format);
  }

  /**
   * checks if a specific key exists
   * @param keyPath the key to check for
   */
  function te(keyPath: string) {
    return vm.$te(keyPath);
  }

  function getLocale() {
    return vm.$i18n.locale;
  }

  return {
    t,
    tc,
    d,
    n,
    te,
    getLocale,
  };
}

/**
 * Stores an I18n cookie for the current active locale, useful for server-side redirection
 */
export function useI18nCookie() {
  const { setCookie } = useCookies();
  const vm = getCurrentInstance()?.proxy as I18nApi;

  onMounted(() => {
    setCookie('i18n_locale', vm.$i18n.locale, {
      expires: 365,
    });
  });
}

/**
 *
 */
export function useSetLocaleToCacheParams() {
  const { app } = useContext();
  function cacheParam(id: string) {
    return (app.i18n as any).locale === 'ar' ? 'ar_' + id : 'en_' + id;
  }

  return {
    cacheParam,
  };
}
/**
 * villus client locale plugin
 */
export function localePlugin() {
  const { app } = useContext();

  return function localePlugin({ opContext }: any) {
    if ((app.i18n as any).locale === 'ar') {
      (opContext.headers as Record<string, any>).Store = `ar`;
      (opContext.headers as Record<string, any>)['Content-Language'] = `ar`;
    }
  };
}

export function useDate() {
  const { getLocale } = useI18n();
  /**
   * Convert month number to month name
   * ex: 1 => January
   */
  const mapActiveMonth = (month: number) => {
    return new Date(0, month - 1).toLocaleString(getLocale(), { month: 'long' });
  };

  return {
    mapActiveMonth,
  };
}
