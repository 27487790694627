import { computed, onMounted, ref } from '@nuxtjs/composition-api';
import { get, set } from 'idb-keyval';
import { intersection } from 'lodash-es';
import { mapProductListing, useCompareProducts } from './products';
import { TRACKING_EVENTS } from './trackingHandlers';
import { useEventBus } from './events';
import { ProductData } from './product';
import { lastItem } from '~/utils/collections';
import { Unpacked } from '~/types/utils';

const DB_KEY = 'compare_skus';
/**
 *  store the skus, category_id ( because we don't need to check categories for all the items in the list everytime we add new one as we can save 1 http rquest  ) in indexedDB
 */

type CompareItem = {
  sku: string;
  categoryIds: number[] | [];
};
const skus = ref<CompareItem[]>([]);
const { emit } = useEventBus();

export function useComparison() {
  const variables = computed(() => ({
    pageSize: 4,
    withAttributes: true,
    // parse all the skus to lowercase ( requirement from the backend)
    filter: {
      sku: { in: skus.value.length <= 0 ? ['no_skus'] : skus.value.map(item => item.sku?.toLocaleLowerCase()) },
    },
  }));

  const { products, isFetching } = useCompareProducts(variables, { fetchOnMount: false });

  onMounted(async () => {
    const items = (await get<CompareItem[]>(DB_KEY)) || [];
    // skip if both lists are empty
    if (!items.length && !skus.value.length) {
      return;
    }

    // this will trigger the fetching
    skus.value = [...new Set(items)];
  });

  return {
    products,
    isFetching,
  };
}

export function useIsInComparison(sku: string) {
  return computed(() => {
    return skus.value.map(item => item.sku).includes(sku);
  });
}

export async function compareItem(sku: CompareItem) {
  /*
   * validation
   * if there is no items in the list, we can add the item to the list
   * if there is only one item in the list, then we compare that item's category with the only already in the list item's category ,
   */

  if (skus.value.length === 0) {
    await updateState([...skus.value, sku]);
    return;
  }

  const lastItem = skus.value[skus.value.length - 1];
  if (intersection(lastItem.categoryIds, sku.categoryIds).length > 0) {
    await updateState([...skus.value, sku]);
    return;
  }

  // if the item's category is not in the list, we can't add it to the list
  throw new Error('invalidItemCategory');
}

export async function removeItem(sku: string, item: ProductData | undefined) {
  const idx = skus.value.map(item => item.sku).indexOf(sku);
  if (idx === -1) {
    return;
  }
  emit(TRACKING_EVENTS.REMOVED_FROM_COMPARE, item);
  await updateState(skus.value.filter((_, index) => index !== idx));
}

export async function clearItems() {
  await updateState([]);
}

async function updateState(items: CompareItem[]) {
  const uniqueSku = [...new Set(items)];
  await set(DB_KEY, uniqueSku);
  skus.value = uniqueSku;
}

const blackListedAttributes = ['story_behind_it'];
export function useCompareItemsDetails() {
  const { products, isFetching } = useComparison();
  const itemsWithAddNodes = computed(() => {
    const items = [...products.value] as (Unpacked<typeof products['value']> | null)[];
    if (items.length < 4) {
      items.push(null);
    }

    return items;
  });
  const attributes = computed(() => {
    const attributes = products.value.reduce(
      (attrs: Record<string, { key: string; title: string; values: string[]; idx: number }>, item, itemIdx) => {
        item.attributes
          ?.filter(attribute => !blackListedAttributes.includes(attribute.key))
          ?.forEach((attr, idx) => {
            // create initial value if it wasn't added to the aggregation before
            if (!attrs[attr.key]) {
              attrs[attr.key] = { key: attr.key, title: attr.label, values: [], idx };
            }

            // Add the item value to the list
            attrs[attr.key].values[itemIdx] = attr.value;
          });

        return attrs;
      },
      {}
    );

    return attributes;
  });

  /**
   * Returns the common category for the compared items
   */
  const categoryURL = computed(() => {
    const firstItem = products.value[0];
    if (!firstItem || !firstItem.categories) {
      return '';
    }

    return lastItem(firstItem.categories)?.url_key;
  });

  /**
   * Returns the stone Details
   */
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const stoneDetails = computed(() => {
    const attributes = products.value.reduce(
      (attrs: Record<string, { key: string; title: string; values: string[]; idx: number }>, item, itemIdx) => {
        item.attributes
          ?.filter(attribute => !blackListedAttributes.includes(attribute.key))
          ?.forEach((attr, idx) => {
            // create initial value if it wasn't added to the aggregation before
            if (!attrs[attr.key]) {
              attrs[attr.key] = { key: attr.key, title: attr.label, values: [], idx };
            }

            // Add the item value to the list
            attrs[attr.key].values[itemIdx] = attr.value;
          });

        return attrs;
      },
      {}
    );

    return attributes;
  });

  /**
   * Returns the general Information
   */
  const GeneralInformationAttributes: Array<keyof ReturnType<typeof mapProductListing>> = ['price'];

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generalInformation = computed(() => {
    const attributes = products.value.reduce(
      (accu: Record<string, { key: string; title: string; values: string[]; idx: number }>, product, itemIdx) => {
        GeneralInformationAttributes.forEach((attr, idx) => {
          if (!accu[attr]) {
            accu[attr] = { key: attr, title: attr, values: [], idx };
          }

          // Add the item value to the list
          accu[attr].values[itemIdx] = product[attr]?.toString() || '-';
        });
        return accu;
      },
      {}
    );

    return attributes;
  });

  return {
    products,
    isFetching,
    itemsWithAddNodes,
    categoryURL,
    generalAttributes: computed(() => ({
      specifications: attributes,
      // stoneDetails,
      // generalInformation,
    })),
  };
}
