import { useEventBus } from './events';
import { useCartAttributes } from './cart';
import { SelectedPaymentMethod, PlaceOrderInput } from '~/graphql-types.gen';

/**
 * @description this one is for the payment methods handling in the checkout
 * @supports valu , payfort, premium
 *
 */

export enum PAYMENT_METHODS_EVENTS {
  PAY_WITH_VALU = 'PAY_WITH_VALU',
  PAY_WITH_PREMIUM = 'PAY_WITH_PREMIUM',
  PAY_WITH_MPGS = 'PAY_WITH_MPGS',
  PAY_WITH_PAYFORT = 'PAY_WITH_PAYFORT',
  PAY_WITH_MPGS_INSTALLMENT = 'PAY_WITH_MPGS_INSTALLMENT',
  PAY_WITH_ACCEPT_VALU = 'PAY_WITH_ACCEPT_VALU',
  PAY_WITH_MOBILE_WALLETS = 'PAY_WITH_MOBILE_WALLETS',
  PAY_WITH_BANK_INSTALLMENTS = 'PAY_WITH_BANK_INSTALLMENTS',
}

/**
 * @description in order to handle and maintain multiple payment/installment method on the cart
 * this api executes payment method function based on the selected payment method
 *
 */
export function useThirdPartyPaymentMethodResolver() {
  function resolvePaymentMethod(paymentMethod: SelectedPaymentMethod) {
    switch (paymentMethod.code) {
      case 'robusta_premium_installment':
        return usePremiumPayment();
      case 'robusta_valu_installment':
        return useValuPayment();
      case 'robusta_accept_valu':
      case 'robusta_accept_souhoola':
      case 'robusta_accept_forsa':
      case 'robusta_accept_contact':
        return useValuAcceptPayment();
      case 'robusta_accept_installments':
        return useBankInstallmentsPayment();
      case 'robusta_accept_mobile_wallets':
        return useMobileWalletsPayment();
      case 'robusta_mpgs_cc':
        return useMpgsPayment();
      case 'robusta_payfort_installments':
        return usePayfortPayment();
      case 'robusta_mpgs_installments':
        return useMpgsInstallmentPayment();
      default:
        return useDefaultPayment();
    }
  }

  return {
    resolvePaymentMethod,
  };
}

export function useValuPayment() {
  const { emit } = useEventBus();

  emit(PAYMENT_METHODS_EVENTS.PAY_WITH_VALU);
}

export function useValuAcceptPayment() {
  const { emit } = useEventBus();

  const { acceptIframeUrl } = useCartAttributes();
  if (!acceptIframeUrl.value) throw new Error('Paymob payments are disabled right now, please try again later!');
  emit(PAYMENT_METHODS_EVENTS.PAY_WITH_ACCEPT_VALU);
}

export function useMobileWalletsPayment() {
  const { emit } = useEventBus();

  emit(PAYMENT_METHODS_EVENTS.PAY_WITH_MOBILE_WALLETS);
}

export function useBankInstallmentsPayment() {
  const { emit } = useEventBus();
  emit(PAYMENT_METHODS_EVENTS.PAY_WITH_BANK_INSTALLMENTS);
}

export function usePayfortPayment() {
  const { emit } = useEventBus();

  emit(PAYMENT_METHODS_EVENTS.PAY_WITH_PAYFORT);
}

export function usePremiumPayment() {
  const { emit } = useEventBus();

  emit(PAYMENT_METHODS_EVENTS.PAY_WITH_PREMIUM);
}

export function useMpgsPayment() {
  const { emit } = useEventBus();
  emit(PAYMENT_METHODS_EVENTS.PAY_WITH_MPGS);
}

export function useMpgsInstallmentPayment() {
  const { emit } = useEventBus();
  emit(PAYMENT_METHODS_EVENTS.PAY_WITH_MPGS_INSTALLMENT);
}

/**
 * this one for the default selected payemnt method that is selected
 */
export function useDefaultPayment(): Partial<PlaceOrderInput> {
  throw new Error('not implemented yet');
}
