import { Context } from '@nuxt/types';
import { isString } from 'lodash-es';
/* eslint-disable camelcase */
import { extend, configure } from 'vee-validate';
import {
  required,
  email,
  min,
  max,
  max_value,
  confirmed,
  alpha_spaces,
  min_value,
  numeric,
  digits,
} from 'vee-validate/dist/rules';

extend('required', required);
extend('email', email);
extend('min', min);
extend('max', max);
extend('confirmed', confirmed);
extend('max_value', max_value);
extend('min_value', min_value);
extend('alpha_spaces', alpha_spaces);
extend('numeric', numeric);
extend('digits', digits);

extend('phone', value => {
  const sanitized = value.replace(/-/g, '');

  return sanitized.length === 11 && sanitized.startsWith('01');
});

extend('iban', value => {
  const ibanPattern = /^[0-9A-Z]*$/;
  if (!ibanPattern.test(value)) {
    return false;
  }

  const symbols = value.trim();
  if (symbols.length < 15 || symbols.length > 34) {
    return false;
  }

  const swapped = symbols.substring(4) + symbols.substring(0, 4);
  const mod97 = swapped.split('').reduce((previousMod: number, char: string) => {
    const value = parseInt(char, 36);
    const factor = value < 10 ? 10 : 100;
    return (factor * previousMod + value) % 97;
  }, 0);

  return mod97 === 1;
});

extend('fullName', (value: string) => {
  // has at least two words
  const words = value.split(' ');
  return !!(words.length >= 2);
});

extend('nationalId', value => {
  return /^[23][\u0600-\u06FF\d]{13}$/.test(value);
});

/**
 * validation on floor from 0 - 15
 */
extend('floor', value => {
  // const regex = /^((1[0-5])|[0-9]|(\u0661[\u0660-\u0665])|[\u0660-\u0669]){1,2}$/;
  return Number(value) <= 15 && Number(value) >= 0;
});

extend('password', value => {
  // Password must contain at least a letter, a number and a special character, and min length of 8
  return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$?!%*#+?&:;=^"_'|\\/(){}<>.-])[A-Za-z\d@$?!%*#+?&:;=^"_'|\\/(){}<>.-]{8,}$/.test(
    value
  );
});

// custom vee-validate rules to return true if the value ${x} - number is greater than or equal to ${param1:number} % of ${param2:number} total price
extend('greaterThan', {
  validate(value: number, args: any) {
    return Number(value) >= Number(args.min);
  },
  params: ['min'],
});

extend('lessThan', {
  validate(value: number, args: any) {
    return Number(value) <= Number(args.max);
  },
  params: ['max'],
});

extend('nationalIdPicture', (value: File[] | string) => {
  if (isString(value)) {
    // check if the value is base64 string
    return /^data:image\/(png|jpg|jpeg);base64,/.test(value);
  }

  const [file] = value;
  return ['image/jpeg', 'image/png'].includes(file.type);
});

extend('supportingDocs', (value: File[]) => {
  const [file] = value;
  return ['image/jpeg', 'image/png', 'application/pdf'].includes(file.type);
});

// arabic name
extend('arabicName', (value: string) => {
  return /^[\u0621-\u064A\u0660-\u0669\s]+$/.test(value);
});

extend('arabicAlphaNumericWithSymbols', (value: string) => {
  return /^[^a-zA-Z]*$/.test(value);
});

// salary range
extend('salaryRange', {
  validate(value: number, args: any) {
    return Number(value) >= Number(args.min) && Number(value) <= Number(args.max);
  },
  params: ['min', 'max'],
});

// date format MM/YYYY
extend('month_year', value => {
  return /^(0[1-9]|1[0-2])\/([0-9]{4})$/.test(value);
});

extend('cc_expiry_date', value => {
  return /^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(value);
});

extend('email_phone', value => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$|^\+?[0-9]{10,}$/.test(
    value
  );
});

extend('futureDate', value => {
  const date = new Date(value);
  const now = new Date();
  return date > now;
});

extend('pastDate', value => {
  const date = new Date(value);
  const now = new Date();
  return date < now;
});

/**
 * validate the bin (first 6 digits) of the credit card number
 * @value - the value of the card number to be validated
 * @args - the array of bins accepted by the selected bank
 */
extend('cc_number', (value: string, args: any) => {
  const numberBin = value.slice(0, 6);
  return args.includes(numberBin);
});
configure({
  useConstraintAttrs: false,
});

export default function VeeValidatePlugin({ app }: Context) {
  configure({
    defaultMessage: (_, values) => {
      return (app.i18n as any).t(`validation.${values._rule_}`, values);
    },
  });
}
