import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SetCustomerRayaEliteCorporateInformationMutationVariables = Types.Exact<{
  corporateId: Types.Scalars['Int'];
  employeeId: Types.Scalars['String'];
  hrLetter: Types.Scalars['String'];
  salary: Types.Scalars['Int'];
}>;


export type SetCustomerRayaEliteCorporateInformationMutation = { __typename?: 'Mutation', setCustomerRayaEliteCorporateInformation: boolean };

export type SetCustomerRayaEliteMedicalIdMutationVariables = Types.Exact<{
  medicalId: Types.Scalars['String'];
}>;


export type SetCustomerRayaEliteMedicalIdMutation = { __typename?: 'Mutation', setCustomerRayaEliteMedicalId: boolean };

export type SetCustomerRayaEliteNationalIdInformationMutationVariables = Types.Exact<{
  nationalIdImageBack: Types.Scalars['String'];
  nationalIdImageFront: Types.Scalars['String'];
}>;


export type SetCustomerRayaEliteNationalIdInformationMutation = { __typename?: 'Mutation', setCustomerRayaEliteNationalIDInformation: boolean };

export type SetCustomerRayaEliteGovernorateAndDistrictIdsMutationVariables = Types.Exact<{
  districtId: Types.Scalars['Int'];
  governorateId: Types.Scalars['Int'];
}>;


export type SetCustomerRayaEliteGovernorateAndDistrictIdsMutation = { __typename?: 'Mutation', setCustomerRayaEliteGovernorateAndDistrictIds: boolean };

export type EliteApplicationFormQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EliteApplicationFormQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', national_id?: string | null | undefined, raya_elite_information?: { __typename?: 'RayaEliteInformation', employee_id?: string | null | undefined, hr_letter?: string | null | undefined, national_id_image_front?: string | null | undefined, national_id_image_back?: string | null | undefined, first_name?: string | null | undefined, personal_image?: string | null | undefined, full_name?: string | null | undefined, medical_id?: string | null | undefined, yearMonthlyIncome?: number | null | undefined, corporate?: { __typename?: 'Corporate', id: number } | null | undefined } | null | undefined } | null | undefined };

export type CustomerPersonalImageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomerPersonalImageQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', raya_elite_information?: { __typename?: 'RayaEliteInformation', personalImage?: string | null | undefined } | null | undefined } | null | undefined };

export type SubmitRayaEliteApplicationMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SubmitRayaEliteApplicationMutation = { __typename?: 'Mutation', submitRayaEliteApplication: boolean };

export type GetCustomerCreditLimitsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerCreditLimitsQuery = { __typename?: 'Query', rayaEliteCreditLimits?: { __typename?: 'EliteCreditLimits', amount?: number | null | undefined, usedAmount?: number | null | undefined, availableAmount?: number | null | undefined } | null | undefined, customer?: { __typename?: 'Customer', national_id?: string | null | undefined, customer_points: { __typename?: 'CustomerPoints', availablePoints?: string | null | undefined } } | null | undefined };

export type GetCustomerActiveInstallmentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerActiveInstallmentQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', upcoming_installments?: { __typename?: 'CustomerInstallmentByMonthOutput', upcoming?: { __typename?: 'CustomerInstallmentByMonth', month?: number | null | undefined, due_date?: number | null | undefined, total_count?: number | null | undefined, is_due?: boolean | null | undefined, installments?: Array<{ __typename?: 'CustomerInstallment', application_id?: string | null | undefined, down_payment_amount?: number | null | undefined, total_months?: number | null | undefined, paid_months?: number | null | undefined, total_amount?: number | null | undefined, paid_amount?: number | null | undefined, bills?: Array<{ __typename?: 'CustomerInstallmentBills', amount?: number | null | undefined, status?: string | null | undefined, due_date?: string | null | undefined, id?: string | null | undefined, penalty?: number | null | undefined } | null | undefined> | null | undefined } | null | undefined> | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type GetCustomerInstallmentsByMonthQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerInstallmentsByMonthQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', all_installments_by_month?: Array<{ __typename?: 'CustomerInstallmentByMonthOutput', upcoming?: { __typename?: 'CustomerInstallmentByMonth', month?: number | null | undefined, due_date?: number | null | undefined, total_count?: number | null | undefined, is_due?: boolean | null | undefined, installments?: Array<{ __typename?: 'CustomerInstallment', application_id?: string | null | undefined, down_payment_amount?: number | null | undefined, total_months?: number | null | undefined, paid_months?: number | null | undefined, total_amount?: number | null | undefined, paid_amount?: number | null | undefined, bills?: Array<{ __typename?: 'CustomerInstallmentBills', amount?: number | null | undefined, status?: string | null | undefined, due_date?: string | null | undefined, id?: string | null | undefined, penalty?: number | null | undefined } | null | undefined> | null | undefined } | null | undefined> | null | undefined } | null | undefined } | null | undefined> | null | undefined } | null | undefined };

export type SetCustomerRayaElitePersonalImageMutationVariables = Types.Exact<{
  personalImage: Types.Scalars['String'];
}>;


export type SetCustomerRayaElitePersonalImageMutation = { __typename?: 'Mutation', setCustomerRayaElitePersonalImage: boolean };

export type CheckIfCustomerHasEliteAccountMutationVariables = Types.Exact<{
  nationalId: Types.Scalars['String'];
}>;


export type CheckIfCustomerHasEliteAccountMutation = { __typename?: 'Mutation', checkIfCustomerHasEliteAccount: { __typename?: 'CheckIfCustomerHasEliteAccountOutput', has_elite_account: boolean, phone_number?: string | null | undefined } };

export type VerifyOldEliteAccountMutationVariables = Types.Exact<{
  otp: Types.Scalars['String'];
}>;


export type VerifyOldEliteAccountMutation = { __typename?: 'Mutation', verifyOldEliteAccount: boolean };

export declare const SetCustomerRayaEliteCorporateInformation: import("graphql").DocumentNode;
export declare const SetCustomerRayaEliteMedicalId: import("graphql").DocumentNode;
export declare const SetCustomerRayaEliteNationalIDInformation: import("graphql").DocumentNode;
export declare const SetCustomerRayaEliteGovernorateAndDistrictIds: import("graphql").DocumentNode;
export declare const EliteApplicationForm: import("graphql").DocumentNode;
export declare const CustomerPersonalImage: import("graphql").DocumentNode;
export declare const SubmitRayaEliteApplication: import("graphql").DocumentNode;
export declare const GetCustomerCreditLimits: import("graphql").DocumentNode;
export declare const GetCustomerActiveInstallment: import("graphql").DocumentNode;
export declare const GetCustomerInstallmentsByMonth: import("graphql").DocumentNode;
export declare const SetCustomerRayaElitePersonalImage: import("graphql").DocumentNode;
export declare const checkIfCustomerHasEliteAccount: import("graphql").DocumentNode;
export declare const verifyOldEliteAccount: import("graphql").DocumentNode;

export const SetCustomerRayaEliteCorporateInformationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetCustomerRayaEliteCorporateInformation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"corporateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"employeeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"hrLetter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"salary"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setCustomerRayaEliteCorporateInformation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"corporate_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"corporateId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"employee_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"employeeId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"hr_letter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"hrLetter"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"salary"},"value":{"kind":"Variable","name":{"kind":"Name","value":"salary"}}}]}}]}]}}]} as unknown as DocumentNode<SetCustomerRayaEliteCorporateInformationMutation, SetCustomerRayaEliteCorporateInformationMutationVariables>;
export const SetCustomerRayaEliteMedicalIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetCustomerRayaEliteMedicalId"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"medicalId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setCustomerRayaEliteMedicalId"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"medical_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"medicalId"}}}]}}]}]}}]} as unknown as DocumentNode<SetCustomerRayaEliteMedicalIdMutation, SetCustomerRayaEliteMedicalIdMutationVariables>;
export const SetCustomerRayaEliteNationalIdInformationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetCustomerRayaEliteNationalIDInformation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nationalIdImageBack"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nationalIdImageFront"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setCustomerRayaEliteNationalIDInformation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"national_id_image_back"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nationalIdImageBack"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"national_id_image_front"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nationalIdImageFront"}}}]}}]}]}}]} as unknown as DocumentNode<SetCustomerRayaEliteNationalIdInformationMutation, SetCustomerRayaEliteNationalIdInformationMutationVariables>;
export const SetCustomerRayaEliteGovernorateAndDistrictIdsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetCustomerRayaEliteGovernorateAndDistrictIds"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"districtId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"governorateId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setCustomerRayaEliteGovernorateAndDistrictIds"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"district_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"districtId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"governorate_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"governorateId"}}}]}}]}]}}]} as unknown as DocumentNode<SetCustomerRayaEliteGovernorateAndDistrictIdsMutation, SetCustomerRayaEliteGovernorateAndDistrictIdsMutationVariables>;
export const EliteApplicationFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"EliteApplicationForm"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"national_id"}},{"kind":"Field","name":{"kind":"Name","value":"raya_elite_information"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"corporate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"employee_id"}},{"kind":"Field","name":{"kind":"Name","value":"hr_letter"}},{"kind":"Field","name":{"kind":"Name","value":"national_id_image_front"}},{"kind":"Field","name":{"kind":"Name","value":"national_id_image_back"}},{"kind":"Field","name":{"kind":"Name","value":"first_name"}},{"kind":"Field","name":{"kind":"Name","value":"personal_image"}},{"kind":"Field","name":{"kind":"Name","value":"full_name"}},{"kind":"Field","name":{"kind":"Name","value":"medical_id"}},{"kind":"Field","alias":{"kind":"Name","value":"yearMonthlyIncome"},"name":{"kind":"Name","value":"salary"}}]}}]}}]}}]} as unknown as DocumentNode<EliteApplicationFormQuery, EliteApplicationFormQueryVariables>;
export const CustomerPersonalImageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CustomerPersonalImage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"raya_elite_information"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"personalImage"},"name":{"kind":"Name","value":"personal_image"}}]}}]}}]}}]} as unknown as DocumentNode<CustomerPersonalImageQuery, CustomerPersonalImageQueryVariables>;
export const SubmitRayaEliteApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SubmitRayaEliteApplication"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"submitRayaEliteApplication"}}]}}]} as unknown as DocumentNode<SubmitRayaEliteApplicationMutation, SubmitRayaEliteApplicationMutationVariables>;
export const GetCustomerCreditLimitsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCustomerCreditLimits"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rayaEliteCreditLimits"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"amount"},"name":{"kind":"Name","value":"total_amount"}},{"kind":"Field","alias":{"kind":"Name","value":"usedAmount"},"name":{"kind":"Name","value":"used_amount"}},{"kind":"Field","alias":{"kind":"Name","value":"availableAmount"},"name":{"kind":"Name","value":"available_amount"}}]}},{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer_points"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"availablePoints"},"name":{"kind":"Name","value":"current_points"}}]}},{"kind":"Field","name":{"kind":"Name","value":"national_id"}}]}}]}}]} as unknown as DocumentNode<GetCustomerCreditLimitsQuery, GetCustomerCreditLimitsQueryVariables>;
export const GetCustomerActiveInstallmentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCustomerActiveInstallment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upcoming_installments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upcoming"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"month"}},{"kind":"Field","name":{"kind":"Name","value":"due_date"}},{"kind":"Field","name":{"kind":"Name","value":"total_count"}},{"kind":"Field","name":{"kind":"Name","value":"is_due"}},{"kind":"Field","name":{"kind":"Name","value":"installments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"application_id"}},{"kind":"Field","name":{"kind":"Name","value":"down_payment_amount"}},{"kind":"Field","name":{"kind":"Name","value":"total_months"}},{"kind":"Field","name":{"kind":"Name","value":"paid_months"}},{"kind":"Field","name":{"kind":"Name","value":"total_amount"}},{"kind":"Field","name":{"kind":"Name","value":"paid_amount"}},{"kind":"Field","name":{"kind":"Name","value":"bills"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"due_date"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"penalty"}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetCustomerActiveInstallmentQuery, GetCustomerActiveInstallmentQueryVariables>;
export const GetCustomerInstallmentsByMonthDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCustomerInstallmentsByMonth"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"all_installments_by_month"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"upcoming"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"month"}},{"kind":"Field","name":{"kind":"Name","value":"due_date"}},{"kind":"Field","name":{"kind":"Name","value":"total_count"}},{"kind":"Field","name":{"kind":"Name","value":"is_due"}},{"kind":"Field","name":{"kind":"Name","value":"installments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"application_id"}},{"kind":"Field","name":{"kind":"Name","value":"down_payment_amount"}},{"kind":"Field","name":{"kind":"Name","value":"total_months"}},{"kind":"Field","name":{"kind":"Name","value":"paid_months"}},{"kind":"Field","name":{"kind":"Name","value":"total_amount"}},{"kind":"Field","name":{"kind":"Name","value":"paid_amount"}},{"kind":"Field","name":{"kind":"Name","value":"bills"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"due_date"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"penalty"}}]}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetCustomerInstallmentsByMonthQuery, GetCustomerInstallmentsByMonthQueryVariables>;
export const SetCustomerRayaElitePersonalImageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetCustomerRayaElitePersonalImage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"personalImage"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setCustomerRayaElitePersonalImage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"personal_image"},"value":{"kind":"Variable","name":{"kind":"Name","value":"personalImage"}}}]}}]}]}}]} as unknown as DocumentNode<SetCustomerRayaElitePersonalImageMutation, SetCustomerRayaElitePersonalImageMutationVariables>;
export const CheckIfCustomerHasEliteAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"checkIfCustomerHasEliteAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"nationalId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkIfCustomerHasEliteAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"national_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"nationalId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"has_elite_account"}},{"kind":"Field","name":{"kind":"Name","value":"phone_number"}}]}}]}}]} as unknown as DocumentNode<CheckIfCustomerHasEliteAccountMutation, CheckIfCustomerHasEliteAccountMutationVariables>;
export const VerifyOldEliteAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"verifyOldEliteAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"otp"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"verifyOldEliteAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"otp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"otp"}}}]}]}}]} as unknown as DocumentNode<VerifyOldEliteAccountMutation, VerifyOldEliteAccountMutationVariables>;