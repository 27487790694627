import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { getUser } from '~/features/auth';

export default defineNuxtMiddleware(async function checkoutMiddleware({ redirect, route, app: { i18n } }) {
  const entityId = route.query.id as string;
  const user = await getUser();
  if (!entityId) {
    redirect(`/${(i18n as VueI18n).locale}/${user ? 'account/bills' : 'bills'}`);
  }
});
