



























































import { defineComponent, ref, onMounted, onUnmounted, PropType, computed, watch } from '@nuxtjs/composition-api';
import { useI18n } from '~/features/i18n';
import { FlashsaleQuery } from '~/graphql/FlashSale';

export default defineComponent({
  name: 'FlashSaleBanner',
  props: {
    withButton: {
      type: Boolean,
      default: false,
    },
    linkAs: {
      type: String,
      default: 'button',
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    flashSale: {
      type: Array as PropType<FlashsaleQuery['flashsale_offers']>,
      default: undefined,
    },
    hasMiddleIllustration: {
      type: Boolean,
      default: false,
    },
    isCategoryBanner: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const currentFlashSale = computed(() => props.flashSale && props.flashSale[0]);
    const startDateTime = computed(
      () => currentFlashSale.value?.startTime && new Date(currentFlashSale.value?.startTime).getTime()
    );
    const endDateTime = computed(
      () => currentFlashSale.value?.endTime && new Date(currentFlashSale.value?.endTime).getTime()
    );

    const timeLeft = ref('');
    const isActiveSale = computed(() => {
      const currentTime = new Date();
      return (
        startDateTime.value &&
        endDateTime.value &&
        currentTime.getTime() > startDateTime.value &&
        currentTime.getTime() < endDateTime.value
      );
    });

    const saleWillStartSoon = computed(() => {
      const currentTime = new Date();
      return startDateTime.value && currentTime.getTime() < startDateTime.value;
    });

    const updateTimeLeft = () => {
      const currentTime = new Date().getTime();
      if (!startDateTime.value || !endDateTime.value) return;
      if (currentTime < startDateTime.value) {
        // Unused case in the design
        const diff = currentTime - startDateTime.value;
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        timeLeft.value = t('timeLeft', [days, hours, minutes, seconds]) as string;
      } else {
        const diff = endDateTime.value - currentTime;
        if (diff < 0) {
          clearInterval(intervalId);
          return;
        }
        // We can remove the days if the flash sale will not last more than 24 hours
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        timeLeft.value = t('timeLeft', [days, hours, minutes, seconds]) as string;
      }
    };

    let intervalId: ReturnType<typeof setInterval>;

    onMounted(() => {
      updateTimeLeft();
      intervalId = setInterval(updateTimeLeft, 1000);
      if (isActiveSale.value) {
        localStorage.setItem('flashSale', 'true');
      } else {
        localStorage.removeItem('flashSale');
      }
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    /**
     * add flashSale to localStorage to help handle header spacing
     * to prevent banner from overlapping page content
     * TODO:: Find a better solution
     */
    watch(isActiveSale, newValue => {
      if (newValue) {
        localStorage.setItem('flashSale', 'true');
      } else {
        localStorage.removeItem('flashSale');
      }
    });

    return {
      timeLeft,
      isActiveSale,
      saleWillStartSoon,
      currentFlashSale,
    };
  },
});
