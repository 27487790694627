import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { isValidPath, ELITE_STORE } from '../utils/elite';

export default defineNuxtMiddleware(function AuthMiddleware({ route, redirect, req }) {
  // get cookies from the request elite
  const cookies = process.server ? req?.headers?.cookie : document.cookie;

  if (!cookies?.includes(ELITE_STORE) && /elite-index/.test(route.path)) {
    const locale = route?.path?.split('/')[1];

    if (process.server) {
      // hard redirect to the path
      redirect(301, `/${locale}/`);
    } else {
      // hard redirect to the path
      window.location.href = `/${locale}/`;
    }

    return;
  }

  if (!cookies?.includes(ELITE_STORE)) {
    return;
  }
  // clear any /en/ ar /ar/ from the path
  const path = route?.path?.replace(/\/(en|ar)\//, '/') ?? '';

  // if path is relative to the root, redirect to /elite-index
  if (path === '/') {
    const locale = route?.path?.split('/')[1];

    return redirect(`/${locale}/elite-index`);
  }

  if (isValidPath(path)) {
    return;
  }

  if (process.server) {
    // hard redirect to the path
    const locale = route?.path?.split('/')[1];
    redirect(301, `/${locale}/elite-index`);
  } else {
    const locale = route?.path?.split('/')[1];
    // hard redirect to the path
    window.location.href = `/${locale}/elite-index`;
  }
});
