import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type InstallmentPlansFragment = { __typename?: 'Corporate', plans?: Array<{ __typename?: 'CorporatePlan', interest_rate: number, plan: number } | null | undefined> | null | undefined };

export type CorporatesInstallmentPlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CorporatesInstallmentPlansQuery = { __typename?: 'Query', corporates: Array<{ __typename?: 'Corporate', id: number, name?: string | null | undefined, plans?: Array<{ __typename?: 'CorporatePlan', interest_rate: number, plan: number } | null | undefined> | null | undefined } | null | undefined> };

export type SetEliteOrderDownPaymentAndPlanOnCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['String'];
  downPayment: Types.Scalars['Float'];
  plan: Types.Scalars['Int'];
}>;


export type SetEliteOrderDownPaymentAndPlanOnCartMutation = { __typename?: 'Mutation', setEliteOrderDownPaymentAndPlanOnCart: { __typename?: 'SetEliteOrderDownPaymentAndPlanOnCartOutput', cart: { __typename?: 'Cart', id: string } } };

export type VerifyEliteCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['String'];
  otp: Types.Scalars['String'];
}>;


export type VerifyEliteCartMutation = { __typename?: 'Mutation', status: boolean };

export type SetEliteOrderDownPaymentMethodOnCartMutationVariables = Types.Exact<{
  cartId: Types.Scalars['String'];
  downPaymentPaymentMethod: Types.DownPaymentPaymentMethod;
}>;


export type SetEliteOrderDownPaymentMethodOnCartMutation = { __typename?: 'Mutation', setEliteOrderDownPaymentMethodOnCart: { __typename?: 'SetEliteOrderDownPaymentMethodOnCartOutput', cart: { __typename?: 'Cart', id: string } } };

export declare const InstallmentPlans: import("graphql").DocumentNode;
export declare const CorporatesInstallmentPlans: import("graphql").DocumentNode;
export declare const SetEliteOrderDownPaymentAndPlanOnCart: import("graphql").DocumentNode;
export declare const VerifyEliteCart: import("graphql").DocumentNode;
export declare const SetEliteOrderDownPaymentMethodOnCart: import("graphql").DocumentNode;
export const InstallmentPlansFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"InstallmentPlans"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Corporate"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"plans"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"interest_rate"}},{"kind":"Field","name":{"kind":"Name","value":"plan"}}]}}]}}]} as unknown as DocumentNode<InstallmentPlansFragment, unknown>;
export const CorporatesInstallmentPlansDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CorporatesInstallmentPlans"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"corporates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"plans"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"interest_rate"}},{"kind":"Field","name":{"kind":"Name","value":"plan"}}]}}]}}]}}]} as unknown as DocumentNode<CorporatesInstallmentPlansQuery, CorporatesInstallmentPlansQueryVariables>;
export const SetEliteOrderDownPaymentAndPlanOnCartDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetEliteOrderDownPaymentAndPlanOnCart"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"downPayment"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Float"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"plan"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setEliteOrderDownPaymentAndPlanOnCart"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cart_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}}},{"kind":"Argument","name":{"kind":"Name","value":"down_payment"},"value":{"kind":"Variable","name":{"kind":"Name","value":"downPayment"}}},{"kind":"Argument","name":{"kind":"Name","value":"plan"},"value":{"kind":"Variable","name":{"kind":"Name","value":"plan"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cart"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<SetEliteOrderDownPaymentAndPlanOnCartMutation, SetEliteOrderDownPaymentAndPlanOnCartMutationVariables>;
export const VerifyEliteCartDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"VerifyEliteCart"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"otp"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"status"},"name":{"kind":"Name","value":"verifyEliteCart"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cart_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}}},{"kind":"Argument","name":{"kind":"Name","value":"otp"},"value":{"kind":"Variable","name":{"kind":"Name","value":"otp"}}}]}]}}]} as unknown as DocumentNode<VerifyEliteCartMutation, VerifyEliteCartMutationVariables>;
export const SetEliteOrderDownPaymentMethodOnCartDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SetEliteOrderDownPaymentMethodOnCart"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"downPaymentPaymentMethod"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DownPaymentPaymentMethod"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"setEliteOrderDownPaymentMethodOnCart"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"cart_id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"cartId"}}},{"kind":"Argument","name":{"kind":"Name","value":"down_payment_payment_method"},"value":{"kind":"Variable","name":{"kind":"Name","value":"downPaymentPaymentMethod"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cart"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<SetEliteOrderDownPaymentMethodOnCartMutation, SetEliteOrderDownPaymentMethodOnCartMutationVariables>;