// import { isBefore, isAfter, endOfDay } from 'date-fns';
import { mapBundleProductItem } from '../features/product';
import { PriceRange, PriceTypeEnum, ProductInterface } from '../graphql-types.gen';
import { ProductCardFragment } from '../graphql/fragments';

/**
 * Resolves the current price of the item.
 */
export function resolveProductPrice(
  product: Pick<ProductInterface, 'special_price' | 'special_from_date' | 'special_to_date'> & {
    price_range?: Partial<PriceRange>;
  }
): number {
  // const specialPrice = product.special_price;
  const basePrice = product.price_range?.maximum_price?.final_price.value ?? 0;
  // if ((!product.special_from_date || !product.special_to_date) && specialPrice) {
  //   return specialPrice;
  // }

  // const offerFrom = product.special_from_date ? new Date(product.special_from_date) : null;
  // const offerTo = product.special_to_date ? endOfDay(new Date(product.special_to_date)) : null;
  // const now = new Date();
  // const isValidOffer =
  //   specialPrice && specialPrice !== null && offerTo && offerFrom && isAfter(offerTo, now) && isBefore(offerFrom, now);
  // if (isValidOffer) {
  //   return specialPrice ?? basePrice;
  // }
  return basePrice;
}

/**
 * Resolves a product stock value
 */
export function resolveProductStock(
  apiProduct: Pick<ProductCardFragment, 'only_x_left_in_stock' | 'stock_status' | '__typename'>
) {
  // configurable products may not have stock associated so `null` is expected
  // update bundle stock based on stock_status then amount left in stock
  if (['BundleProduct', 'ConfigurableProduct', 'GroupedProduct'].includes(apiProduct.__typename)) {
    return apiProduct.stock_status === 'OUT_OF_STOCK' || apiProduct.only_x_left_in_stock === 0
      ? 0
      : apiProduct.only_x_left_in_stock;
  }

  return apiProduct.only_x_left_in_stock || 0;
}

/**
 * Resolves a product stock value in bundle items
 */
export function resolveBundleProductStock(items: ReturnType<typeof mapBundleProductItem>[] | undefined) {
  if (!items) return 0;

  return Math.min(
    ...items
      ?.map(item => {
        return {
          ...item.options?.[0],
        };
      })
      .map<number>(item => (item.stock ?? 0) / (item.qty ?? 1))
  );
}

export const isValidPercentage = (threshold: number) => (oldPrice: number, newPrice: number) =>
  newPrice >= (oldPrice * threshold) / 100;

export const isNumeric = (str: string) => {
  if (typeof str !== 'string') return false; // we only process strings!
  return (
    !isNaN((str as unknown) as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const validationRulesFactory = <T>(rules: Array<(f: T) => boolean> | []) => {
  return rules;
};

export const validateProductData = <T>(validationRules: Array<(f: T) => boolean> | []) => (product: T): boolean => {
  return validationRules.every(rule => !!rule(product));
};

export function getInsurancePrice(productPrice: number, insurancePrice: number, priceType: string) {
  /** calculate insurance price as percentage or direct value */
  if (insurancePrice)
    return priceType === PriceTypeEnum.Percent
      ? (productPrice * ((insurancePrice || 0) / 100)).toFixed(2)
      : insurancePrice;
  return 0;
}
