import PubSub from 'pubsub-js';

export const topics = {
  auth: {
    identify: 'auth:identify',
  },
};
export function useChannels() {
  return {
    subscribe: PubSub.subscribe,
    register: PubSub.publish,
  };
}
