import { Context } from '@nuxt/types';
import { ref } from '@nuxtjs/composition-api';

interface TTLStreamOptions {
  // Define options for TTLStream constructor if needed
}

interface TTLStreamInstance {
  find(options: {
    brand: string;
    productId: string;
    retailerDomain: string;
    templateType: string;
    resultType: string;
    contentType: string;
    language: string;
  }): Promise<string>;
}

declare global {
  interface Window {
    TTLStreamReady: Promise<TTLStreamInstance>;
    ttlStream?: TTLStreamInstance;
    TTLStream?: new (options: TTLStreamOptions) => TTLStreamInstance;
  }
}

export default function ({ app }: Context, inject: Function): void {
  try {
    (function (w: Window, d: Document, s: string, o: TTLStreamOptions) {
      const f = d.getElementsByTagName(s)[0] as HTMLScriptElement;
      const j = d.createElement(s) as HTMLScriptElement;
      w.TTLStreamReady = new Promise<TTLStreamInstance>(resolve => {
        j.async = true;
        j.src = '//content.24ttl.stream/widget.js';
        f?.parentNode?.insertBefore(j, f);
        j.onload = function () {
          if (window.TTLStream) {
            w.ttlStream = new window.TTLStream(o);
            resolve(w.ttlStream);
          }
        };
      });
    })(window, document, 'script', {});
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error loading TTLStream: ', (error as Error).message);
  }

  async function getRichContent(brand: string, productId: string) {
    const content = ref<string | null>(null);

    try {
      await window.TTLStreamReady;
      if (!window.ttlStream) return;

      const data = await window.ttlStream.find({
        brand,
        productId,
        retailerDomain: 'rayashop.com',
        templateType: 'master_template',
        resultType: 'html',
        contentType: 'minisite',
        language: app.i18n.locale === 'ar' ? 'eg_ar' : 'eg_en',
      });

      content.value = data;
    } catch (error) {
      // no content found
    }

    return content;
  }

  inject('getRichContent', getRichContent);
}
