

















import { ref } from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';
import { useEvent } from '~/features/events';

type Payload = {
  phoneNumber: string;
  // whether we're verifying the nationalId or the customer himself
  nationalId?: boolean;
  // true if we're verifying the customer info
  customerInfo?: boolean;
  // true if we're verifying guest cart
  guest?: boolean;

  verifyElite?: boolean;
};
export default defineComponent({
  setup() {
    const isShown = ref(false);
    const phoneNumber = ref('');
    const isNationalId = ref(false);
    const isCustomerInfo = ref(false);
    const isGuest = ref(false);
    const verifyElite = ref(false);

    useEvent(
      'VERIFY_OTP',
      ({ phoneNumber: phoneNumberData, nationalId, customerInfo, guest, verifyElite: _verifyElite }: Payload) => {
        isShown.value = true;
        phoneNumber.value = phoneNumberData;
        isNationalId.value = Boolean(nationalId);
        isCustomerInfo.value = Boolean(customerInfo);
        isGuest.value = Boolean(guest);
        verifyElite.value = Boolean(_verifyElite);
      }
    );

    useEvent('CLOSE_VERIFY_OTP', () => {
      isShown.value = false;
    });

    return {
      isShown,
      phoneNumber,
      isNationalId,
      isCustomerInfo,
      isGuest,
      verifyElite,
    };
  },
});
