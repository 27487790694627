var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.as,_vm._g(_vm._b({tag:"component",staticClass:"AppButton [ flex items-center ] [ rounded-2xs ] [ py-4 px-5 ] overflow-visible focus:outline-none relative",class:{
    'bg-primary-500 text-white': _vm.variant === 'default' || _vm.variant === 'primary',
    'bg-secondary-200 text-primary-500': _vm.variant === 'secondary',
    'text-primary-500 bg-white border-2 border-primary-500': _vm.variant === 'inverted' || _vm.variant === 'white',
    'text-primary-700 bg-white border border-primary-700': _vm.variant === 'outline',
    'border-2 border-red-700 bg-red-700 text-white': _vm.variant === 'danger',
    'text-white bg-transparent border border-white': _vm.variant === 'outline-white',
    ' opacity-50 pointer-events-none': _vm.disabled,
  },attrs:{"aria-label":_vm.ariaLabel}},'component',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }