









import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { useEvent, useEventBus } from '~/features/events';
import { CustomizableCheckboxValue } from '~/graphql-types.gen';

export default defineComponent({
  setup() {
    const displayInsuranceInfo = ref(false);
    const insuranceOption = ref<CustomizableCheckboxValue | null>(null);
    const productPrice = ref(0);
    const { emit: emitEvent } = useEventBus();

    useEvent(
      'SHOW_INSURANCE_MODAL',
      ({
        showModal,
        insurance,
        price,
      }: {
        showModal: boolean;
        insurance: CustomizableCheckboxValue | null;
        price: number;
      }) => {
        displayInsuranceInfo.value = showModal;
        insuranceOption.value = insurance;
        productPrice.value = price;
      }
    );

    useEvent('INSURANCE_CONFIRMED', () => {
      displayInsuranceInfo.value = false;
    });

    watch(displayInsuranceInfo, newValue => {
      if (!newValue) emitEvent('INSURANCE_CONFIRMATION_CANCELLED');
    });

    return {
      displayInsuranceInfo,
      insuranceOption,
      productPrice,
    };
  },
});
