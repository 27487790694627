import { computed, onMounted, ref, watch } from '@nuxtjs/composition-api';
import { useMutation, useQuery } from 'villus';
import { useAlerts } from './alerts';
import useCookies from './cookies';
import { useI18n } from './i18n';
import { useEventBus } from './events';
import { useAuth } from '~/features/auth';
import {
  EliteApplicationFormDocument,
  GetCustomerCreditLimitsDocument,
  SetCustomerRayaEliteCorporateInformationDocument,
  SetCustomerRayaEliteCorporateInformationMutationVariables,
  SetCustomerRayaEliteGovernorateAndDistrictIdsDocument,
  SetCustomerRayaEliteMedicalIdDocument,
  SetCustomerRayaEliteNationalIdInformationDocument,
  SetCustomerRayaEliteNationalIdInformationMutationVariables,
  SetCustomerRayaElitePersonalImageDocument,
  SubmitRayaEliteApplicationDocument,
  CheckIfCustomerHasEliteAccountDocument,
  VerifyOldEliteAccountDocument,
} from '~/graphql/Elite';
import { CheckEnableB2cViewDocument } from '~/graphql/Config';
import { GetApplicationStatusDocument, GetCustomerEliteStatusDocument } from '~/graphql/Customer';
import { RayaEliteStatus } from '~/graphql-types.gen';

export function useCorporateInformation() {
  const { execute, isFetching: executingRayaEliteCoprorateInformation } = useMutation(
    SetCustomerRayaEliteCorporateInformationDocument
  );
  const { execute: executeSettingRayaEliteMedical, isFetching: executingMedicalId } = useMutation(
    SetCustomerRayaEliteMedicalIdDocument
  );

  const { error: errorAlert } = useAlerts();

  async function setCorporateInformation(input: SetCustomerRayaEliteCorporateInformationMutationVariables) {
    const { data, error } = await execute(input);

    if (error) {
      errorAlert('Error', error.message);
      throw error;
    }

    return data;
  }

  async function setMedicalId(medicalId: string) {
    const { data, error } = await executeSettingRayaEliteMedical({ medicalId });

    if (error) {
      errorAlert('Error', error.message);
      throw error;
    }

    return data;
  }

  const isFetching = computed(() => executingRayaEliteCoprorateInformation.value || executingMedicalId.value);

  return {
    setCorporateInformation,
    setMedicalId,
    isFetching,
  };
}

export function useNationalIdInformation() {
  const { execute, isFetching: isFetchingCustomerNationalId } = useMutation(
    SetCustomerRayaEliteNationalIdInformationDocument
  );
  const { eliteApplicationForm, refetch, isFetching: isFetchingApplicationForm } = useEliteApplicationForm();
  const errors = ref<string | null>(null);

  const nationalIdFormData = computed(() => ({
    nationalId: eliteApplicationForm.value?.nationalId ?? '',
    // append base 64 headers
    nationalIdImageFront: eliteApplicationForm.value?.national_id_image_front
      ? `data:image/png;base64,${eliteApplicationForm.value?.national_id_image_front ?? ''}`
      : null,
    nationalIdImageBack: eliteApplicationForm.value?.national_id_image_back
      ? `data:image/png;base64,${eliteApplicationForm.value?.national_id_image_back ?? ''}`
      : null,
    fullName: `${eliteApplicationForm.value?.first_name ?? ''} ${eliteApplicationForm.value?.full_name ?? ''}`,
  }));

  async function setNationalIdInformation(input: SetCustomerRayaEliteNationalIdInformationMutationVariables) {
    const { data, error } = await execute(input);

    if (error) {
      errors.value = error.message.replace('[GraphQL] ', '');
      throw new Error(error.message);
    }

    await refetch();
    errors.value = null;
    return data;
  }

  return {
    setNationalIdInformation,
    isFetching: computed(() => isFetchingCustomerNationalId.value || isFetchingApplicationForm.value),
    nationalIdFormData,
    errors,
  };
}

export function useEliteApplicationForm() {
  const { data, error, isFetching, execute: refetch } = useQuery({
    query: EliteApplicationFormDocument,
    cachePolicy: 'network-only',
  });

  return {
    eliteApplicationForm: computed(() => ({
      ...data?.value?.customer?.raya_elite_information,
      nationalId: data?.value?.customer?.national_id,
    })),
    error,
    isFetching,
    refetch,
  };
}

export function useSetCustomerRayaEliteGovernorateAndDistrictIds() {
  const { isFetching, execute } = useMutation(SetCustomerRayaEliteGovernorateAndDistrictIdsDocument);

  return {
    isExecutingSetCustomerRayaEliteGovernorateAndDistrictIds: isFetching,
    execute,
  };
}

export function useSubmitEliteApplicationForm() {
  const { execute, isFetching } = useMutation(SubmitRayaEliteApplicationDocument);
  const { error: errorAlert } = useAlerts();

  async function submitEliteApplicationForm() {
    const { data, error } = await execute();

    if (error) {
      errorAlert('Error', error.message);
      throw new Error(error.message);
    }

    return data;
  }

  return {
    submitEliteApplicationForm,
    isFetching,
  };
}

// handles selfie image for elite registration flow
export function useSubmitPersonalImage() {
  const { execute, isFetching } = useMutation(SetCustomerRayaElitePersonalImageDocument);
  const { error: errorAlert } = useAlerts();

  async function submitPersonalImage(image: string) {
    const { data, error } = await execute({
      // remove base64 headers
      personalImage: image.replace(/^data:image\/[a-z]+;base64,/, ''),
    });

    if (error) {
      errorAlert('Error', error.message);
      throw new Error(error.message);
    }

    return data;
  }

  return {
    submitPersonalImage,
    isFetching,
  };
}

export const useUserApplicationStatus = () => {
  const { data, error, execute } = useQuery({ query: GetCustomerEliteStatusDocument, cachePolicy: 'network-only' });
  const { user } = useAuth();

  watch(
    user,
    newUser => {
      if (newUser) {
        execute();
      }
    },
    {
      immediate: true,
    }
  );

  return {
    userApplicationStatus: computed(() => data?.value?.customer?.raya_elite_information?.status),
    error,
  };
};

export const useUserCreditLimits = () => {
  const { data, error, execute, isFetching } = useQuery({
    query: GetCustomerCreditLimitsDocument,
    fetchOnMount: false,
  });

  return {
    execute,
    creditOptions: computed(() => ({
      creditLimits: Number(data?.value?.rayaEliteCreditLimits?.amount ?? '0'),
      availablePoints: Number(data?.value?.customer?.customer_points ?? '0'),
      usedCredit: Number(data?.value?.rayaEliteCreditLimits?.usedAmount ?? '0'),
      availableCredit: Number(data?.value?.rayaEliteCreditLimits?.availableAmount ?? '0'),
      nationalId: data?.value?.customer?.national_id ?? '',
    })),
    isFetching,
    error,
  };
};

export const ELITE_STORE = 'eliteStore';
export const B2C_VIEW = 'isB2cView';

export const useEliteSwitcher = (id: string) => {
  const { cookies, setCookie, removeCookie } = useCookies();
  const { user } = useAuth();
  const { emit } = useEventBus();

  const isEliteStore = computed(() => !!cookies[ELITE_STORE]);

  const setEliteStore = (value: boolean) => {
    if (value) {
      setCookie(ELITE_STORE, `${id}-${user.value?.id}`);
      emit('TOGGLE_ELITE_STORE', value);
      return;
    }

    emit('TOGGLE_ELITE_STORE', value);

    removeCookie(ELITE_STORE);
    removeCookie(B2C_VIEW);
  };

  return {
    isEliteStore,
    setEliteStore,
  };
};

export const useUserEliteFullApplicationStatus = () => {
  const { data, error } = useQuery({ query: GetApplicationStatusDocument });
  const { t } = useI18n();

  return {
    companyName: computed(() => data?.value?.customer?.raya_elite_information?.corporate?.name),
    companyId: computed(() => data?.value?.customer?.raya_elite_information?.employee_id),
    hrLetter: computed(() =>
      data?.value?.customer?.raya_elite_information?.hr_letter ? t('uploaded') : t('not-uploaded')
    ),
    frontSideId: computed(() =>
      data?.value?.customer?.raya_elite_information?.national_id_image_front ? t('uploaded') : t('not-uploaded')
    ),
    backSideId: computed(() =>
      data?.value?.customer?.raya_elite_information?.national_id_image_back ? t('uploaded') : t('not-uploaded')
    ),
    nationalId: computed(() => data?.value?.customer?.national_id),
    fullName: computed(() => {
      return `${data?.value?.customer?.raya_elite_information?.first_name ?? ''} ${
        data?.value?.customer?.raya_elite_information?.full_name ?? ''
      }`;
    }),

    error,
  };
};

/**
 * check for local storage for the elite popup to show or not, if it's not there then show it, when close it, save it in local storage
 */
export const useElitePopup = () => {
  const elitePopup = ref<boolean>(false);
  const { cookies, setCookie, removeCookie } = useCookies();
  const { user } = useAuth();

  const setElitePopup = (value: boolean) => {
    if (value) {
      setCookie('elitePopup', `${user?.value?.raya_elite_information?.corporate?.id}-${user.value?.id}`, {
        // expires in 30 days
        expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
      });
      return;
    }
    removeCookie('elitePopup');
  };

  onMounted(() => {
    elitePopup.value = cookies.elitePopup !== `${user?.value?.raya_elite_information?.corporate?.id}-${user.value?.id}`;
  });

  return {
    elitePopup,
    setElitePopup,
  };
};

export const useStoredEliteCorporate = () => {
  const { cookies, getCookies, removeCookie } = useCookies();
  const { user } = useAuth();

  const storedEliteCorporate = computed(() => {
    return cookies[ELITE_STORE]?.split('-')[0];
  });

  const resolveEliteCorporate = () => {
    if (process.server) {
      return user.value?.raya_elite_information?.corporate?.id?.toString() === cookies[ELITE_STORE]?.split('-')[0]
        ? cookies[ELITE_STORE]?.split('-')[0]
        : null;
    }
    return user.value?.raya_elite_information?.corporate?.id?.toString() ===
      getCookies()?.[ELITE_STORE]?.split('-')[0]?.toString()
      ? getCookies()?.[ELITE_STORE]?.split('-')[0]
      : null;
  };

  const removeEliteStore = () => {
    removeCookie(ELITE_STORE);
  };

  return {
    storedEliteCorporate,
    resolveEliteCorporate,
    removeEliteStore,
  };
};

export const useStoredCookiesEliteCorporate = () => {
  const { cookies, getCookies, removeCookie } = useCookies();

  const storedEliteCorporate = computed(() => {
    return cookies[ELITE_STORE]?.split('-')[0];
  });

  const resolveEliteCorporate = () => {
    if (process.server) {
      return cookies[ELITE_STORE] ? cookies[ELITE_STORE]?.split('-')[0] : null;
    }
    return getCookies()?.[ELITE_STORE] ? getCookies()?.[ELITE_STORE]?.split('-')[0] : null;
  };

  const removeEliteStore = () => {
    removeCookie(ELITE_STORE);
  };

  return {
    storedEliteCorporate,
    resolveEliteCorporate,
    removeEliteStore,
  };
};

export function useSetCorporateSourceToCacheParams() {
  const { storedEliteCorporate } = useStoredEliteCorporate();

  function corporateCacheParam(id: string) {
    if (storedEliteCorporate.value) return `${storedEliteCorporate.value}-${id}`;

    return id;
  }

  return {
    corporateCacheParam,
  };
}

/**
 *
 * Used to Eject the Elite Corporate Information within every query that needs it , only if it's enabled
 */
export function useGetEliteInfo() {
  const { user } = useAuth();
  const { resolveEliteCorporate } = useStoredEliteCorporate();
  // Add a prefix to the 'isApprovedElite' variable
  const enabledCorporate = computed(() => {
    return (
      resolveEliteCorporate() === user?.value?.raya_elite_information?.corporate?.id.toString() &&
      user?.value?.raya_elite_information?.status === RayaEliteStatus.Approved
    );
  });
  const isActiveElite = computed(() => {
    return (
      resolveEliteCorporate() === user?.value?.raya_elite_information?.corporate?.id.toString() &&
      user?.value?.raya_elite_information?.status === RayaEliteStatus.Active
    );
  });
  const attachedCorporate = computed(() => {
    return user?.value?.raya_elite_information?.corporate?.id &&
      Boolean(isActiveElite?.value || enabledCorporate?.value)
      ? {
          id: user?.value?.raya_elite_information?.corporate?.id,
          name: user?.value?.raya_elite_information?.corporate?.name,
          assignedSellerIds: user?.value?.raya_elite_information?.corporate?.assigned_seller_ids,
        }
      : null;
  });
  return {
    enabledCorporate,
    attachedCorporate,
    isActiveElite,
  };
}

/**
 * villus client locale plugin
 */
export function eliteCorporatePlugin() {
  const { resolveEliteCorporate } = useStoredCookiesEliteCorporate();
  return function corporatePlugin({ opContext }: any) {
    if (resolveEliteCorporate()) {
      (opContext.headers as Record<string, string>).SourceCode = 'elite';
    }
  };
}

export const useIsElite = () => {
  const { cookies } = useCookies();

  const isElite = computed(() => {
    return cookies[ELITE_STORE];
  });

  return {
    isElite,
  };
};
export function useRequestToCheckIfCustomerHasEliteAccount() {
  const { execute, isFetching } = useMutation(CheckIfCustomerHasEliteAccountDocument);
  return { checkIfCustomerHasEliteAccount: execute, isFetching };
}

export function useRequestToVerifyOldEliteAccount() {
  const { execute, isFetching } = useMutation(VerifyOldEliteAccountDocument);
  return { verifyOldEliteAccount: execute, isFetching };
}

export function useCheckEnableB2cViewFlag() {
  const { setCookie, removeCookie } = useCookies();
  const { user } = useAuth();
  const { execute } = useQuery({
    query: CheckEnableB2cViewDocument,
    fetchOnMount: false,
  });

  const isElite = computed(() => {
    return (
      user?.value?.raya_elite_information?.status === RayaEliteStatus.Approved ||
      user?.value?.raya_elite_information?.status === RayaEliteStatus.Active
    );
  });

  async function checkEnableB2cViewFlag() {
    try {
      const { data } = await execute();

      if (!data?.storeConfig?.enable_b2c_view && isElite.value) {
        const { isEliteStore, setEliteStore } = useEliteSwitcher(
          user?.value?.raya_elite_information?.corporate?.id?.toString() as string
        );
        setEliteStore(!isEliteStore.value);
        setCookie(B2C_VIEW, 'disabled');
        return false;
      }

      removeCookie(B2C_VIEW);
      return true;
    } catch (error) {
      removeCookie(B2C_VIEW); // Default to true in case of error
      return true;
    }
  }

  return {
    checkEnableB2cViewFlag,
  };
}
