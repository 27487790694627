

















import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { useAlerts } from '~/features/alerts';

export default defineComponent({
  props: {
    layout: {
      type: String,
      default: 'default',
    },
  },
  setup() {
    const { alerts, dismiss } = useAlerts();

    const sticky = ref(false);

    onMounted(() => {
      window.onscroll = function () {
        if (window.scrollY === 0) {
          sticky.value = false;
          return;
        }
        sticky.value = true;
      };
    });

    return {
      alerts,
      dismiss,
      sticky,
    };
  },
});
