import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { DocumentNode, print } from 'graphql';
import { CombinedError } from 'villus';
import { makeHttpJSONRequest } from '../server/utils';

/**
 * Normalizes a query string or object to a string.
 */
export function normalizeQuery(query: string | DocumentNode | TypedDocumentNode): string | null {
  if (typeof query === 'string') {
    return query;
  }

  if (query && query.kind) {
    return print(query);
  }

  return null;
}

/**
 * useQuery server side
 *
 **/

declare type QueryVariables = Record<string, any>;
interface Operation<TData, TVars> {
  query: string | DocumentNode | TypedDocumentNode<TData, TVars>;
  variables?: TVars;
}

interface QueryCompositeOptions<TData, TVars> {
  query: Operation<TData, TVars>['query'];
  variables: TVars;
  auth?: string;
}

interface BaseQueryApi<TData = any> {
  data: TData | null;
  isFetching: boolean;
  isDone: boolean;
  error: CombinedError | null;
}

interface QueryApi<TData> extends BaseQueryApi<TData> {
  then(onFulfilled: (value: BaseQueryApi<TData>) => any): Promise<BaseQueryApi<TData>>;
}

export async function useQuery<TData = any, TVars = QueryVariables>(
  opts: QueryCompositeOptions<TData, TVars>
): Promise<QueryApi<TData>> {
  return await makeHttpJSONRequest(
    'post',
    process.env.API_URL ?? '',
    {
      query: normalizeQuery(opts.query),
      variables: {
        ...opts.variables,
      },
    },
    {
      Authorization: opts?.auth || '',
    }
  );
}
