import { render, staticRenderFns } from "./VariantModalDialog.vue?vue&type=template&id=5dd1cb4a&scoped=true&"
import script from "./VariantModalDialog.vue?vue&type=script&lang=ts&"
export * from "./VariantModalDialog.vue?vue&type=script&lang=ts&"
import style0 from "./VariantModalDialog.vue?vue&type=style&index=0&id=5dd1cb4a&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd1cb4a",
  null
  
)

export default component.exports