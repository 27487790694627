export default {
  name: 'AppLink',
  functional: true,
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
  },
  render(h, { props, data, children, parent }) {
    function make() {
      if (!props.to) {
        return ['span', { ...data, props }];
      }

      if (typeof props.to === 'object' && props.to.path) {
        return [
          'nuxt-link',
          {
            ...data,
            props,
          },
        ];
      }

      const locale = parent.$i18n.locale;
      const linkProps = { ...props, external: undefined };
      const attrs = linkProps.ariaLabel ? { 'aria-label': linkProps.ariaLabel } : {};
      let tag = 'nuxt-link';
      if (!linkProps.to.includes(`/${locale}/`)) {
        linkProps.to = `/${locale}${props.to}`;
      }

      if (props.external) {
        tag = 'a';
        attrs.href = props.to;
        delete linkProps.to;
        attrs.rel = 'noopener';
        attrs.target = 'blank';
      }

      return [
        tag,
        {
          ...data,
          attrs,
          props: linkProps,
        },
      ];
    }

    return h(...make(), children);
  },
};
