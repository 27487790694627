





































































































































































import { computed, defineComponent, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api';
import { keyBy } from 'lodash-es';
// import { useMegaMenuBrands } from '~/features/brands';
import { useMegaMenuCategories } from '~/features/categories';
import { useFeatureFlags, useStoreConfig } from '~/features/storeConfig';

export default defineComponent({
  name: 'CategoryMegaMenu',
  setup() {
    const { route } = useContext();
    const { offerLabel, promotedCategory } = useStoreConfig();
    const { fetchFlags } = useFeatureFlags();
    const featureFlags = ref<any>(undefined);

    const { megaMenuCategories } = useMegaMenuCategories();
    const selectedCategory = ref<any>(undefined);
    // const { megaMenuBrands } = useMegaMenuBrands();
    const megaMenuBrands = ref<any>(undefined);
    const brandsSelected = ref(false);

    onMounted(() => {
      fetchFlags().then(data => {
        featureFlags.value = keyBy(data.data?.features, 'feature_name');
      });
    });

    watch(route, () => {
      selectedCategory.value = undefined;
      brandsSelected.value = false;
    });

    const offer = computed(() => {
      return brandsSelected.value ? null : (selectedCategory.value as any)?.offer;
    });

    return {
      selectedCategory,
      offer,
      featuredCategories: megaMenuCategories,
      offerLabel,
      promotedCategory,
      brandsSelected,
      megaMenuBrands,
      featureFlags,
    };
  },
});
