

























































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useEvent } from '@/features/events';

export default defineComponent({
  name: 'AddToCartConfirmation',
  setup() {
    const item = ref();

    const isOpen = ref(false);

    function close() {
      isOpen.value = false;
    }

    function toggle(state: boolean) {
      isOpen.value = state;
    }

    useEvent('ITEM_ADDED', (product: any) => {
      item.value = product;
      isOpen.value = true;

      setTimeout(() => {
        close();
      }, 5000);
    });

    return {
      item,
      isOpen,
      close,
      toggle,
    };
  },
});
