

































import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SlideModalDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
    disableDesktop: {
      type: Boolean,
      default: false,
      description: 'Used for preventing it to be opened on large screens ',
    },
  },
  setup(_, { emit }) {
    function close() {
      emit('input', false);
    }

    return {
      close,
    };
  },
});
