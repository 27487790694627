import { useMutation, useQuery } from 'villus';
import { ActiveCampaignDocument, SpinTheWheelDocument } from '~/graphql/wheel';

/**
 * Use active spin
 * To get current active campain
 */
export const useActiveCampain = () => {
  const { data, isFetching } = useQuery({
    query: ActiveCampaignDocument,
    cachePolicy: 'network-only',
  });

  return {
    data,
    isFetching,
  };
};

/**
 * Use spin hook
 * spinWheel() : on call spin wheel action
 */
export const useSpinWheel = () => {
  const { execute, isFetching } = useMutation(SpinTheWheelDocument);

  async function spinWheel() {
    try {
      const { data, error } = await execute();
      if (error) {
        throw new Error(error.message);
      }
      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    spinWheel,
    isFetching,
  };
};
