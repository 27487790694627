const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['bills-payment'] = require('../middleware/bills-payment.ts')
middleware['bills-payment'] = middleware['bills-payment'].default || middleware['bills-payment']

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['elite'] = require('../middleware/elite.ts')
middleware['elite'] = middleware['elite'].default || middleware['elite']

middleware['guest'] = require('../middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['is-elite-user'] = require('../middleware/is-elite-user.ts')
middleware['is-elite-user'] = middleware['is-elite-user'].default || middleware['is-elite-user']

middleware['taksety'] = require('../middleware/taksety.ts')
middleware['taksety'] = middleware['taksety'].default || middleware['taksety']

export default middleware
