import { useContext } from '@nuxtjs/composition-api';
import { CookieAttributes } from 'js-cookie';

interface CookiesApi {
  readonly cookies: Record<string, any>;
  setCookie(name: string, value: string, attrs?: CookieAttributes): void;
  removeCookie(name: string, attrs?: CookieAttributes): void;
  getCookies?: any;
}

// eslint-disable-next-line import/no-mutable-exports
let useCookies: () => CookiesApi;

// eslint-disable-next-line import/no-mutable-exports
export let useContextCookies: (req?: any, res?: any) => CookiesApi;

export const DEFAULT_ATTRS = {
  path: '/',
};

if (process.server) {
  const cookie = require('cookie');
  useCookies = function useServerCookies(): CookiesApi {
    const { req, res } = useContext();
    const cookies = cookie.parse(req.headers.cookie || '');

    return {
      cookies,
      setCookie(name, value, attrs = {}) {
        try {
          res.setHeader('Set-Cookie', cookie.serialize(name, value, { ...DEFAULT_ATTRS, ...attrs }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('set cookie error', e);
        }
      },
      removeCookie(name) {
        try {
          res.setHeader('Set-Cookie', cookie.serialize(name, '', { maxAge: 0 }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('set cookie error', e);
        }
      },
    };
  };

  useContextCookies = function useServerContextCookies(req: any, res: any): CookiesApi {
    const cookies = cookie.parse(req.headers.cookie || '');
    return {
      cookies,
      setCookie(name, value, attrs = {}) {
        try {
          res.setHeader('Set-Cookie', cookie.serialize(name, value, { ...DEFAULT_ATTRS, ...attrs }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('set cookie error', e);
        }
      },
      removeCookie(name) {
        try {
          res.setHeader('Set-Cookie', cookie.serialize(name, '', { maxAge: 0 }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('set cookie error', e);
        }
      },
    };
  };
} else {
  const jsCookies = require('js-cookie');

  useCookies = useContextCookies = function useClientCookies(): CookiesApi {
    const cookies = jsCookies.get() as Record<string, any>;

    return {
      cookies,
      setCookie(name, value, attrs = {}) {
        jsCookies.set(name, value, { ...DEFAULT_ATTRS, ...attrs });
      },
      removeCookie(name, attrs = {}) {
        jsCookies.remove(name, { ...DEFAULT_ATTRS, ...attrs });
      },
      getCookies: jsCookies.get,
    };
  };
}

export default useCookies;
