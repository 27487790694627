import { mergeWith } from 'lodash-es';

// TODO: get these values from configurations
const defaultTitle = 'White Label';
const currencyCode = 'EGP';

/**
 * Holds a promise reference to the gtagInit process
 */
let gtagInitPending;

/**
 * handles initializing the gtag with the required scripts
 * called on initializing the plugin
 */
function initGtag(gtagKey) {
  // Do not re-initialize the gtag script
  if (gtagKey && gtagInitPending) {
    return gtagInitPending;
  }

  if (gtagKey)
    gtagInitPending = new Promise((resolve, reject) => {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.defer = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        j.onload = resolve;
        j.onerror = reject;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtagKey);

      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtagKey}`;
      iframe.height = 0;
      iframe.width = 0;
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.appendChild(iframe);
      document.body.prepend(noscript);
    });
}

/**
 * maps the data received to the required snippets format
 * @param {*} item
 */
function mapItemData(item) {
  const itemData = {
    id: String(item.sku),
    name: item.name,
    brand: item.brand ? item.brand.url_key : '',
    category: item.categories && item.categories.length ? item.categories[item.categories.length - 1].name : '',
    variant: '',
    price: item.price ? String(item.price?.toFixed(2)) : String(item.unitPrice?.toFixed(2)),
  };

  return itemData;
}

/**
 * Maps App events to gtag events
 */
const GTAG_EVENTS_MAP = {
  product_impression(data) {
    const listName = data.listName;

    return {
      ecommerce: {
        currencyCode,
        impressions: [
          {
            ...mapItemData(data),
            list: listName,
          },
        ],
      },
    };
  },
  product_click(data) {
    const listName = data.listName;

    return {
      event: 'productClick',
      ga4: { ...data.ga4 },
      ecommerce: {
        currencyCode,
        click: {
          actionField: { list: listName },
          products: [
            {
              ...mapItemData(data),
            },
          ],
        },
      },
    };
  },
  product_detail_views(data) {
    return {
      event: 'product_detail_views',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ecommerce: {
        currencyCode,
        detail: {
          actionField: { list: data.listName },
          products: [
            {
              ...mapItemData(data.product),
            },
          ],
        },
      },
      ...data.weData,
    };
  },
  product_added_to_cart(data) {
    const listName = data.listname;

    return {
      event: 'addToCart',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ecommerce: {
        currencyCode,
        add: {
          actionField: { list: listName },
          products: [
            {
              ...mapItemData(data.product),
              quantity: data.quantity,
            },
          ],
        },
      },
      ...data.weData,
    };
  },
  product_removed_from_cart(data) {
    const listName = data.listname;

    return {
      event: 'removeFromCart',
      ga4: { ...data.ga4 },
      ecommerce: {
        currencyCode,
        remove: {
          actionField: { list: listName },
          products: [
            {
              ...mapItemData(data.product),
              quantity: data.quantity,
            },
          ],
        },
      },
      ...data.weData,
    };
  },
  checkout_step({ step, items, option }) {
    // Checkout steps: 1 -> Shipping methods; 2 -> Payment methods
    return {
      event: 'checkout',
      ecommerce: {
        currencyCode,
        checkout: {
          actionField: { step, option },
          products: items.map(item => {
            return {
              ...mapItemData(item),
              quantity: item.quantity,
            };
          }),
        },
      },
    };
  },
  checkout_step_option({ step, option, items }) {
    return {
      event: 'checkoutOption',
      ecommerce: {
        currencyCode,
        checkout: {
          actionField: { step, option },
          products: items.map(item => {
            return {
              ...mapItemData(item),
              quantity: item.quantity,
            };
          }),
        },
      },
    };
  },
  purchase({ order, items, listName, transactionData, ga4, weData }) {
    return {
      event: 'purchase',
      ...transactionData,
      ga4,
      ecommerce: {
        currencyCode,
        purchase: {
          actionField: {
            id: order.number,
            affiliation: defaultTitle,
            revenue: String(order.total.toFixed(2)),
            tax: '00.00',
            shipping: String(order.shippingFees.toFixed(2) || '00.00'),
          },
          products: items.map(item => {
            return {
              ...mapItemData(item),
              quantity: item.quantity,
              list: item.listname || listName,
            };
          }),
        },
      },
      ...weData,
    };
  },
  search(data) {
    return {
      event: 'Search',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ...data.weData,
    };
  },
  taksety_form_started(data) {
    return {
      event: 'taksetyFormStarted',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ...data.weData,
    };
  },
  taksety_form_submitted(data) {
    return {
      event: 'taksetyFormSubmitted',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ...data.weData,
    };
  },
  taksety_form_personal_data(data) {
    return {
      event: 'taksetyFormPersonalData',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ...data.weData,
    };
  },
  taksety_form_address_data(data) {
    return {
      event: 'taksetyFormAddressData',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ...data.weData,
    };
  },
  taksety_form_occupation_data(data) {
    return {
      event: 'taksetyFormOccupationData',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ...data.weData,
    };
  },
  taksety_form_additional_info_data(data) {
    return {
      event: 'taksetyFormAdditionalInfoData',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ...data.weData,
    };
  },
  completing_registration(data) {
    return {
      event: 'completingRegistration',
      ...data.transactionData,
      ...data.weData,
    };
  },
  add_to_wishlist(data) {
    return {
      event: 'addToWishlist',
      ...data.transactionData,
      ga4: { ...data.ga4 },
      ...data.weData,
    };
  },
};

let pendingPush;
let pendingData;

/**
 * Triggers the analytic event
 */
function gtag(evt, payload) {
  // handle if event not present in map
  if (!GTAG_EVENTS_MAP[evt] && !WEBENGAGE_EVENTS_MAP.find(event => event === evt)) {
    throw new Error(`Event: ${evt} does not exist`);
  }
  const isGAEvent = Boolean(GTAG_EVENTS_MAP[evt]);
  const webEngagePayload = {
    event: payload.event,
    ...payload.weData,
  };
  // get event's data
  let data = isGAEvent ? GTAG_EVENTS_MAP[evt](payload) : webEngagePayload;

  /**
   * Pushes the data into the data layer
   */
  function commitDataToLayer() {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('GTM Event Pushed: ', evt, data);
      return;
    }
    pushLayer(data);
  }
  function commitToWEDataLayer() {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('GTM WE Event Pushed: ', evt, data);
      return;
    }
    pushLayer(data, true);
  }

  /**
   * Some events can be grouped like product impressions
   */
  const canBeGrouped = ['product_impression'].includes(evt);
  if (!canBeGrouped) {
    if (isGAEvent) {
      commitDataToLayer();
    } else {
      commitToWEDataLayer();
    }
    return;
  }

  // debounce the event's calls (in case of product impressions triggered multiple times)
  if (pendingPush) {
    // if we have any timeouts set -> clear them
    clearTimeout(pendingPush);
    if (pendingData) {
      // if we have data pending from previous timeouts -> merge it with new data
      data = mergeWith(data, pendingData, (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return [...objValue, ...srcValue];
        }
      });
    }

    // clear pending data
    pendingData = null;
    pendingPush = null;
  }

  pendingData = data;
  pendingPush = setTimeout(() => {
    pendingData = null;
    if (isGAEvent) {
      commitDataToLayer();
    } else {
      commitToWEDataLayer();
    }
  }, 200);
}

async function pushLayer(data, isWeData) {
  await gtagInitPending;
  if (!window.dataLayer) return;
  if (isWeData) {
    // resetting product details to handle overlapping data
    window.dataLayer.push({ product_details: undefined });
  }
  window.dataLayer.push(data);
}

export default function GTM({ $config }, inject) {
  initGtag($config.gtmKey);
  inject('gtag', gtag);
}

const WEBENGAGE_EVENTS_MAP = [
  'userSignedUp',
  'userLoggedIn',
  'bannerClicked',
  'categoryViewed',
  'subCategoryViewed',
  'microCategoryViewed',
  'removedFromWishlist',
  'addedToCompareProducts',
  'removedFromCompareProducts',
  'cartUpdated',
  'cartViewed',
  'checkoutStarted',
  'userLoggedOut',
  'languageSelected',
  'couponCodeApplied',
  'couponCodeFailed',
  'shippingDetailsUpdated',
  'filterSelected',
  'maintenanceRequestFormSubmitted',
  'otpVerified',
  'proceedWithInstallments',
];
