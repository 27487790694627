import { computed, ComputedRef, provide, useAsync } from '@nuxtjs/composition-api';
import { useQuery } from 'villus';
import { keyBy } from 'lodash-es';
import { useCachedSsrRef } from './serverCache';
import { useSetLocaleToCacheParams } from './i18n';
import { FeatureFlagsDocument, StoreConfigDocument } from '@/graphql/Config';
import { CountryCodeEnum } from '~/graphql-types.gen';
import { CONFIG_STORE_COUNTRY, CONFIG_STORE_CURRENCY } from '~/utils/provides';
import { pipe } from '~/utils/collections';

export type AttributesType =
  | {
      code: string;
      options: {
        label: string;
        value: string;
      }[];
    }[]
  | [];

// ! https://github.com/nuxt-community/composition-api/issues/19
/**
 * in order to use computed property returned from useAsync/useFetch , use empty fake setter to overcome the warning .
 */
interface StoreConfig {
  currencyCode: string | null | undefined;
  defaultTitle: string | null | undefined;
  defaultDescription: string | null | undefined;
  defaultKeywords: string | null | undefined;
  title_prefix: string | null | undefined;
  title_suffix: string | null | undefined;
  initialized: boolean; // if it was populated from API
  offerLabel: string | null | undefined;
  headerPromotedLabel: string | null | undefined;
  raya_seller_emails: string | null | undefined;
  offerPageTitle: string | null | undefined;
  homeFooterContent: string | null | undefined;
  dealsFooterContent: string | null | undefined;

  productsSeoTemplateTitle: string | null | undefined;
  productsSeoTemplateKeywords: string | null | undefined;
  productsSeoTemplateDescription: string | null | undefined;
  categoriesSeoTemplateTitle: string | null | undefined;
  categoriesSeoTemplateKeywords: string | null | undefined;
  categoriesSeoTemplateDescription: string | null | undefined;
  attributes: AttributesType;
  promotedCategory: string | null | undefined;
  promotedCategoryId: number | null | undefined;
  payInstallmentFees: string | null | undefined;
  digitalWalletFees: string | null | undefined;
  blackFridayThemeEnabled: boolean | null | undefined;
  blackFridayEndDate: string | null | undefined;
  taksetyClubMembershipRequired: boolean | null | undefined;
  taksetyMedicalInsuranceRequired: boolean | null | undefined;
  taksetyCarOwnershipRequired: boolean | null | undefined;
  taksetyguarantorInfoRequired: boolean | null | undefined;
  ramadanThemeEnabled: boolean | null | undefined;
}

// 30 minutes
const CACHE_TTL = 30 * 60 * 1000;

export function useStoreConfig() {
  const { cacheParam } = useSetLocaleToCacheParams();
  const cachingKey = pipe(cacheParam)('storeConfig');

  const state = useCachedSsrRef<StoreConfig>(
    cachingKey,
    {
      currencyCode: null,
      defaultTitle: null,
      defaultDescription: null,
      defaultKeywords: null,
      title_prefix: null,
      title_suffix: null,
      initialized: false,
      offerLabel: '',
      headerPromotedLabel: '',
      raya_seller_emails: '',
      offerPageTitle: '',
      homeFooterContent: '',
      dealsFooterContent: '',
      categoriesSeoTemplateDescription: '',
      categoriesSeoTemplateKeywords: '',
      categoriesSeoTemplateTitle: '',
      productsSeoTemplateDescription: '',
      productsSeoTemplateKeywords: '',
      productsSeoTemplateTitle: '',
      attributes: [],
      promotedCategory: '',
      promotedCategoryId: null,
      payInstallmentFees: null,
      digitalWalletFees: null,
      blackFridayThemeEnabled: false,
      blackFridayEndDate: '',
      taksetyClubMembershipRequired: false,
      taksetyMedicalInsuranceRequired: false,
      taksetyCarOwnershipRequired: false,
      taksetyguarantorInfoRequired: false,
      ramadanThemeEnabled: false,
    },
    CACHE_TTL
  );

  const { data, error, execute } = useQuery({
    query: StoreConfigDocument,
    fetchOnMount: false,
  });

  useAsync(async () => {
    // Skip re-fetching the store config
    if (state.value.initialized) {
      return;
    }

    await execute();

    if (error.value) {
      throw new Error(error.value.message);
    }

    state.value = {
      currencyCode: data.value?.storeConfig?.base_currency_code,
      defaultTitle: data.value?.storeConfig?.default_title,
      defaultDescription: data.value?.storeConfig?.default_description,
      defaultKeywords: data.value?.storeConfig?.default_keywords,
      title_prefix: data.value?.storeConfig?.title_prefix,
      title_suffix: data.value?.storeConfig?.title_suffix,
      initialized: true,
      offerLabel: data.value?.storeConfig?.offers_label,
      headerPromotedLabel: data.value?.storeConfig?.header_promoted_label,
      raya_seller_emails: data.value?.storeConfig?.raya_seller_emails,
      offerPageTitle: data.value?.storeConfig?.slider_title_label,
      homeFooterContent: data.value?.storeConfig?.home_footer_content,
      dealsFooterContent: data.value?.storeConfig?.deals_footer_content,
      productsSeoTemplateDescription: data.value?.storeConfig?.products_seo_template_description,
      productsSeoTemplateKeywords: data.value?.storeConfig?.products_seo_template_keywords,
      productsSeoTemplateTitle: data.value?.storeConfig?.products_seo_template_title,
      categoriesSeoTemplateDescription: data.value?.storeConfig?.categories_seo_template_description,
      categoriesSeoTemplateKeywords: data.value?.storeConfig?.categories_seo_template_keywords,
      categoriesSeoTemplateTitle: data.value?.storeConfig?.categories_seo_template_title,
      promotedCategory: data.value?.storeConfig?.promoted_category_url_key,
      promotedCategoryId: data.value?.storeConfig?.promoted_category_id,
      payInstallmentFees: data.value?.storeConfig?.pay_installment_fees,
      digitalWalletFees: data.value?.storeConfig?.pay_installment_digital_wallet_fees,
      blackFridayThemeEnabled: data.value?.storeConfig?.blackfriday_theme_enabled,
      blackFridayEndDate: data.value?.storeConfig?.blackfriday_end_date,
      taksetyClubMembershipRequired: data.value?.storeConfig?.club_membership,
      taksetyMedicalInsuranceRequired: data.value?.storeConfig?.medical_insurance,
      taksetyCarOwnershipRequired: data.value?.storeConfig?.car_ownership,
      taksetyguarantorInfoRequired: data.value?.storeConfig?.guarantor_info,
      ramadanThemeEnabled: data.value?.storeConfig?.ramadan_theme,
      attributes:
        data.value?.attributes?.items?.map(attribute => {
          return {
            code: attribute?.attribute_code ?? '',
            options:
              attribute?.attribute_options?.map(option => ({
                label: option?.label ?? '',
                value: option?.value ?? '',
              })) || [],
          };
        }) || [],
    };
  });

  const refs: Record<keyof Partial<typeof state['value']>, ComputedRef<any>> = {
    currencyCode: computed(() => state.value?.currencyCode),
    defaultTitle: computed(() => state.value?.defaultTitle),
    defaultDescription: computed(() => state.value?.defaultDescription),
    defaultKeywords: computed(() => state.value?.defaultKeywords),
    title_prefix: computed(() => state.value?.title_prefix),
    title_suffix: computed(() => state.value?.title_suffix),
    offerLabel: computed({ get: () => state.value?.offerLabel, set: () => {} }),
    headerPromotedLabel: computed(() => state.value?.headerPromotedLabel),
    raya_seller_emails: computed(() => state.value?.raya_seller_emails),
    offerPageTitle: computed(() => state.value?.offerPageTitle),
    initialized: computed(() => undefined),
    homeFooterContent: computed({ get: () => state.value?.homeFooterContent, set: () => {} }),
    dealsFooterContent: computed(() => state.value?.dealsFooterContent),
    productsSeoTemplateDescription: computed(() => state.value?.productsSeoTemplateDescription),
    productsSeoTemplateKeywords: computed(() => state.value?.productsSeoTemplateKeywords),
    productsSeoTemplateTitle: computed(() => state.value?.productsSeoTemplateTitle),
    categoriesSeoTemplateDescription: computed(() => state.value?.categoriesSeoTemplateDescription),
    categoriesSeoTemplateKeywords: computed(() => state.value?.categoriesSeoTemplateKeywords),
    categoriesSeoTemplateTitle: computed(() => state.value?.categoriesSeoTemplateTitle),
    attributes: computed(() => state.value?.attributes) as ComputedRef<AttributesType>,
    promotedCategory: computed(() => state.value.promotedCategory),
    promotedCategoryId: computed(() => state.value.promotedCategoryId),
    payInstallmentFees: computed(() => state.value.payInstallmentFees),
    digitalWalletFees: computed(() => state.value.digitalWalletFees),
    blackFridayThemeEnabled: computed(() => state.value.blackFridayThemeEnabled),
    blackFridayEndDate: computed(() => state.value.blackFridayEndDate),
    taksetyClubMembershipRequired: computed(() => state.value.taksetyClubMembershipRequired),
    taksetyMedicalInsuranceRequired: computed(() => state.value.taksetyMedicalInsuranceRequired),
    taksetyCarOwnershipRequired: computed(() => state.value.taksetyCarOwnershipRequired),
    taksetyguarantorInfoRequired: computed(() => state.value.taksetyguarantorInfoRequired),
    ramadanThemeEnabled: computed(() => state.value.ramadanThemeEnabled),
  };

  provide(CONFIG_STORE_CURRENCY, refs.currencyCode);
  // TODO: Maybe don't hard code this
  provide(CONFIG_STORE_COUNTRY, CountryCodeEnum.Eg);

  return {
    ...refs,
  };
}

export function useFeatureFlags() {
  const { execute } = useQuery({
    query: FeatureFlagsDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });
  return {
    fetchFlags: execute,
  };
}

export function useFetchFeatureFlags() {
  const { data } = useQuery({
    query: FeatureFlagsDocument,
    cachePolicy: 'network-only',
  });

  const featureFlags = computed(() => keyBy(data.value?.features, 'feature_name'));
  return {
    featureFlags,
  };
}
