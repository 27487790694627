import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ContactFormMutationVariables = Types.Exact<{
  input: Types.ContactFormInput;
}>;


export type ContactFormMutation = { __typename?: 'Mutation', contactForm?: string | null | undefined };

export type SubscribeToEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type SubscribeToEmailMutation = { __typename?: 'Mutation', subscribeEmailToNewsletter?: { __typename?: 'SubscribeEmailToNewsletterOutput', status?: Types.SubscriptionStatusesEnum | null | undefined } | null | undefined };

export type ContactFormSubjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContactFormSubjectsQuery = { __typename?: 'Query', response?: Array<{ __typename?: 'ContactFormSubject', id?: number | null | undefined, value?: string | null | undefined } | null | undefined> | null | undefined };

export type RequestMaintenanceMutationVariables = Types.Exact<{
  input: Types.CreateMaintenanceRequestInput;
}>;


export type RequestMaintenanceMutation = { __typename?: 'Mutation', createMaintenanceRequest: boolean };

export type GetServiceDetailsQueryVariables = Types.Exact<{
  receipt: Types.Scalars['String'];
  mobileNumber: Types.Scalars['String'];
}>;


export type GetServiceDetailsQuery = { __typename?: 'Query', getServiceDetails?: { __typename?: 'ServiceDetailsOutput', model?: string | null | undefined, serialNumber?: string | null | undefined, stage?: string | null | undefined, reason?: string | null | undefined, deliveryDate?: string | null | undefined, remarks?: string | null | undefined, systemMessage?: string | null | undefined } | null | undefined };

export declare const ContactForm: import("graphql").DocumentNode;
export declare const SubscribeToEmail: import("graphql").DocumentNode;
export declare const ContactFormSubjects: import("graphql").DocumentNode;
export declare const RequestMaintenance: import("graphql").DocumentNode;
export declare const GetServiceDetails: import("graphql").DocumentNode;

export const ContactFormDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ContactForm"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ContactFormInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"contactForm"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<ContactFormMutation, ContactFormMutationVariables>;
export const SubscribeToEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SubscribeToEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"subscribeEmailToNewsletter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<SubscribeToEmailMutation, SubscribeToEmailMutationVariables>;
export const ContactFormSubjectsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ContactFormSubjects"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"contact_form_subjects"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<ContactFormSubjectsQuery, ContactFormSubjectsQueryVariables>;
export const RequestMaintenanceDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RequestMaintenance"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateMaintenanceRequestInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createMaintenanceRequest"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<RequestMaintenanceMutation, RequestMaintenanceMutationVariables>;
export const GetServiceDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetServiceDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"receipt"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mobileNumber"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getServiceDetails"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"receipt"},"value":{"kind":"Variable","name":{"kind":"Name","value":"receipt"}}},{"kind":"Argument","name":{"kind":"Name","value":"mobileNumber"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mobileNumber"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"model"}},{"kind":"Field","name":{"kind":"Name","value":"serialNumber"}},{"kind":"Field","name":{"kind":"Name","value":"stage"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"deliveryDate"}},{"kind":"Field","name":{"kind":"Name","value":"remarks"}},{"kind":"Field","name":{"kind":"Name","value":"systemMessage"}}]}}]}}]} as unknown as DocumentNode<GetServiceDetailsQuery, GetServiceDetailsQueryVariables>;