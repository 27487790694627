import { reactive, useAsync } from '@nuxtjs/composition-api';
import { useMutation, useQuery } from 'villus';
import { useCachedSsrRef } from './serverCache';

import { useSetLocaleToCacheParams } from './i18n';
import {
  ContactFormDocument,
  SubscribeToEmailDocument,
  ContactFormSubjectsDocument,
  ContactFormSubjectsQuery,
  RequestMaintenanceDocument,
  GetServiceDetailsDocument,
} from '~/graphql/Contact';
import { CreateMaintenanceRequestInput } from '~/graphql-types.gen';

// 30 minutes
const CACHE_TTL = 1000 * 60 * 30;

export function useContactForm() {
  const { execute, isFetching } = useMutation(ContactFormDocument);
  const input = reactive({
    name: '',
    email: '',
    phone_number: '',
    description: '',
    reason_of_contact: '',
  });

  async function submitForm() {
    const { error } = await execute({
      input,
    });

    if (error) {
      throw error;
    }
  }

  return {
    input,
    submitForm,
    isSubmittingForm: isFetching,
  };
}

export function useSubscribeToNewsletter() {
  const { execute } = useMutation(SubscribeToEmailDocument);
  const input = reactive({
    email: '',
  });

  async function submitForm() {
    const { error } = await execute({
      email: input.email,
    });

    if (error) {
      throw error;
    }
  }

  return {
    input,
    submitForm,
  };
}

export function useContactFormSubjects() {
  const { cacheParam } = useSetLocaleToCacheParams();

  const formSubjects = useCachedSsrRef<ContactFormSubjectsQuery['response'] | null | undefined>(
    cacheParam('formSubjects'),
    [],
    CACHE_TTL
  );

  const { execute, isFetching } = useQuery({
    query: ContactFormSubjectsDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  useAsync(async () => {
    // Don't refetch formSubjects unless cache expired
    if (formSubjects.value?.length) {
      return;
    }

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    formSubjects.value = data?.response || [];
  });

  return {
    formSubjects,
    isFetching,
  };
}

export function useSubmitMaintenanceRequest() {
  const { execute } = useMutation(RequestMaintenanceDocument);

  async function submitForm(input: CreateMaintenanceRequestInput) {
    const { error, data } = await execute({
      input,
    });

    if (error) {
      throw new Error(error.message);
    }
    return { error, data };
  }

  return {
    submitForm,
  };
}

export function useTrackMaintenanceRequest() {
  const { execute, isFetching } = useQuery({
    query: GetServiceDetailsDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  async function trackMaintenanceRequest(input: { receipt: string; mobileNumber: string }) {
    const { data, error } = await execute({
      variables: input,
    });
    if (error) {
      console.log(error);
      throw new Error(error.message);
    }

    return data?.getServiceDetails;
  }

  return {
    trackMaintenanceRequest,
    isFetching,
  };
}
