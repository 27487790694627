import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { getUser } from '~/features/auth';

export default defineNuxtMiddleware(async function checkoutMiddleware({ redirect, app: { i18n }, req }) {
  const user = await getUser();
  const locale = (i18n as VueI18n).locale;
  let eligibleForTaksetyForm = false;

  // If user is not logged in, redirect to login page
  if (!user) {
    redirect(`/${locale}/login`);
    return;
  }

  // If user is not eligible for taksety form, redirect to home page
  if (process.server) {
    eligibleForTaksetyForm = Boolean(req.headers.cookie?.includes('pending_approval=true'));
  } else {
    eligibleForTaksetyForm = Boolean(document.cookie.includes('pending_approval=true'));
  }

  if (!eligibleForTaksetyForm) {
    redirect(`/${locale}/`);
    return;
  }

  if (!user.national_id) {
    redirect(`/${locale}/national-id`);
  }
});
