

















































































































































































import { computed, defineComponent, inject, ref, watch, watchEffect } from '@nuxtjs/composition-api';
import { useAuth, useLogout } from '~/features/auth';
import { useRouter } from '~/features/router';
import { Category, useMegaMenuCategories } from '~/features/categories';
import { useEvent, useEventBus } from '~/features/events';
import { AUTH_IS_LOGGED_IN } from '~/utils/provides';
import { useStoreConfig } from '~/features/storeConfig';
import { useI18n } from '~/features/i18n';
import { TRACKING_EVENTS } from '~/features/trackingHandlers';
import useCookies from '~/features/cookies';
import { ELITE_STORE } from '~/features/elite';

export default defineComponent({
  name: 'SideMenu',

  setup() {
    const { cookies } = useCookies();
    const isOpen = ref(false);
    const categoryLevel = ref(1);

    const { megaMenuCategories: featuredCategories } = useMegaMenuCategories();
    const { offerLabel, promotedCategory } = useStoreConfig();

    const { user } = useAuth();
    const { headerPromotedLabel } = useStoreConfig();

    const { emit } = useEventBus();
    const { getLocale } = useI18n();

    useEvent('TOGGLE_SIDE_MENU', value => {
      isOpen.value = value === undefined ? !isOpen.value : value;
    });
    const selectedCategory = ref<Category | undefined>(undefined);

    const selectedCategoryModel = ref<''>('');

    const isLoggedIn = inject(AUTH_IS_LOGGED_IN);
    const { redirect, route } = useRouter();
    const { logout } = useLogout();

    watchEffect(() => {
      if (process.server) {
        return;
      }

      window.document.body.classList.toggle('overflow-hidden', isOpen.value);
    });

    function close() {
      isOpen.value = false;
    }

    function updateCategoryLevel(level: number, category: Category) {
      if (category?.children?.length) {
        categoryLevel.value = level;
        selectedCategory.value = category;
        return;
      }
      redirect(`/${category.url_key}`);
    }

    function routeToSubcategory(category: Category, subcategory: Category) {
      redirect(`/${category.url_key}/${subcategory.url_key}`);
    }

    // close menu when navigating
    watch(route, () => {
      close();
    });

    function to(url: string) {
      redirect(url);
      close();
    }

    function trackLocaleSwitch() {
      emit(TRACKING_EVENTS.LANGUAGE_SELECTED, { language: getLocale() === 'en' ? 'ar' : 'en' });
    }

    const offer = computed(() => {
      return (selectedCategory.value as any)?.offer;
    });

    const isElite = computed(() => {
      return cookies[ELITE_STORE];
    });

    return {
      logout,
      close,
      selectedCategory,
      isLoggedIn,
      isOpen,
      categoryLevel,
      updateCategoryLevel,
      routeToSubcategory,
      featuredCategories,
      user,
      selectedCategoryModel,
      headerPromotedLabel,
      to,
      offer,
      offerLabel,
      promotedCategory,
      trackLocaleSwitch,
      isElite,
    };
  },
});
