











import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useEvent } from '~/features/events';

export default defineComponent({
  setup() {
    const isShown = ref(false);
    const limitMessage = ref('');
    useEvent('SHOW_LIMIT_MODAL', message => {
      isShown.value = true;
      limitMessage.value = message;
    });

    return {
      isShown,
      limitMessage,
    };
  },
});
