// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/home-art/bg-pattern-2.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/letter-a-2.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n@media (min-width: 1024px){\n.DialogBody-primary[data-v-5dd1cb4a]{\n    background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n\n    background-repeat:no-repeat\n}\n[dir=\"ltr\"] .DialogBody-primary[data-v-5dd1cb4a]{\n\n    background-position:calc(100% + 136px) -94px,-7px 172px\n}\n[dir=\"rtl\"] .DialogBody-primary[data-v-5dd1cb4a]{\n\n    background-position:calc(100% - (100% + 136px)) -94px,-7px 172px\n}\n}\n@media (min-width: 1024px){\n.DialogBody[data-v-5dd1cb4a]{\n    max-width:740px;\n    min-width:700px\n}\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
