//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate';

export default {
  name: 'TextInput',
  components: {
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    customLabelBg: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    autocomplete: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Setting The Interaction Mode for ValidationProvider
     * values that we're intrested in ['aggressive', 'lazy']
     */
    mode: {
      type: String,
      default: 'aggressive',
    },
  },
  data: vm => ({
    innerValue: vm.value ?? '',
    showPassword: false,
  }),
  computed: {
    hasValue() {
      return !!this.innerValue.length;
    },
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value);
    },
    value(value) {
      if (value !== this.innerValue) {
        this.innerValue = value;
      }
    },
  },
};
