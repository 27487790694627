import { computed } from '@nuxtjs/composition-api';
import { useQuery } from 'villus';
import { FlashsaleDocument } from '~/graphql/FlashSale';

export function useFlashSale() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, isFetching } = useQuery({
    query: FlashsaleDocument,
  });

  const flashSale = computed(() => data?.value?.flashsale_offers || []);

  return {
    flashSale,
    isFetching,
  };
}
