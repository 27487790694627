

















































































import { computed, onMounted, ref, watch } from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { CombinedError } from 'villus';
import { useAlerts } from '~/features/alerts';
import { useRequestPasswordResetPhone } from '~/features/auth';
import { useVerifyElite } from '~/features/elite-checkout';
import { useEventBus } from '~/features/events';
import { useI18n } from '~/features/i18n';
import {
  useEditPhoneNumber,
  usePhoneVerification,
  useSendVerficationCode,
  useVerifyPhoneNumber,
  useSendOtpToGuestUser,
  useVerifyGuestUserOtp,
} from '~/features/phoneVerification';
import { handleMoveToNextInput } from '~/utils/events';
import { isEmail } from '~/utils/text';

export default defineComponent({
  name: 'VerifyPhoneNumber',
  components: {
    ValidationObserver,
  },
  model: {
    prop: 'modelValue',
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    alignment: {
      type: String,
      default: 'center',
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    changePhone: {
      type: Boolean,
      default: true,
    },
    passive: {
      type: Boolean,
      default: false,
    },
    isGuest: {
      type: Boolean,
      default: false,
    },
    verifyElite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { reSendVerficationCode, isFetching: isSendingVerificationCode } = useSendVerficationCode(props.modelValue);
    const { requestPasswordReset, isFetchingRequestResetPassword } = useRequestPasswordResetPhone();
    const { sendOtpToGuestUser } = useSendOtpToGuestUser();
    const { verifyGuestUserOtp } = useVerifyGuestUserOtp();

    const changeIdentityString = ref(false);
    const identityString = ref(props.modelValue);
    const { editPhoneNumber, isEditingCustomerPhone } = useEditPhoneNumber();
    const isSendingCode = computed(() => isFetchingRequestResetPassword.value || isSendingVerificationCode.value);

    const { input, phoneVerification } = usePhoneVerification();
    const { error, success } = useAlerts();
    const { t } = useI18n();
    const { verifyPhoneNumber } = useVerifyPhoneNumber(identityString);
    const { verifyElite } = useVerifyElite();

    const { emit: emitEventBus } = useEventBus();

    const firstInput = ref<HTMLInputElement | null>(null);

    function handleChangePhone() {
      if (props.isGuest) {
        emitEventBus('CLOSE_VERIFY_OTP');
        // TODO: focus on phone input
        return;
      }

      changeIdentityString.value = true;
    }

    function handleResendCode() {
      if (isEmail(identityString.value)) {
        onResendCodeEmail();
        return;
      }

      if (props.isGuest) {
        onResendGuestCode();
        return;
      }

      onResendCode();
    }

    async function onResendGuestCode() {
      const { message, otpSent } = await sendOtpToGuestUser();
      if (otpSent) {
        success(t('otp').toString(), t('otpCodeSent').toString());
      } else {
        error(t('resendError').toString(), message);
      }
    }

    async function onResendCode() {
      try {
        await reSendVerficationCode(identityString.value);
        success(t('otp').toString(), t('otpCodeSent').toString());
      } catch (err: any) {
        error(t('resendError').toString(), err.message.replace('[GraphQL] ', ''));
      }
    }

    async function onResendCodeEmail() {
      try {
        await requestPasswordReset(identityString.value);
        success(t('otp').toString(), t('otpCodeSent').toString());
      } catch (err: any) {
        error(t('resendError').toString(), err.message.replace('[GraphQL] ', ''));
      }
    }

    async function onSubmit() {
      try {
        await editPhoneNumber(props.modelValue, identityString.value);
        emit('input', identityString.value);

        changeIdentityString.value = false;
      } catch (err) {
        if (/\[GraphQL\] A customer exists with the new phone number\./.test((err as CombinedError).message)) {
          error('phone', 'A customer exists with the new phone number.');
        }
      }
    }

    onMounted(() => {
      firstInput.value?.focus();
    });

    async function verifyGuestOtp(otp: string) {
      try {
        const { success: verifiedSuccessfully } = await verifyGuestUserOtp(otp);
        if (verifiedSuccessfully) {
          success(t('otp') as string, t('otpVerified') as string);
          emitEventBus('CLOSE_VERIFY_OTP', { otp });
          emitEventBus('CART_VERIFIED');
        } else {
          error(t('otp') as string, t('wrongOtp') as string);
        }
      } catch (e) {
        if (/\[GraphQL\] Wrong OTP/.test((e as CombinedError).message)) {
          error(t('otp') as string, t('wrongOtp') as string);
          return;
        }

        error('error', (e as CombinedError).message);
      }
    }

    watch(phoneVerification, async value => {
      if (!value) return;

      if (props.passive) {
        emit('otp', value);
        return;
      }

      if (props.isGuest) {
        verifyGuestOtp(value);
        return;
      }

      try {
        props.verifyElite ? await verifyElite(value) : await verifyPhoneNumber(value);
        emitEventBus('CLOSE_VERIFY_OTP');
        emitEventBus('ACCOUNT_GOT_VERIFIED');
      } catch (e) {
        if (/\[GraphQL\] Wrong OTP/.test((e as CombinedError).message)) {
          error(t('otp') as string, t('wrongOtp') as string);
          return;
        }

        error('error', (e as CombinedError).message);
      }
    });

    return {
      handleMoveToNextInput,
      input,
      changeIdentityString,
      identityString,
      onSubmit,
      isSendingCode,
      reSendVerficationCode,
      isEditingCustomerPhone,
      handleResendCode,
      firstInput,
      handleChangePhone,
    };
  },
});
