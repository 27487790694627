import { isBefore, isAfter } from 'date-fns';
import { reactive, computed, watch, toRefs, ref, ComputedRef, Ref, inject } from '@nuxtjs/composition-api';
import { CombinedError, useMutation, useQuery } from 'villus';
import {
  AddConfigurableItemDocument,
  CustomerCartIdDocument,
  CustomerCartIframeUrlDocument,
  CustomerCartQueryVariables,
  GuestCartIframeUrlDocument,
  GuestPaymentMethodsDocument,
  MergeCartDataDocument,
  MergeCartItemsDocument,
  RemoveCustomOptionsDocument,
  SetFlipAndWinChoiceOnCartDocument,
  SetInstallmentPlanOnCartDocument,
  SetMpgsInstallmentPlanOnCartDocument,
  SetOrderMediumSourceOnCartDocument,
  SetPaymentMethodOnCartWithInstallmentOptionDocument,
  SetPaymentMethodOnCartWithInstallmentOptionMutation,
  UpdateInstallmentsItemsDocument,
} from './../graphql/Cart';
import {
  SelectedPaymentMethod,
  PlaceOrderInput,
  SetPaymentMethodOnCartOutput,
  SingleInstallmentPlanOutput,
  CustomizableCheckboxValue,
  PriceTypeEnum,
  UpdateCartItemCustomizableOptionsInput,
  ConfigurableProductCartItemInput,
  CustomizableCheckboxOption,
  CountryCodeEnum,
  BundleRelatedProductIdsInput,
  ConfigurableCartItem,
} from './../graphql-types.gen';
import { useAuth } from './auth';
import { useI18n } from './i18n';
import { useAlerts } from './alerts';
import { useEventBus } from './events';
import { TRACKING_EVENTS } from './trackingHandlers';
import { useRouter } from './router';
import { useChannels, topics } from './channels';
import { calculateDownpayment } from './installments';
import { useGetEliteInfo } from './elite';
import {
  AddItemDocument,
  ApplyCouponToCartDocument,
  ApplyCouponToCartMutation,
  CreateCartDocument,
  CustomerCartDocument,
  CustomerCartQuery,
  GuestCartDocument,
  PaymentMethodsDocument,
  PlaceOrderDocument,
  RemoveCouponFromCartDocument,
  RemoveItemDocument,
  SetBillingAddressDocument,
  SetPaymentMethodOnCartDocument,
  SetShippingMethodDocument,
  UpdateItemDocument,
  AddSelectedStoreToCartDocument,
  SetOrderSourceOnCartDocument,
  SetGuestEmailOnCartDocument,
} from '@/graphql/Cart';
import { installmentAmountPerMonth, extractInstallmentPlan } from '~/features/installments';
import { MaybeReactive, Unpacked } from '~/types/utils';
import { resolveProductPrice } from '~/utils/product';
import { toNonNullable } from '~/utils/collections';
import useCookies from '~/features/cookies';
import { isPendingAuth } from '~/features/auth';
import { useThirdPartyPaymentMethodResolver } from '~/features/paymentMethods';

import {
  BillingAddressInput,
  CartAddressInput,
  CartItemInput,
  CartUserInputErrorType,
  Money,
  ShippingCartAddress,
  ShippingMethodInput,
  AmsStoreLocator,
} from '~/graphql-types.gen';

import { CONFIG_STORE_COUNTRY } from '~/utils/provides';
import { AddBundlePackToCartDocument } from '~/graphql/Cart';

const cartState: {
  cartId: string;
  email: string;
  items: ReturnType<typeof mapCartItem>[];
  total: number;
  subtotal: number;
  address: Partial<ShippingCartAddress> | undefined | null;
  pickupStoreAddress: Partial<AmsStoreLocator> | undefined | null;
  shippingFees: undefined | number;
  hasInvalidItems: Boolean | undefined;
  discounts: [] | { label: string; value: number }[];
  appliedCoupon: string;
  discount: Money | null;
  selectedPaymentMethod: SelectedPaymentMethod | undefined;
  mpgsAuth: string | undefined;
  merchantId: string | undefined;
  reservedOrderNumber: string | undefined;
  acceptIframeUrl: string | undefined;
  // COD fees
  paymentFees?: number;
  extraFees?: number;
  signAtBranch: boolean;
  guestUserVerified: boolean;
  acceptInstallmentBankId: number | undefined;
  acceptInstallmentPlanMonths: number | undefined;
  acceptInstallmentPlanInterestAmount: number | undefined;
  acceptInstallmentPlanFees: number | undefined;
} = reactive({
  cartId: '',
  email: '',
  items: [],
  total: 0,
  subtotal: 0,
  shippingFees: undefined,
  address: null,
  pickupStoreAddress: null,
  hasInvalidItems: false,
  discounts: [],
  appliedCoupon: '',
  discount: null,
  selectedPaymentMethod: undefined,
  paymentMethods: [],

  mpgsAuth: undefined,
  merchantId: undefined,

  reservedOrderNumber: undefined,
  acceptIframeUrl: undefined,
  paymentFees: undefined,
  signAtBranch: false,
  extraFees: undefined,
  insuranceOptions: undefined,
  guestUserVerified: false,
  acceptInstallmentBankId: undefined,
  acceptInstallmentPlanMonths: undefined,
  acceptInstallmentPlanInterestAmount: undefined,
  acceptInstallmentPlanFees: undefined,
});

const guestCartId = ref('');

export function useCustomerCart() {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute } = useQuery({
    query: CustomerCartDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
    variables: { corporateId: attachedCorporate?.value?.id },
  });

  async function getCart() {
    const { data, error } = await execute();

    if (error && !/some of the products are out of stock./i.test(error.message)) {
      throw new Error(error.message);
    }

    if (data?.cart) {
      patchCartState(mapCartState(data?.cart));
    }
  }

  return {
    getCart,
  };
}

export function useGuestCart() {
  const { execute } = useQuery({ query: GuestCartDocument, fetchOnMount: false, cachePolicy: 'network-only' });

  async function getCart() {
    try {
      const { data, error } = await execute({
        variables: {
          cartId: cartState.cartId,
        },
      });

      // bypass the generic cart error
      // it only means other items in the cart are out of stock but the item
      // was added successfully
      if (error && !/some of the products are out of stock./i.test(error.message)) {
        throw new Error(error.message);
      }

      if (data?.cart) {
        patchCartState(mapCartState(data?.cart));
      }

      return mapCartState(data?.cart);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('An error occurred while getting guest cart:', error);
    }
  }

  return {
    getCart,
  };
}

let PENDING_GUEST_CART: Promise<typeof cartState | undefined> | undefined;
/**
 *
 * @description use cart set up function
 */
export function useSetupCart() {
  const { cookies, getCookies } = useCookies();

  const { subscribe } = useChannels();
  const { getCart: getGuestCart } = useGuestCart();
  const { getCart: getCustomerCart } = useCustomerCart();
  const { mergeCartItems } = useMergeCartItems();
  const { mergeCartData } = useMergeCartData();
  const { setOrderMediumSource } = useSetOrderMediumSource();

  if (cookies.cart) {
    cartState.cartId = cookies.cart;
    PENDING_GUEST_CART = getGuestCart();
  }

  subscribe(topics.auth.identify, async () => {
    if (process.browser) {
      await getCustomerCart();

      if (getCookies().cart) {
        if (getCookies().guest) {
          mergeCartData(getCookies().cart);
        } else {
          mergeCartItems(getCookies().cart);
        }
      }
      // if we have a medium source -> save it to cart
      if (localStorage.getItem('ORDER_SOURCE')) {
        const orderSource = localStorage.getItem('ORDER_SOURCE') as string;
        const { setSourceError } = await setOrderMediumSource(orderSource);
        // if source is successfully updated => remove it from local storage
        if (!setSourceError.value) {
          localStorage.removeItem('ORDER_SOURCE');
        }
      }
    }
  });

  return {};
}

export async function isGuestUserVerified() {
  // if we does't have the cart state yet -> wait for it
  if (PENDING_GUEST_CART) {
    const userVerified = (await PENDING_GUEST_CART)?.guestUserVerified;
    PENDING_GUEST_CART = undefined;
    return userVerified;
  }

  return cartState.guestUserVerified;
}

export function useCart() {
  const { isLoggedIn } = useAuth();
  const { cookies } = useCookies();
  const { warn } = useAlerts();
  const { t } = useI18n();
  const { attachedCorporate } = useGetEliteInfo();

  if (cookies.cart) {
    cartState.cartId = cookies.cart;
    guestCartId.value = cookies.cart;
  }
  const { data, execute, isFetching, error } = useQuery({
    query: computed(() => (isLoggedIn.value ? CustomerCartDocument : GuestCartDocument)),
    variables: computed(() =>
      isLoggedIn.value
        ? ({
            corporateId: attachedCorporate.value?.id,
          } as CustomerCartQueryVariables)
        : {
            cartId: cartState.cartId,
            corporateId: attachedCorporate.value?.id,
          }
    ),
    cachePolicy: 'network-only',
    fetchOnMount: computed(() => {
      return !isPendingAuth.value;
    }).value,
  });

  watch(data, value => {
    patchCartState(mapCartState(value?.cart));
  });

  watch(error, value => {
    // display a warning message for the user to remove the out of stock items from the cart
    if (value && /\[GraphQL\] Some of the products are out of stock\./.test(value.message)) {
      warn(t('productsOutOfStock').toString());
    }
  });

  const count = computed(() => {
    return cartState.items.reduce((sum, curr) => {
      return sum + curr.quantity;
    }, 0);
  });

  const uniqueCount = computed(() => {
    return cartState.items.length;
  });

  const crossSellingProducts = computed(() => {
    return cartState.items.reduce<string[] | []>((accu, item) => {
      return [...accu, ...item.crossSelling];
    }, []);
  });
  return {
    ...toRefs(cartState),
    count,
    uniqueCount,
    refetch: execute,
    isFetching,
    crossSellingProducts,
  };
}

export function useCheckableItems() {
  return {
    isCheckable: computed(() => {
      return cartState.items.length && cartState.items.every(item => item.stock > 0);
    }),
  };
}

/**
 * function to check whether the cart is able to be checked out with installments or not
 *
 */
export function useInstallmentsCartItems() {
  return {
    isAbleToPayWithInstallments: computed(() => {
      return cartState.items.every(
        item =>
          item.stock > 0 &&
          item.installments?.installment_data?.length &&
          item.rayaPlanOptionId &&
          item.downPaymentOptionId
      );
    }),
  };
}

/**
 * Manages adding items to cart
 */
export function useAddCartItem() {
  const { execute, isFetching } = useMutation(AddItemDocument);
  const { execute: executeAddConfigurableProduct, isFetching: isFetchingConfigurableProduct } = useMutation(
    AddConfigurableItemDocument
  );
  const { prepareCart } = usePrepareCart();
  const { resetCart } = useResetCart();
  const { cartError } = useCartErrors();
  const { emit } = useEventBus();
  const { count } = useCartAttributes();
  const { attachedCorporate } = useGetEliteInfo();

  type ConfigurableCartInput = ConfigurableProductCartItemInput | ConfigurableProductCartItemInput[];

  async function addItem(item: CartItemInput | CartItemInput[]) {
    // isAddingItem.value = true;
    await prepareCart();
    const items = Array.isArray(item) ? item : [item];

    const result = await execute({
      cartId: cartState.cartId,
      items,
      corporateId: attachedCorporate?.value?.id,
    });

    if (
      result.error &&
      (/\[GraphQL\] Current user does not have an active cart\./i.test(result.error.message) ||
        /The current user cannot perform operations on cart "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] Could not find a cart with ID "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] The cart isn't active\./i.test(result.error.message))
    ) {
      resetCart();
      await addItem(item);
      return;
    }

    if (result.error) {
      throw new Error(result.error.message);
    }

    if (result.data?.response) {
      if (result.data?.response?.user_errors?.length > 0) {
        result.data?.response?.user_errors.forEach(error => {
          cartError({ code: error?.code, message: error?.message });
        });
        // if adding an item failed -> throw an error
        if (result.data?.response?.cart?.items?.length === cartState.items.length) {
          throw new Error('Cart error');
        }
      }
      patchCartState(mapCartState(result.data.response.cart));

      for (let i = 0; i < items.length; i++) {
        const cartItem = getCartItem(items[i].sku);

        if (!cartItem) {
          return;
        }

        emit(TRACKING_EVENTS.ADD_TO_CART, { ...cartItem, currency: 'EGP' });
        emit(TRACKING_EVENTS.CART_UPDATED, {
          items: cartState.items,
          quantity: count.value,
          total_amount: cartState.total,
        });
      }
    }
  }

  async function addConfigurableProductItem(item: ConfigurableCartInput) {
    // isAddingItem.value = true;
    await prepareCart();
    const items = Array.isArray(item) ? item : [item];

    const result = await executeAddConfigurableProduct({
      cartId: cartState.cartId,
      items,
    });

    if (
      result.error &&
      (/\[GraphQL\] Current user does not have an active cart\./i.test(result.error.message) ||
        /The current user cannot perform operations on cart "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] Could not find a cart with ID "(.*)"/gm.test(result.error.message) ||
        /\[GraphQL\] The cart isn't active\./i.test(result.error.message))
    ) {
      resetCart();
      await addConfigurableProductItem(item);
      return;
    }

    if (result.error) {
      throw new Error(result.error.message);
    }

    if (result.data?.response) {
      patchCartState(mapCartState(result.data.response.cart));

      for (const item of items) {
        const cartItem = getCartItem(item.data.sku);

        if (!cartItem) {
          return;
        }

        emit(TRACKING_EVENTS.ADD_TO_CART, { ...cartItem, currency: 'EGP' });
      }
    }
  }

  return {
    addItem,
    isFetching,
    addConfigurableProductItem,
    isFetchingConfigurableProduct,
  };
}

export function useAddBundlePackToCart() {
  const { refetch } = useCart();
  const { prepareCart } = usePrepareCart();

  const { execute } = useMutation(AddBundlePackToCartDocument);

  async function addBundlePackToCart(bundle_id: string, main_product_id: string, related_products_ids: number[]) {
    await prepareCart();
    const input = {
      bundle_id,
      bundle_quantity: '1',
      cart_id: cartState.cartId,
      main_product_id,
      related_products_ids: related_products_ids.map(id => ({
        product_id: id.toString(),
      })) as BundleRelatedProductIdsInput[],
    };
    const { data, error } = await execute({ input });
    if (error) {
      throw error;
    }
    if (data) {
      await refetch();
      return data.addBundlePackToCart;
    }
  }
  return {
    addBundlePackToCart,
  };
}

/**
 * Updates a Cart Item quantity
 */
export function useUpdateCartItem(id: MaybeReactive<number>) {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute, isFetching } = useMutation(UpdateItemDocument);
  // eslint-disable-next-line no-unused-vars
  const { cartError } = useCartErrors();
  const { emit } = useEventBus();
  const { t } = useI18n();

  async function updateItem(quantity: number, idToUpdate?: number) {
    const idValue = idToUpdate || (id as ComputedRef<number>).value ? (id as ComputedRef<number>).value : id;
    const item = cartState.items.find(i => String(idValue) === String(i.id));
    if (!item) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`Could not find product with ${idValue} in cart`);
      }

      return;
    }

    // Save old quantity to be used later for tracking
    const oldQuantity = item.quantity;
    const result = await execute({
      input: {
        cart_id: cartState.cartId,
        cart_items: [
          {
            cart_item_id: (id as ComputedRef<number>).value ? (id as ComputedRef<number>).value : (id as number),
            quantity,
          },
        ],
      },
      corporateId: attachedCorporate?.value?.id,
    });
    if (result.error) {
      if (/\[GraphQL\] Some of the products are out of stock\./.test(result.error.message)) {
        cartError({ code: CartUserInputErrorType.Undefined, message: result.error.message });
      } else {
        cartError({ message: t('qtyUnavailable').toString() });
      }
    }

    if (result.data.response?.cart) {
      patchCartState(mapCartState(result.data.response.cart));

      emit(quantity > oldQuantity ? TRACKING_EVENTS.ADD_TO_CART : TRACKING_EVENTS.PRODUCT_REMOVE_FROM_CART, {
        ...item,
        quantity: Math.abs(quantity - oldQuantity),
      });
      emit(TRACKING_EVENTS.CART_UPDATED, {
        items: cartState.items,
        quantity: Math.abs(quantity - oldQuantity),
        total_amount: cartState.total,
      });
    }
  }

  return {
    updateItem,
    isFetching,
  };
}

/**
 * Removes a cart item
 */
export function useRemoveCartItem(id: number) {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute, isFetching: isRemoving } = useMutation(RemoveItemDocument);
  const { emit } = useEventBus();
  const { error: errorAlert, warn } = useAlerts();
  const { t } = useI18n();
  const { count } = useCartAttributes();

  async function removeItem() {
    const item = cartState.items.find(i => String(i.id) === String(id));
    if (!item) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`Could not find product with ${id} in cart`);
      }
    }

    try {
      const result = await execute({
        input: {
          cart_id: cartState.cartId,
          cart_item_id: id,
        },
        corporateId: attachedCorporate?.value?.id,
      });

      if (result.data.response?.cart) {
        patchCartState(mapCartState(result.data.response.cart));

        emit(TRACKING_EVENTS.PRODUCT_REMOVE_FROM_CART, item);
        emit(TRACKING_EVENTS.CART_UPDATED, {
          items: cartState.items,
          quantity: count.value,
          total_amount: cartState.total,
        });
      }

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (e: any) {
      if (/ \[GraphQL\] Some of the products are out of stock\./.test(e)) {
        warn(t('stockError').toString(), t('productsOutOfStock').toString());
        return;
      }
      errorAlert(e.message);
    }
  }

  return { removeItem, isRemoving };
}

export function useSetCartAddress() {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute } = useMutation(SetBillingAddressDocument);
  const { setOrderSourceOnCart } = useSetOrderSourceOnCart();

  async function setCartAddress(addressId: number, address?: CartAddressInput) {
    // in case we are in the store pick up flow
    // we send a newly created dummy address to the set address mutation
    const billingAddress: BillingAddressInput =
      addressId >= 0
        ? {
            customer_address_id: addressId,
            use_for_shipping: true,
          }
        : {
            address,
            use_for_shipping: true,
          };

    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        billing_address: billingAddress,
      },
      corporateId: attachedCorporate?.value?.id,
    });

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response?.cart));
    }

    if (error) {
      throw new Error(error.message);
    }
    // set order source on cart
    await setOrderSourceOnCart();

    return data.response;
  }

  return {
    setCartAddress,
  };
}

export function useSetGuestEmailOnCart() {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute } = useMutation(SetGuestEmailOnCartDocument);

  async function setGuestEmailOnCart(email: string) {
    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        email,
      },
      corporateId: attachedCorporate?.value?.id,
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data.response?.cart) {
      patchCartState(mapCartState(data.response?.cart));
    }

    return data.response;
  }

  return {
    setGuestEmailOnCart,
  };
}

export function getGuestAddressFieldsFromCart() {
  const countryCode = inject(CONFIG_STORE_COUNTRY, CountryCodeEnum.Eg);
  return {
    telephone: cartState.address?.telephone,
    street: cartState.address?.street,
    building: cartState.address?.building,
    floor: cartState.address?.floor,
    apartment: cartState.address?.apartment,
    firstname: cartState.address?.firstname,
    lastname: cartState.address?.lastname,
    country_code: countryCode,
    city: cartState.address?.city,
    postcode: cartState.address?.postcode,
    region: {
      region: cartState.address?.region?.name,
      region_code: cartState.address?.region?.code,
      region_id: cartState.address?.region?.id,
    },
    address_name: cartState.address?.address_name,
    email: cartState.email,
  };
}

export function useCartItemCount(sku: string) {
  return {
    cartItemCount: computed(() => cartState.items.find(item => item.sku === sku)?.quantity),
    cartItemTotalPrice: computed(() => cartState.items.find(item => item.sku === sku)?.totalPrice),
    itemId: computed(() => parseInt(cartState.items.find(item => item.sku === sku)?.id || '', 10)),
  };
}

export function getCartItemCountById(id: string | undefined) {
  return computed(() => cartState.items.find(item => item.id === id)?.quantity);
}

export function getCartItemTotalPriceById(id: string | undefined) {
  return computed(() => cartState.items.find(item => item.id === id)?.totalPrice);
}

export function getCartItemInsuranceInfo(sku: string) {
  return {
    itemInsuranceOption: computed(
      () =>
        cartState.items.filter(item => item.sku === sku).find(item => item.selectedInsuranceOption || null)
          ?.selectedInsuranceOption
    ),
    itemIdWithInsurance: computed(
      () => cartState.items.filter(item => item.sku === sku).find(item => item.selectedInsuranceOption || null)?.id
    ),
    itemIdWithoutInsurance: computed(
      () => cartState.items.filter(item => item.sku === sku).find(item => !item.selectedInsuranceOption || null)?.id
    ),
  };
}

export function useUpdateProductStepItem(sku: string, selectedInsurance: Ref<CustomizableCheckboxValue | null>) {
  const { itemIdWithInsurance, itemIdWithoutInsurance } = getCartItemInsuranceInfo(sku);
  const idToUpdate = computed(() =>
    selectedInsurance.value && itemIdWithInsurance.value
      ? Number(itemIdWithInsurance.value)
      : Number(itemIdWithoutInsurance.value)
  );
  const { updateItem } = useUpdateCartItem(idToUpdate);
  const { warn, error: errorAlert } = useAlerts();
  const { t } = useI18n();

  const isUpdatingQuantity = ref(false);
  async function removeOneFromCart(qty?: number) {
    const cartItemCount: ComputedRef<number | undefined> = getCartItemCountById(String(idToUpdate.value));
    try {
      isUpdatingQuantity.value = true;
      cartItemCount.value && (await updateItem(qty ?? cartItemCount.value - 1, idToUpdate.value));
    } catch (e) {
      errorAlert((e as CombinedError).message);
    } finally {
      isUpdatingQuantity.value = false;
    }
  }

  async function addOneToCart() {
    try {
      isUpdatingQuantity.value = true;
      const cartItemCount = getCartItemCountById(String(idToUpdate.value));
      cartItemCount.value && (await updateItem(cartItemCount.value + 1, idToUpdate.value));
    } catch (e) {
      if ((e as CombinedError).message.includes('requested qty is not available')) {
        warn('stock', t('outOfStock').toString());
        return;
      }
      warn('stock', (e as CombinedError).message);
    } finally {
      isUpdatingQuantity.value = false;
    }
  }

  return {
    removeOneFromCart,
    addOneToCart,
    isUpdatingQuantity,
  };
}

export function useResetCart() {
  const { removeCookie } = useCookies();
  const { removeCartItems } = useRemoveCartItems();
  function resetCart() {
    clearCart();
    removeCookie('cart');
  }

  async function asyncResetCart() {
    await removeCartItems();
  }

  return {
    resetCart,
    asyncResetCart,
  };
}

export function useCartErrors() {
  const { error: errorAlert, warn } = useAlerts();
  const { t } = useI18n();

  function cartError(error: { code?: CartUserInputErrorType | undefined; message: string | undefined }) {
    switch (error.code) {
      case 'INSUFFICIENT_STOCK':
        errorAlert(t('stockError').toString(), error.message);
        break;

      case 'UNDEFINED':
        warn(t('stockError').toString(), t('productsOutOfStock').toString());
        break;
      default:
        errorAlert(t('stockError').toString(), error.message);
        break;
    }
  }

  return {
    cartError,
  };
}

/**
 * Clears the cart
 */
export function clearCart() {
  cartState.cartId = '';
  cartState.items = [];
  cartState.total = 0;
  cartState.subtotal = 0;
  cartState.address = undefined;
  cartState.shippingFees = undefined;
}

export function mapCartState(
  cart?:
    | (CustomerCartQuery['cart'] & NonNullable<SetPaymentMethodOnCartWithInstallmentOptionMutation['response']>['cart'])
    | (CustomerCartQuery['cart'] & Partial<SetPaymentMethodOnCartOutput['cart']>)
    | null
): typeof cartState {
  if (!cart) {
    return cartState;
  }

  return {
    cartId: cart.id,
    email: cart.email ?? '',
    total: cart.prices?.grand_total?.value ?? 0,
    subtotal: cart.prices?.subtotal_including_tax?.value ?? 0,
    discounts: toNonNullable(cart.prices?.discounts).map(d => ({ value: d.amount.value ?? 0, label: d.label })) ?? [],
    items: toNonNullable(cart.items).map(mapCartItem),
    address: cart.addresses[0] as any,
    /** the branch address of the selected pickup store */
    pickupStoreAddress:
      cart.selectedPickupStore && cart.selectedPickupStore?.length
        ? (cart.selectedPickupStore[0] as AmsStoreLocator)
        : undefined,
    shippingFees: cart.addresses[0]?.selected_shipping_method?.amount.value ?? undefined,
    /** the promocode applied to the cart */
    appliedCoupon: cart.applied_coupons ? (cart.applied_coupons[0]?.code as string) : '',
    /** the discount value after applying the promocode */
    discount: cart.prices?.discounts ? (cart.prices.discounts[0]?.amount as Money) : null,
    hasInvalidItems: cart.items?.some(item => {
      if (!item) {
        return;
      }

      const cartItem = mapCartItem(item);
      return !cartItem.stock || cartItem.quantity > cartItem.stock;
    }),
    selectedPaymentMethod: cart.selected_payment_method ?? undefined,
    mpgsAuth: cart.selected_payment_method?.mpgsCredentials?.mpgsAuthorizationHeader ?? undefined,
    merchantId: cart.selected_payment_method?.mpgsCredentials?.mpgsMerchantId ?? undefined,

    reservedOrderNumber: cart.reservedOrderNumber ?? undefined,
    acceptIframeUrl: (cart as any).acceptIframeUrl ?? undefined,
    signAtBranch: Boolean(cart.sign_at_branch),
    // paymentFees: cart.paymentFee,
    guestUserVerified: Boolean(cart.guest_user_verified),
    acceptInstallmentBankId: cart.accept_installment_bank_id ?? undefined,
    acceptInstallmentPlanMonths: cart.accept_installment_plan_months ?? undefined,
    acceptInstallmentPlanInterestAmount: cart.accept_installment_plan_interest_amount ?? undefined,
    acceptInstallmentPlanFees: cart.accept_installment_plan_fees_amount ?? undefined,
  };
}

function mapCartStateAddress(cart?: Partial<CustomerCartQuery['cart']> | null): Partial<typeof cartState> {
  if (!cart) {
    return cartState;
  }
  return {
    address: cart && (cart.addresses?.[0] as any),
    shippingFees: (cart?.addresses?.[0] as any)?.selected_shipping_method?.amount.value ?? undefined,
    total: cart.prices?.grand_total?.value ?? 0,
    subtotal: cart.prices?.subtotal_including_tax?.value ?? 0,
  };
}

function mapCartStatePayment(cart?: Partial<SetPaymentMethodOnCartOutput['cart']> | null): Partial<typeof cartState> {
  if (!cart) {
    return cartState;
  }
  return {
    selectedPaymentMethod: cart.selected_payment_method ?? undefined,
    mpgsAuth: cart.selected_payment_method?.mpgsCredentials?.mpgsAuthorizationHeader ?? undefined,
    merchantId: cart.selected_payment_method?.mpgsCredentials?.mpgsMerchantId ?? undefined,
    acceptIframeUrl: cart.acceptIframeUrl ?? undefined,
    paymentFees: cart.payment_fee,
    extraFees: cart.fee,
    total: cart.prices?.grand_total?.value ?? 0,
  };
}

function patchCartState(newState: Partial<typeof cartState>) {
  Object.keys(newState).forEach(key => {
    (cartState as any)[key] = (newState as any)[key];
  });
}

/**
 * Maps the cart item data returned from the API to be displayed in the cart pages.
 */
const SPECIAL_INSTALLMENT_KEY = 'Raya';
const SPECIAL_DOWNPAYMENT_KEY = 'Down Payment';

export function mapCartItem(apiItem: NonNullable<Unpacked<CustomerCartQuery['cart']['items']>>) {
  const newFrom = new Date(apiItem.product.special_from_date ?? Date.now());
  const newTo = new Date(apiItem.product.special_to_date ?? Date.now() + 1000);
  const now = new Date();
  const isNew = isAfter(newTo, now) && isBefore(newFrom, now);
  const baseProductPrice = resolveProductPrice(apiItem.product);
  const unitPrice = (apiItem.prices?.row_total_including_tax?.value || 0) / apiItem.quantity;
  const totalPrice = apiItem.prices?.row_total_including_tax.value || baseProductPrice * apiItem.quantity;

  const selectedInsuranceOption =
    (apiItem.__typename === 'SimpleCartItem' &&
      apiItem.customizable_options.find(option => option?.label === 'Insurance')?.values[0]) ||
    null;
  const insuranceOptions =
    (apiItem.product.__typename === 'SimpleProduct' &&
      (apiItem.product.options?.find(option => option?.title === 'Insurance') as CustomizableCheckboxOption)?.value) ||
    null;

  /**
   * in case of percentage insurnce:
   * use the insurance price as the percentage value to get the total insurance fees
   */
  const selectedInsurancePrice =
    selectedInsuranceOption?.price.type === PriceTypeEnum.Percent
      ? (apiItem.product.price_range.maximum_price?.final_price?.value || 0) *
        (selectedInsuranceOption.price.value / 100)
      : selectedInsuranceOption?.price.value;

  const oldInsurancePrice =
    selectedInsuranceOption?.price.type === PriceTypeEnum.Percent
      ? (apiItem.product.price_range.maximum_price?.regular_price?.value || 0) *
        (selectedInsuranceOption.price.value / 100)
      : selectedInsuranceOption?.price.value;

  const oldPrice = selectedInsuranceOption
    ? (apiItem.product.price_range.maximum_price?.regular_price.value || 0) + (oldInsurancePrice || 0)
    : apiItem.product.price_range.maximum_price?.regular_price.value;

  // TODO this section will be refactored to contain other third-party plans as well.
  const rayaPlansOption =
    apiItem.product.__typename === 'SimpleProduct' || apiItem.product.__typename === 'BundleProduct'
      ? apiItem.product.options?.find(option => option?.title === SPECIAL_INSTALLMENT_KEY)
      : null;
  const rayaPlanOptionId = rayaPlansOption?.uid;

  const downPaymentOptionId =
    apiItem.product.__typename === 'SimpleProduct' || apiItem.product.__typename === 'BundleProduct'
      ? apiItem.product.options?.find(option => option?.title === SPECIAL_DOWNPAYMENT_KEY)?.uid
      : null;

  const rayaPlansOptions = rayaPlansOption?.__typename === 'CustomizableDropDownOption' ? rayaPlansOption.value : [];

  const selectedDownPayment =
    apiItem.__typename === 'SimpleCartItem' || apiItem.__typename === 'BundleCartItem'
      ? apiItem?.customizable_options.find(option => option?.label === SPECIAL_DOWNPAYMENT_KEY)?.values[0]?.value
      : null;
  const selectedPlan = selectedDownPayment ? resolveSelectedProductPlan(apiItem, Number(selectedDownPayment)) : null;
  const lowestInstallmentPlan = apiItem?.product.installments?.installment_data
    ?.find(banks => banks?.method_name === 'Raya')
    ?.plans?.reduce(
      (accu, curr) => {
        const currentDownPayment = calculateDownpayment(
          unitPrice,
          selectedInsuranceOption?.price.value || 0,
          curr?.down_payment_percentage || 0
        );
        const accDownPayment = calculateDownpayment(
          unitPrice,
          selectedInsuranceOption?.price.value || 0,
          accu?.down_payment_percentage || 0
        );
        return (currentDownPayment || Number.MAX_SAFE_INTEGER) < (accDownPayment || Number.MAX_SAFE_INTEGER)
          ? { ...curr, down_payment: currentDownPayment }
          : { ...accu, down_payment: accDownPayment };
      },
      { down_payment: Number.MAX_SAFE_INTEGER }
    );
  const lowestAmount = lowestInstallmentPlan?.down_payment || 0;

  const item = {
    __typename: apiItem.__typename,
    id: apiItem.id,
    quantity: apiItem.quantity,
    name: apiItem.product.name,
    unitPrice,
    totalPrice,
    oldPrice,
    sku: apiItem.product.sku,
    isPendingRemoval: false,
    slug:
      apiItem.__typename === 'ConfigurableCartItem'
        ? `${apiItem.product.url_key}/var/${apiItem.configured_variant?.url_key}`
        : apiItem.product.url_key,
    isNew,
    stock: apiItem.__typename === 'BundleCartItem' ? 1000 : apiItem.product.only_x_left_in_stock || 0,
    image: {
      src: apiItem.product.thumbnail?.url,
      alt: apiItem.product.thumbnail?.label ?? apiItem.product.name,
    },
    mainCategory: apiItem.product.categories?.[0]?.name || '',
    seller: apiItem.product.seller?.email || '',
    special_installment_plans: mapPlan(
      apiItem.product.installments?.installment_data?.find(item => item?.method_name === SPECIAL_INSTALLMENT_KEY)
        ?.plans,
      unitPrice,
      selectedInsuranceOption?.price.value || 0
    ),
    installments: apiItem.product.installments,
    downPaymentOptionId,
    rayaPlanOptionId,
    rayaPlansOptions,

    // selected installment plan

    selectedDownPayment,
    selectedPlan,
    // cross selling products for the product
    crossSelling: apiItem.product.crosssell_products?.map(product => product?.sku as string) || [],
    rayaInstallmentsPlans: apiItem.product.installments?.installment_data?.find(banks => banks?.method_name === 'Raya')
      ?.plans,
    // displaying lowest plan for the product
    lowestAmount,
    lowestInstallmentPlan,

    minAddingQty: apiItem?.product?.stockQtyTerm?.[0]?.min_sale_qty || 1,
    maxAddingQty: apiItem?.product?.stockQtyTerm?.[0]?.max_sale_qty || Number.MAX_SAFE_INTEGER,
    bundleItems:
      apiItem.product?.__typename === 'BundleProduct'
        ? apiItem.product.items?.map(item => ({
            optionId: item?.options?.[0]?.optionId,
            productId: item?.options?.[0]?.product?.productId,
          })) || []
        : [],
    selectedInsuranceOption,
    selectedInsurancePrice,
    insuranceOptions,
    brand: apiItem.product.brand?.name || '',
    // Use Configurable Cart Item keys to overwrite apiItem?.product keys
    ...(apiItem?.__typename === 'ConfigurableCartItem'
      ? mapConfiguredVariant(apiItem?.configured_variant as ConfigurableCartItem['configured_variant'])
      : {}),
  };

  return item;
}

function mapConfiguredVariant(product: ConfigurableCartItem['configured_variant']) {
  return {
    name: product.name || '',
    image: {
      src: product.thumbnail?.url || '',
      alt: product.thumbnail?.label ?? (product.name || ''),
    },
    stock: product.only_x_left_in_stock || 0,
    sku: product.sku || '',
    unitPrice: product?.price_range?.maximum_price?.final_price.value ?? 0,
    oldPrice: product?.price_range?.maximum_price?.regular_price.value ?? 0,
  };
}

// function reduceCartSubTotal(items: any[]) {
//   return items.reduce((sum, { product, quantity }) => {
//     return product.price * quantity + sum;
//   }, 0);
// }

/**
 * Creates a new cart id if its not already created
 */
function usePrepareCart() {
  const { execute } = useMutation(CreateCartDocument);
  const { setCookie } = useCookies();

  async function prepareCart() {
    if (cartState.cartId) {
      return;
    }

    const { data, error } = await execute();
    if (error) {
      throw new Error(error.message);
    }

    if (data.response) {
      setCookie('cart', data.response, { expires: 2 });
      guestCartId.value = data.response;
      patchCartState({ cartId: data.response || '' });
    }
  }

  return {
    prepareCart,
  };
}
/**
 * maps the plan to recalculate the down payment in case an insurance option was added on the product
 * @param plans
 * @param price
 * @param insurance
 * @returns
 */
function mapPlan(
  plans: (SingleInstallmentPlanOutput | undefined | null)[] | undefined | null,
  price: number,
  insurance: number
) {
  return plans?.map(plan => ({
    ...plan,
    down_payment: calculateDownpayment(price, insurance, plan?.down_payment_percentage || 0),
    amount: installmentAmountPerMonth(
      price,
      Number(plan?.interest),
      calculateDownpayment(price, insurance, plan?.down_payment_percentage || 0),
      Number(plan?.months)
    ).toFixed(2),
  }));
}

export function getCartItem(sku: string) {
  return cartState.items.find(i => sku === i.sku);
}

export function getCartItems() {
  return cartState.items;
}

export function useApplyPromocode() {
  const { attachedCorporate } = useGetEliteInfo();

  const { execute, isFetching } = useMutation(ApplyCouponToCartDocument);

  const promocodeData = ref<ApplyCouponToCartMutation | null>(null);
  const promocodeError = ref<CombinedError | null>(null);

  async function applyCoupon(code: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.cartId, coupon_code: code },
      corporateId: attachedCorporate?.value?.id,
    });
    if (error) {
      throw new Error(error.message);
    }
    promocodeData.value = data;
    promocodeError.value = error;
    return {
      promocodeData,
      promocodeError,
    };
  }
  return {
    isFetching,
    applyCoupon,
  };
}

export function useRemovePromocode() {
  const { attachedCorporate } = useGetEliteInfo();

  const { execute, isFetching } = useMutation(RemoveCouponFromCartDocument);

  async function removeCode() {
    const { data, error } = await execute({
      input: { cart_id: cartState.cartId },
      corporateId: attachedCorporate?.value?.id,
    });
    if (error) {
      throw new Error(error.message);
    }
    patchCartState(mapCartState(data.response?.cart));
    return { data, error };
  }

  return {
    removeCode,
    isFetching,
  };
}
export function usePaymentMethods() {
  const { cookies } = useCookies();
  const { isLoggedIn } = useAuth();

  if (cookies.cart && process.browser) {
    cartState.cartId = cookies.cart;
  }

  const { data, error, isFetching } = useQuery({
    query: computed(() => (isLoggedIn.value ? PaymentMethodsDocument : GuestPaymentMethodsDocument)),
    variables: computed(() =>
      isLoggedIn.value
        ? ({} as { [k: string]: never })
        : {
            cartId: cartState.cartId,
          }
    ),
  });

  const { execute } = useMutation(SetPaymentMethodOnCartDocument);
  const { execute: executeWithInstallment } = useMutation(SetPaymentMethodOnCartWithInstallmentOptionDocument);
  const { setOrderSourceOnCart } = useSetOrderSourceOnCart();

  async function setPaymentMethod(code: string, phoneNumber?: string) {
    const { data } = await execute({
      input: {
        cart_id: cartState.cartId,
        payment_method: {
          code,
        },
      },
      phoneNumber,
    });

    if (data?.response?.cart) {
      patchCartState(mapCartStatePayment(data.response?.cart));
      // set order source on cart
      await setOrderSourceOnCart();
    }

    return data?.response;
  }

  async function setPaymentMethodWithInstallment(code: string, installment: number) {
    const { data } = await executeWithInstallment({
      input: {
        cart_id: cartState.cartId,
        payment_method: {
          code,
        },
      },
      installmentsMonths: installment,
    });

    if (data.response?.cart) {
      patchCartState(mapCartStatePayment(data.response?.cart));
      // set order source on cart
      await setOrderSourceOnCart();
    }

    return data.response;
  }

  const paymentMethods = computed(() => {
    return data.value?.cart?.paymentMethods || [];
  });

  return {
    paymentMethods,
    error,
    isFetching,
    setPaymentMethod,
    setPaymentMethodWithInstallment,
  };
}

function getCartId() {
  const { execute } = useQuery({ query: CustomerCartIdDocument, fetchOnMount: false });
  const fetchCartId = async () => {
    const { data, error } = await execute();
    if (error) {
      throw new Error(error.message);
    }
    if (data?.cart.id) {
      cartState.cartId = data.cart.id;
    }
    return data?.cart.id;
  };
  return { fetchCartId };
}

export function useShippingMethods() {
  const { execute, isFetching, error } = useMutation(SetShippingMethodDocument);
  const { fetchCartId } = getCartId();

  const shippingMethods = computed(() => {
    return toNonNullable(cartState.address?.available_shipping_methods).filter(method => method.available) || [];
  });

  // only interested in the error that may have consequences during checkout
  const setShippingMethodError = computed(() => {
    if (error.value?.message === '[GraphQL] Not all requested products can be delivered.') {
      return error.value.message;
    }
    return '';
  });

  async function setShippingMethod(input: ShippingMethodInput) {
    // handles the case where the cart id yet available after refresh
    if (!cartState.cartId) {
      await fetchCartId();
    }
    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        shipping_methods: [input],
      },
    });
    if (data?.response?.cart) {
      patchCartState(mapCartStateAddress(data.response?.cart));
    }

    return {
      data,
      error,
    };
  }

  return {
    setShippingMethod,
    shippingMethods,
    isSettingShippingMethod: isFetching,
    setShippingMethodError,
  };
}

export function useAddSelectedStoreToCart() {
  const { attachedCorporate } = useGetEliteInfo();

  const { execute, isFetching } = useMutation(AddSelectedStoreToCartDocument);

  async function addStorePickup(storeId: string) {
    const { data, error } = await execute({
      cartId: cartState.cartId,
      storeId,
      corporateId: attachedCorporate?.value?.id,
    });

    if (data.selectSourcesInCart?.cart) {
      patchCartState(mapCartState(data.selectSourcesInCart?.cart));
    }

    if (error) {
      throw new Error(error.message);
    }

    return {
      data,
      error,
    };
  }

  return {
    addStorePickup,
    isFetching,
  };
}

export function useCartAttributes() {
  const count: ComputedRef<number> = computed(() => {
    return cartState.items.reduce((sum, curr) => {
      return sum + curr.quantity;
    }, 0);
  });
  return {
    ...toRefs(cartState),
    count,
  };
}

/**
 * Get current selected Shipping type/method on the cart
 */

export function useSelectedShippingMethod() {
  const shippingMethod = computed(() => cartState.address?.selected_shipping_method || null);
  return {
    shippingMethod,
  };
}

export function useSelectedPaymentMethod() {
  const paymentMethod = computed(() => cartState.selectedPaymentMethod || null);
  return {
    paymentMethod,
  };
}

const isMergingCart = ref<Boolean>(false);

// merge carts when user successfully login
export function useMergeCartItems() {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute } = useMutation(MergeCartItemsDocument);
  const { isLoggedIn } = useAuth();
  const { removeCookie } = useCookies();
  const { success } = useAlerts();
  const { t } = useI18n();

  /**
   * In order to merge items in the cart the user must be logged in
   */
  async function mergeCartItems(sourceId: string) {
    try {
      if (isMergingCart.value) {
        throw new Error('cartIsMerging');
      }
      if (!isLoggedIn.value) {
        throw new Error(t('loggedInRequired').toString());
      }

      isMergingCart.value = true;

      const { data, error } = await execute({
        dest: cartState.cartId,
        source: sourceId,
        corporateId: attachedCorporate?.value?.id,
      });

      if (error) {
        throw new Error(error.message);
      }

      if (data.response) {
        removeCookie('cart');
        patchCartState(mapCartState(data.response));
      }

      success('success', t('cart.mergedSuccessfully').toString());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      if (/\[GraphQL\] The cart isn't active\./.test((e as CombinedError).message)) {
        // alertError('error', t('cartIsNotActive').toString());
        removeCookie('cart');
      }
    } finally {
      isMergingCart.value = false;
    }
  }

  return {
    mergeCartItems,
  };
}

// merge carts when user successfully login
export function useMergeCartData() {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute } = useMutation(MergeCartDataDocument);
  const { isLoggedIn } = useAuth();
  const { removeCookie } = useCookies();
  const { success } = useAlerts();
  const { t } = useI18n();
  const { emit } = useEventBus();
  const { getCart } = useCustomerCart();

  /**
   * In order to merge items in the cart the user must be logged in
   */
  async function mergeCartData(sourceId: string) {
    try {
      if (isMergingCart.value) {
        throw new Error('cartIsMerging');
      }
      if (!isLoggedIn.value) {
        throw new Error(t('loggedInRequired').toString());
      }

      isMergingCart.value = true;

      const { data, error } = await execute({
        dest: cartState.cartId,
        source: sourceId,
        corporateId: attachedCorporate?.value?.id,
      });

      if (error) {
        throw new Error(error.message);
      }

      if (data.response) {
        removeCookie('cart');
        await getCart();
        // Trigger event to place order after merging carts if guest user entered a password in guest checkout flow
        emit('CART_DATA_MERGED');
      }

      success('success', t('cart.mergedSuccessfully').toString());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);

      if (/\[GraphQL\] The cart isn't active\./.test((e as CombinedError).message)) {
        // alertError('error', t('cartIsNotActive').toString());
        removeCookie('cart');
      }
    } finally {
      isMergingCart.value = false;
    }
  }

  return {
    mergeCartData,
  };
}

/**
 * Updates a Cart Item installment plan options
 */
export function useUpdateItemsPlan() {
  const { attachedCorporate } = useGetEliteInfo();

  const { execute: executeUpdateItem, isFetching: isUpdatingItem } = useMutation(UpdateInstallmentsItemsDocument);

  async function updatePlan(input: UpdateCartItemCustomizableOptionsInput[]) {
    const { data } = await executeUpdateItem({
      cartId: cartState.cartId,
      items: input,
      corporateId: attachedCorporate?.value?.id,
    });
    if (data.response?.cart) {
      cartState.items = toNonNullable(data.response?.cart?.items).map(mapCartItem);
      patchCartState(mapCartState(data.response?.cart));
    }
  }

  return { updatePlan, isFetching: computed(() => isUpdatingItem.value) };
}

export function useRemoveItemCustomOptions() {
  const { attachedCorporate } = useGetEliteInfo();

  const { execute: executeRemoveOption, isFetching: isRemovingOption } = useMutation(RemoveCustomOptionsDocument);

  async function removeCustomOption(input: UpdateCartItemCustomizableOptionsInput[]) {
    const { data } = await executeRemoveOption({
      cartId: cartState.cartId,
      items: input,
      corporateId: attachedCorporate?.value?.id,
    });
    if (data.response?.cart) {
      cartState.items = toNonNullable(data.response?.cart?.items).map(mapCartItem);
      patchCartState(mapCartState(data.response?.cart));
    }
  }

  return { removeCustomOption, isRemovingOption };
}

export function usePlaceOrder() {
  const { execute, isFetching } = useMutation(PlaceOrderDocument);
  const { refetch } = useCart();

  async function placeOrder(input?: Partial<PlaceOrderInput>) {
    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        ...input,
      },
    });

    await refetch();

    return {
      orders: data.response?.orders,
      error,
    };
  }

  return {
    placeOrder,
    isFetching,
  };
}
export function useSetOrderSourceOnCart() {
  const { execute, isFetching } = useMutation(SetOrderSourceOnCartDocument);

  async function setOrderSourceOnCart() {
    const { data, error } = await execute({
      input: {
        cart_id: cartState.cartId,
        order_source: 'Web',
      },
    });

    return {
      data,
      error,
    };
  }

  return {
    setOrderSourceOnCart,
    isFetching,
  };
}

export function useRemoveCartItems() {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute, isFetching } = useMutation(UpdateItemDocument);
  const { cartId, items } = useCartAttributes();
  const removeCartItems = async () => {
    try {
      await execute({
        input: {
          cart_id: cartId.value,
          cart_items: items.value.map(item => ({
            cart_item_id: Number(item.id ?? 0),
            quantity: 0,
          })),
        },
        corporateId: attachedCorporate?.value?.id,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  return {
    removeCartItems,
    isFetching,
  };
}
export function useSubmitPlaceOrder() {
  const { cookies, removeCookie } = useCookies();
  const { query } = useRouter();

  const homeDeliverySelected = computed(() => 'home_delivery' in query.value);
  const { setShippingMethod, shippingMethods } = useShippingMethods();
  const { setPaymentMethod } = usePaymentMethods();

  const { placeOrder } = usePlaceOrder();
  const { resolvePaymentMethod } = useThirdPartyPaymentMethodResolver();
  const isSubmitting = ref<boolean>(false);
  // const { success, error } = useAlerts();

  function isThirdPartyMethod(method: { code: string }) {
    return method.code !== 'cashondelivery' && method.code !== 'free';
  }
  async function submitPlaceOrder() {
    try {
      const selectedPaymentMethod = ref(cartState.selectedPaymentMethod);
      const selectedShippingMethod = ref(cartState.address?.selected_shipping_method);
      isSubmitting.value = true;
      await setPaymentMethod(selectedPaymentMethod.value?.code || '');
      if (!selectedShippingMethod.value && homeDeliverySelected.value) {
        throw new Error('validation.checkout.requiredShippingMethod');
      }

      // on setting the shipping method we default to the store pickup method in case no method was selected (store pickup flow)
      if (!homeDeliverySelected.value) {
        await setShippingMethod({
          carrier_code: shippingMethods.value.find(shippingMethod => shippingMethod.carrier_code === 'amstorepickup')
            ?.carrier_code as string,
          method_code: shippingMethods.value.find(shippingMethod => shippingMethod.carrier_code === 'amstorepickup')
            ?.method_code as string,
        });
      }

      if (!(selectedPaymentMethod.value && selectedPaymentMethod.value.code)) {
        throw new Error('validation.checkout.requiredPaymentMethod');
      }

      if (isThirdPartyMethod(selectedPaymentMethod.value)) {
        resolvePaymentMethod(selectedPaymentMethod.value);
        return;
      }

      const { orders, error: orderError } = await placeOrder();

      // reset payment fees after order is placed
      cartState.paymentFees = 0;

      if (orderError) {
        throw new Error(orderError.message);
      }

      // clear cart after order is placed
      if (cookies.cart && process.browser) {
        clearCart();
        removeCookie('cart');
      }

      return orders;
    } catch (e) {
      throw new Error((e as CombinedError).message);
    } finally {
      isSubmitting.value = false;
    }
  }

  return {
    submitPlaceOrder,
    isSubmitting,
  };
}

export function resolveSelectedProductPlan(
  item: Unpacked<NonNullable<NonNullable<NonNullable<CustomerCartQuery['cart']>['items']>[number]>>,
  downPayment: number
): {
  month: number | string;
  pricePerMonth: number;
} | null {
  const selectedPlan =
    item.__typename === 'SimpleCartItem' || item.__typename === 'BundleCartItem'
      ? item.customizable_options.find(option => option?.label === SPECIAL_INSTALLMENT_KEY)?.values?.[0]
      : null;

  if (!selectedPlan) return null;

  return {
    ...extractInstallmentPlan(
      selectedPlan?.value || '',
      item?.prices?.row_total_including_tax?.value || 0,
      downPayment
    ),
  };
}

/**
 * handles saving the order medium source on cart
 * source examples:fb, google, arabyads
 */
export function useSetOrderMediumSource() {
  const { execute } = useMutation(SetOrderMediumSourceOnCartDocument);

  async function setOrderMediumSource(source: string) {
    const { data, error } = await execute({
      input: { cart_id: cartState.cartId, order_medium_source: source },
    });

    const setSourceData = computed(() => {
      return data?.response.cart;
    });

    const setSourceError = computed(() => error?.message);
    return {
      setSourceData,
      setSourceError,
    };
  }
  return {
    setOrderMediumSource,
  };
}

export function useSetBankInstallmentPlan() {
  const { execute } = useMutation(SetInstallmentPlanOnCartDocument);

  async function setBankInstallmentPlan(bankId: number, months: number) {
    const { data, error } = await execute({
      cartId: cartState.cartId,
      bankId,
      months,
    });

    if (error) {
      throw new Error(error.message);
    }

    patchCartState({
      acceptInstallmentPlanInterestAmount:
        data.setInstallmentPlanOnCart?.cart.accept_installment_plan_interest_amount ?? 0,
      acceptInstallmentPlanFees: data.setInstallmentPlanOnCart?.cart.accept_installment_plan_fees_amount ?? 0,
      total: data.setInstallmentPlanOnCart?.cart.prices?.grand_total?.value ?? 0,
    });
  }

  return {
    setBankInstallmentPlan,
  };
}

export function useGetCartIframeUrl() {
  const { isLoggedIn } = useAuth();

  const { execute, isFetching } = useQuery({
    query: isLoggedIn.value ? CustomerCartIframeUrlDocument : GuestCartIframeUrlDocument,
    fetchOnMount: false,
  });

  async function getCartIframeUrl() {
    const { data, error } = await execute({
      variables: isLoggedIn ? {} : ({ cartId: cartState.cartId } as any),
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data?.cart?.acceptIframeUrl) {
      patchCartState({
        acceptIframeUrl: data.cart.acceptIframeUrl,
      });
    }
  }

  return {
    getCartIframeUrl,
    isFetching,
  };
}

export function useSetMpgsInstallmentPlanOnCart() {
  const { execute } = useMutation(SetMpgsInstallmentPlanOnCartDocument);

  async function setMpgsInstallmentPlanOnCart(months: number) {
    const { data, error } = await execute({
      cartId: cartState.cartId,
      months,
    });

    if (error) {
      throw new Error(error.message);
    }

    patchCartState({
      extraFees: data.setMpgsInstallmentPlanOnCart?.cart.fee ?? cartState.extraFees,
      paymentFees: data.setMpgsInstallmentPlanOnCart?.cart.payment_fee ?? cartState.paymentFees,
      total: data.setMpgsInstallmentPlanOnCart?.cart.prices?.grand_total?.value ?? cartState.total,
    });
  }

  return {
    setMpgsInstallmentPlanOnCart,
  };
}

export function useSetFlipAndWinChoiceOnCart() {
  const { execute, isFetching } = useMutation(SetFlipAndWinChoiceOnCartDocument);

  async function setFlipAndWinChoiceOnCart(choiceId: string) {
    const { data, error } = await execute({
      cartId: cartState.cartId,
      choiceId,
    });

    if (error) {
      throw new Error(error.message);
    }

    if (data.setFlipAndWinChoiceOnCart?.cart) {
      patchCartState(mapCartState(data.setFlipAndWinChoiceOnCart.cart));
    }
  }

  return {
    setFlipAndWinChoiceOnCart,
    isFetching,
  };
}
