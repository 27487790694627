var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.as,_vm._g(_vm._b({tag:"component",staticClass:"AppButton flex",class:{
    inverted: _vm.variant === 'inverted',
    danger: _vm.variant === 'danger',
    'opacity-50 pointer-events-none': _vm.disabled,
  },attrs:{"aria-label":_vm.ariaLabel}},'component',_vm.$attrs,false),_vm.$listeners),[_c('span',{staticClass:"AppButton__text"},[_vm._t("default")],2),_vm._v(" "),_c('span',{staticClass:"AppButton__arrow"},[_c('svg-icon',{staticClass:"w-3 h-3 z-1 transform",class:{
        'scale-x-reverse rotate-45': _vm.$i18n.locale === 'ar' && _vm.arrowDirection === 'up-right',
        '-rotate-45': _vm.$i18n.locale === 'en' && _vm.arrowDirection === 'up-right',
        'rotate-90': _vm.arrowDirection === 'down',
      },attrs:{"name":"right-arrow"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }