import { computed, ref, useAsync } from '@nuxtjs/composition-api';
import { useMutation, useQuery } from 'villus';
import {
  CustomerLimitInfoDocument,
  CustomerLoyaltyTransactionsDocument,
  CustomerPromoCodesDocument,
  DeleteCustomerDocument,
  GetCustomerCreditDocument,
  GetCustomerInfoDocument,
  GetInstallmentDistrictsDocument,
  SubmitCustomerDataDocument,
  VerifyCustomerInformationDocument,
} from '../graphql/Customer';
import { CustomerLoyaltyPointsDocument, CustomerCreditLimitDocument } from '~/graphql/Customer';

export function useCustomerLoyaltyPoints() {
  const { data, error, isFetching } = useQuery({ query: CustomerLoyaltyPointsDocument, cachePolicy: 'network-only' });
  const loyaltyPoints = computed(() => data.value?.customer?.total_active_points || 0);

  const loyaltyPointsError = computed(() => error.value);
  const customerPoints = computed(() => data.value?.customer?.customer_points.current_points || 0);
  const customerPointsAboutToExpire = computed(() => data.value?.customer?.customer_points.points_to_expire || 0);
  const customerPointsExpirationDate = computed(() => data.value?.customer?.customer_points.expire_date || 0);

  return {
    isFetching,
    loyaltyPoints,
    loyaltyPointsError,
    customerPoints,
    customerPointsAboutToExpire,
    customerPointsExpirationDate,
  };
}

export function useCustomerLoyaltyTransactions() {
  const { data, error, isFetching } = useQuery({
    query: CustomerLoyaltyTransactionsDocument,
    cachePolicy: 'network-only',
  });
  const loyaltyTransactions = computed(() => data.value?.customer?.customer_points?.transactions || []);
  const loyaltyTransactionsError = computed(() => error.value);

  return {
    isFetching,
    loyaltyTransactions,
    loyaltyTransactionsError,
  };
}

export function useCustomerCreditLimit() {
  const { data, error, isFetching } = useQuery({ query: CustomerCreditLimitDocument });
  const creditLimit = computed(() => data.value?.customer?.credit_limit_data?.credit_limit || 0);
  const limitMessage = computed(() => data.value?.customer?.credit_limit_data?.message || '');
  const creditLimitError = computed(() => error.value);

  return {
    isFetching,
    creditLimitError,
    creditLimit,
    limitMessage,
  };
}

export function useCustomerLimitInfo() {
  const { execute, isFetching } = useQuery({
    query: CustomerLimitInfoDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });
  return { getLimitInfo: execute, isFetching };
}

export const maritalStatusMap = {
  1: 'single',
  2: 'married',
  3: 'divorced',
  4: 'widow',
};

export function useGetCustomerInfo() {
  const { data, error, isFetching } = useQuery({
    query: GetCustomerInfoDocument,
    cachePolicy: 'network-only',
  });

  const customerRawData = computed(() => data.value?.getCustomerData);
  const customerInfo = computed(() => {
    const customerData = data.value?.getCustomerData;
    return {
      name: `${customerData?.first_name || ''} ${customerData?.middle_name || ''} ${customerData?.last_name || ''} ${
        customerData?.family_name || ''
      }`,
      nationalId: customerData?.national_id,
      issueDate: customerData?.national_id_date,
      phone: customerData?.phone_number,
      personalAddress: customerData?.national_id_address,
      currentAddress: customerData?.current_address,
      maritalStatus: customerData?.martial_status,
      governorate: customerData?.national_id_governorate,
      area: customerData?.district_name,
      street: customerData?.street_name,
      building: customerData?.building_number,
      floor: '1',
      apartment: '3',
      addressName: 'name',
      jobTitle: customerData?.job,
      company: customerData?.company,
      companyAddress: customerData?.job_address,
      jobDate: customerData?.job_date?.split(' ')[0],
      salary: Number(customerData?.total_salary),
    };
  });
  const customerInfoError = computed(() => error.value);

  return {
    customerInfo,
    customerInfoError,
    isFetchingCustomerInfo: isFetching,
    customerData: customerRawData,
  };
}

export function useSubmitCustomerData() {
  const { execute: submitCustomerData } = useMutation(SubmitCustomerDataDocument);
  return { submitCustomerData };
}

export function useGetInstallmentDistricts() {
  const { data, error, isFetching: isFetchingDistricts } = useQuery({ query: GetInstallmentDistrictsDocument });
  const districts = computed(() => data.value?.getInstallmentsDistricts);
  return { districts, error, isFetchingDistricts };
}

export function useVerifyCustomerInfo() {
  const { execute: verifyCustomer, isFetching: isVerifying } = useMutation(VerifyCustomerInformationDocument);
  return { verifyCustomer, isVerifying };
}

export function useGetCustomerCreditInfo() {
  const { data, error, isFetching } = useQuery({ query: GetCustomerCreditDocument, cachePolicy: 'network-only' });
  const creditInfo = computed(() => data.value?.getCustomerCredit);
  const creditInfoError = computed(() => error.value);
  const creditLimit = computed(() => data.value?.getCustomerCredit?.credit_limits?.[0]?.amount || 0);
  const nationalId = computed(() => data.value?.customer?.nationalId || '');
  return { creditInfo, creditInfoError, isFetching, creditLimit, nationalId };
}

export function useDeleteCustomer() {
  const { execute: deleteCustomer } = useMutation(DeleteCustomerDocument);
  return { deleteCustomer };
}

export function useCustomerPromoCodes() {
  const promoCodes = ref<string[]>([]);
  const totalCount = ref(0);

  const { execute, isFetching } = useQuery({
    query: CustomerPromoCodesDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  useAsync(async () => {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    promoCodes.value = data?.getCustomerCoupons?.map(code => code?.coupon_code as string) || [];
    totalCount.value = data?.getCustomerCoupons?.length || 0;
  });

  return {
    promoCodes,
    totalCount,
    isFetching,
  };
}
