import { reactive, computed, inject, ref, watch } from '@nuxtjs/composition-api';
import { useQuery, useMutation, CombinedError } from 'villus';
import { flatten, isEqual } from 'lodash-es';
import {
  GuestInstallmentsDocument,
  MinimalGuestInstallmentsDocument,
  GetInstallmentsMpgsDataDocument,
  GetInstallmentsAcceptUrlDocument,
  GetMultipleInstallmentsAcceptUrlDocument,
  LegacyGetInstallmentsMpgsDataDocument,
  GetInstallmentsMpgsDataQuery,
} from '../graphql/Installments';
import {
  SetNationalIdOnAccountDocument,
  VerifyNationalIdDocument,
  RequestToVerifyIdDocument,
  CheckCustomerCreditDocument,
} from '../graphql/Customer';
import { useI18n } from '~/features/i18n';
import { CustomerInput, RequestedInstallments } from '~/graphql-types.gen';
import {
  CustomerInstallmentsDocument,
  CustomerInstallmentsQueryVariables,
  CustomerApplicationDataDocument,
  CustomerLimitInfoDocument,
} from '~/graphql/Customer';
import { MaybeReactive } from '~/types/utils';
import { toNonNullable } from '~/utils/collections';
import { AUTH_USER } from '~/utils/provides';
import {
  SetCustomerDataOnCartDocument,
  SetInstallmentsPaymentOptionsOnCartDocument,
  SetInstallmentsSignatureOptionsOnCartDocument,
} from '~/graphql/Cart';
import {
  GetCustomerActiveInstallmentDocument,
  GetCustomerActiveInstallmentQueryVariables,
  GetCustomerInstallmentsByMonthDocument,
  GetCustomerInstallmentsByMonthQueryVariables,
} from '~/graphql/Elite';

/**
 * Installment integrations from the back-end
 *
 * Hint: lowest installment data is only for product card display option
 *
 *
 * installment_data:
 *  @param method - installment method id
 *  @param method_name - installment display name
 *  @param method_logo - installment display logo
 *
 *  @plans ${plan[]}
 *     @param months - installment # of months
 *     @param interest - installment interest
 *     @param down_payment - installment lowest down payment
 *
 * @description to calculate the amount paid for everymonth use the formula:
 *    amount = (product_price * (1 + interest / 100) - down_payment) / months
 */
export function useInstallmentApplicationForm() {
  const user = inject(AUTH_USER);
  const input = reactive({
    fullName: user?.value?.firstname && user.value?.lastname ? user.value?.firstname + ' ' + user.value?.lastname : '',
    nationalId: '',
    jobTitle: '',
    companyName: '',
    salary: '',
    nationalIdImage: '',
    supportingDocuments: '',
  });

  function toCustomerDataInput(): CustomerInput {
    return {
      firstname: input.fullName.split(' ')[0] || user?.value?.firstname || '',
      lastname: input.fullName.split(' ').slice(1).join(' ') || user?.value?.lastname || '',
      company_name: input.companyName,
      job_title: input.jobTitle,
      salary: input.salary,
      ...(input.nationalIdImage && !input.nationalIdImage.startsWith('https://')
        ? {
            national_photo: {
              base64_encoded_file: input.nationalIdImage || '',
              name: 'nationalId',
            },
          }
        : {}),
      ...(input.supportingDocuments && !input.supportingDocuments.startsWith('https://')
        ? {
            custom_docs: {
              base64_encoded_file: input.supportingDocuments,
              name: 'customDocs',
            },
          }
        : {}),
    };
  }

  const salaryRanges = [
    {
      label: '0 - 10,000',
      value: '0 - 10,000',
    },
    {
      label: '10,000 - 20,000',
      value: '10,000 - 20,000',
    },
    {
      label: '20,000 - 30,000',
      value: '20,000 - 30,000',
    },
    {
      label: '30,000 - 40,000',
      value: '30,000 - 40,000',
    },
    {
      label: '40,000 - 50,000',
      value: '40,000 - 50,000',
    },
    {
      label: '50,000 - 60,000',
      value: '50,000 - 60,000',
    },
    {
      label: '60,000 - 70,000',
      value: '60,000 - 70,000',
    },
    {
      label: '70,000 - 80,000',
      value: '70,000 - 80,000',
    },
    {
      label: '80,000 - 90,000',
      value: '80,000 - 90,000',
    },
    {
      label: '90,000 - 100,000',
      value: '90,000 - 100,000',
    },
    {
      label: '100,000 - 110,000',
      value: '100,000 - 110,000',
    },
    {
      label: '110,000 - 120,000',
      value: '110,000 - 120,000',
    },
    {
      label: '120,000 - 130,000',
      value: '120,000 - 130,000',
    },
  ];
  return {
    input,
    salaryRanges,
    toCustomerDataInput,
  };
}

export function useFetchCustomerApplicationInfo() {
  const { data, error, isFetching } = useQuery({ query: CustomerApplicationDataDocument, cachePolicy: 'network-only' });
  const customerData = computed(() => {
    return data.value?.customer;
  });
  const customerError = error.value;
  return { customerData, customerError, isFetching };
}

export function installmentAmountPerMonth(price: number, interest: number, downPayment: number, months: number) {
  // apply interest to price after subtracting down payment
  return ((price - downPayment) * (1 + interest / 100)) / months;
}

export function calculateDownpayment(price: number, insurance: number, downPaymentPercentage: number) {
  return Math.round(Number(price * (downPaymentPercentage / 100)));
}

export function useGuestInstallments(nationalId: string) {
  const { data, isFetching, error, execute } = useQuery({
    query: GuestInstallmentsDocument,
    variables: { nationalId },
    cachePolicy: 'network-only',
  });

  const installmentsData = computed(() => data.value?.guest_installments?.items || []);
  const installmentsError = computed(() => error.value);
  return {
    isFetching,
    installmentsData,
    installmentsError,
    fetchInstallments: execute,
  };
}

export function useMinimalGuestInstallments(nationalId: string) {
  const { execute, isFetching } = useQuery({
    query: MinimalGuestInstallmentsDocument,
    variables: { nationalId },
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  const fetchGuestInstallmentsData = async (nationalId: string) => {
    const { data, error } = await execute({
      variables: { nationalId },
    });
    return { data, error };
  };
  return {
    isFetching,
    fetchGuestInstallmentsData,
  };
}

export function useCustomerInstallments(variables?: MaybeReactive<CustomerInstallmentsQueryVariables>) {
  const { data, error, isFetching, execute } = useQuery({
    query: CustomerInstallmentsDocument,
    variables,
    cachePolicy: 'network-only',
  });

  const installments = computed(() => {
    return toNonNullable(data.value?.customer?.installments?.items) || [];
  });

  const installmentsCount = computed(() => {
    return data.value?.customer?.installments?.total_count || 0;
  });

  return {
    data,
    installments,
    installmentsCount,
    isFetchingInstallments: isFetching,
    error,
    refetchInstallments: execute,
  };
}

/**
 * fetch the data for the pay installments page
 * @param socialNumber
 * @param applicationId
 * @returns
 */
export function useGetMPGSInstallmentsData(socialNumber: string) {
  const { execute, isFetching: isFetchingInstallments } = useQuery({
    query: GetInstallmentsMpgsDataDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });
  const { execute: executeLegacy, isFetching: isFetchingLegacy } = useQuery({
    query: LegacyGetInstallmentsMpgsDataDocument,
    fetchOnMount: false,
  });

  const getMPGSInstallmentsData = async (installments: RequestedInstallments[]) => {
    const { data, error } = await execute({ variables: { socialNumber, installments } });
    return { data, error };
  };

  const legacyGetMPGSInstallmentsData = async (
    installmentIds: string[],
    applicationId: string
  ): Promise<{ data: GetInstallmentsMpgsDataQuery; error: CombinedError | null }> => {
    const { data, error } = await executeLegacy({ variables: { socialNumber, applicationId, installmentIds } });
    return {
      data: {
        getMultipleInstallmentsMPGSData: {
          amount: data?.getInstallmentsMPGSData?.amount,
          auth_header: data?.getInstallmentsMPGSData?.auth_header,
          installment_id: data?.getInstallmentsMPGSData?.installment_id,
        },
      },
      error,
    };
  };

  const isFetching = computed(() => isFetchingInstallments.value || isFetchingLegacy.value);

  return { isFetching, getMPGSInstallmentsData, legacyGetMPGSInstallmentsData };
}

export function useGetInstallmentsAcceptUrl() {
  const { execute, isFetching } = useQuery({ query: GetInstallmentsAcceptUrlDocument, fetchOnMount: false });
  const getWalletsUrl = async ({
    socialNumber,
    applicationId,
    installmentIds,
    phoneNumber,
  }: {
    socialNumber: string;
    applicationId: string;
    installmentIds: string[];
    phoneNumber: string;
  }) => {
    const { data, error } = await execute({ variables: { socialNumber, applicationId, installmentIds, phoneNumber } });
    return { data, error };
  };

  return { isFetching, getWalletsUrl };
}

export function useGetMultipleInstallmentsAcceptUrl() {
  const { execute, isFetching } = useQuery({ query: GetMultipleInstallmentsAcceptUrlDocument, fetchOnMount: false });
  const getWalletsUrl = async ({
    socialNumber,
    installments,
    phoneNumber,
  }: {
    socialNumber: string;
    installments: RequestedInstallments[];
    phoneNumber: string;
  }) => {
    const { data, error } = await execute({
      variables: { socialNumber, requestedInstallments: installments, phoneNumber },
    });
    return { data, error };
  };

  return { isFetching, getWalletsUrl };
}

/**
 *
 * @param installmentStr  - installment string such as '3 month - 10 interest
 * @param price
 * @param downPayment
 * @returns
 */
export function extractInstallmentPlan(installmentStr: string, price: number, downPayment: number) {
  const month = /^([0-9]*) month - ([0-9]*) interest$/gm.exec(installmentStr)?.[1] || 0;

  const interest = /^([0-9]*) month - ([0-9]*) interest$/gm.exec(installmentStr)?.[2] || 0;

  return {
    month,
    pricePerMonth: installmentAmountPerMonth(price, Number(interest) || 0, downPayment, Number(month) || 0),
  };
}

export function useSetNationalId() {
  const { execute, isFetching } = useMutation(SetNationalIdOnAccountDocument);
  return { saveNationalId: execute, isFetching };
}

export function useRequestToVerifyNationalId() {
  const { execute, isFetching } = useQuery({
    query: RequestToVerifyIdDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });
  return { requestToVerifyID: execute, isFetching };
}

export function useVerifyNationalId() {
  const { execute, isFetching } = useMutation(VerifyNationalIdDocument);
  return { verifyNationalId: execute, isFetching };
}

export function useCustomerLimitInfo() {
  const { execute, isFetching } = useQuery({
    query: CustomerLimitInfoDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });
  return { getLimitInfo: execute, isFetching };
}

export function userCheckCustomerLimit() {
  const { execute: checkCustomerLimit, isFetching: isCheckingLimit } = useQuery({
    query: CheckCustomerCreditDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });
  return {
    checkCustomerLimit,
    isCheckingLimit,
  };
}

export function useSetInstallmentsSignatureOptionsOnCart() {
  const { execute: setOptionsOnCart, isFetching: isSettingOptions } = useMutation(
    SetInstallmentsSignatureOptionsOnCartDocument
  );
  return {
    setOptionsOnCart,
    isSettingOptions,
  };
}

export function useSetInstallmentsPaymentOptionsOnCart() {
  const { execute: setPaymentOptionsOnCart, isFetching: isSettingOptions } = useMutation(
    SetInstallmentsPaymentOptionsOnCartDocument
  );
  return {
    setPaymentOptionsOnCart,
    isSettingOptions,
  };
}

export function useSetCustomerDataOnCart() {
  const { execute: setCustomerDataOnCart, isFetching: isSettingData } = useMutation(SetCustomerDataOnCartDocument);
  return {
    setCustomerDataOnCart,
    isSettingData,
  };
}

export function useCustomerActiveInstallment(variables?: MaybeReactive<GetCustomerActiveInstallmentQueryVariables>) {
  const { data, error, isFetching, execute } = useQuery({
    query: GetCustomerActiveInstallmentDocument,
    variables,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });
  const { d } = useI18n();

  const activeMonth = computed(() => {
    return data.value?.customer?.upcoming_installments?.upcoming?.month;
  });

  const dueDate = computed(() => {
    if (!data.value?.customer?.upcoming_installments?.upcoming?.due_date) {
      return 'N/A';
    }

    const date = new Date(data.value?.customer?.upcoming_installments?.upcoming?.due_date * 1000);

    if (!date) {
      return 'N/A';
    }

    return d(date, 'short');
  });

  const totalAmount = computed(() => {
    return data.value?.customer?.upcoming_installments?.upcoming?.total_count;
  });

  const amountDue = computed(() => {
    return data.value?.customer?.upcoming_installments?.upcoming?.installments?.reduce(
      (acc, curr) =>
        acc + (curr?.bills?.reduce<number>((accu, bill) => accu + (bill?.amount ?? 0) + (bill?.penalty ?? 0), 0) ?? 0),
      0
    );
  });

  const isDue = computed(() => {
    return data.value?.customer?.upcoming_installments?.upcoming?.is_due;
  });

  const instalmentIds = computed(() => {
    return (
      flatten(
        data.value?.customer?.upcoming_installments?.upcoming?.installments?.map(inst => {
          return inst?.bills?.map(bill => bill?.id) ?? [];
        })
      ) ?? []
    );
  });

  const applicationIds = computed(() => {
    return (
      data.value?.customer?.upcoming_installments?.upcoming?.installments?.map(inst => {
        return inst?.application_id;
      }) ?? []
    );
  });

  return {
    data,
    activeMonth,
    dueDate,
    amountDue,
    isFetching,
    error,
    isDue,
    refetch: execute,
    totalAmount,
    instalmentIds,
    applicationIds,
  };
}

export function useCustomerInstallmentsByMonth(
  variables?: MaybeReactive<GetCustomerInstallmentsByMonthQueryVariables>
) {
  const { d } = useI18n();

  const { data, error, isFetching, execute } = useQuery({
    query: GetCustomerInstallmentsByMonthDocument,
    variables,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  const aggregations = ref<
    {
      month: number;
      dueDate: string;
      totalAmount: number;
      amountDue: number;
      isDue: boolean;
      applications: {
        [key: string]: { installmentIds: string[] };
      };
    }[]
  >([]);

  watch(data, newData => {
    if (!newData) return;

    aggregations.value =
      newData.customer?.all_installments_by_month?.map(inst => ({
        month: inst?.upcoming?.month ?? 0,
        dueDate: d(new Date((inst?.upcoming?.due_date ?? 0) * 1000), 'short') ?? '',
        totalAmount: inst?.upcoming?.total_count ?? 0,
        amountDue:
          inst?.upcoming?.installments?.reduce<number>(
            (acc, curr) =>
              acc +
              (curr?.bills?.reduce<number>((accu, bill) => accu + (bill?.amount ?? 0) + (bill?.penalty ?? 0), 0) ?? 0),
            0
          ) ?? 0,
        isDue: inst?.upcoming?.is_due ?? false,
        applications:
          inst?.upcoming?.installments?.reduce<{
            [key: string]: { installmentIds: string[] };
          }>((accu, current) => {
            if (accu?.[current?.application_id ?? '']) {
              return {
                ...accu,
                [current?.application_id ?? '']: {
                  installmentIds: [
                    ...accu[current?.application_id ?? ''].installmentIds,
                    ...(current?.bills?.map(bill => bill?.id ?? '') ?? []),
                  ],
                },
              };
            }

            return {
              ...accu,
              [current?.application_id ?? '']: {
                installmentIds: current?.bills?.map(bill => bill?.id ?? '') ?? [],
              },
            };
          }, {}) ?? {},
      })) ?? [];
  });

  const aggregatedByMonthBreakdown = (monthAttributes: {
    month: number;
    dueDate: string;
    totalCount: number;
  }): {
    installmentIndex: string;
    totalAmountDue: number;
    remainingInstallments: number;
    totalMonths: number;
  }[] => {
    const monthInstallments = data?.value?.customer?.all_installments_by_month
      ?.map(inst => inst?.upcoming)
      ?.find(inst => {
        return isEqual(
          {
            month: inst?.month,
            dueDate: d(new Date((inst?.due_date ?? 0) * 1000), 'short') ?? '',
            totalCount: inst?.total_count,
          },
          monthAttributes
        );
      });

    if (!monthInstallments) return [];

    return (
      monthInstallments?.installments?.map(installment => ({
        installmentIndex: installment?.application_id ?? '',
        totalAmountDue:
          installment?.bills?.reduce<number>((accu, bill) => accu + (bill?.amount ?? 0) + (bill?.penalty ?? 0), 0) ?? 0,
        remainingInstallments: (installment?.total_months ?? 0) - (installment?.paid_amount ?? 0),
        totalMonths: installment?.total_months ?? 0,
      })) ?? []
    );
  };

  return {
    data,
    isFetching,
    error,
    refetch: execute,
    aggregations,
    aggregatedByMonthBreakdown,
  };
}

export function useCustomerInstallmentsByMonthBreakdown(
  variables?: MaybeReactive<GetCustomerInstallmentsByMonthQueryVariables>
) {
  const { d } = useI18n();
  const { data, error, isFetching, execute } = useQuery({
    query: GetCustomerInstallmentsByMonthDocument,
    variables,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  const aggregations = ref<
    {
      month: number;
      dueDate: string;
      totalAmount: number;
      isDue: boolean;

      installments: {
        id: string;
        dueDate: string;
        displayId: string;
        amountDue: number;
        bills: string[];
      }[];
    }[]
  >([]);

  watch(data, newData => {
    if (!newData) return;

    aggregations.value =
      newData.customer?.all_installments_by_month?.reduce<typeof aggregations.value>((acc, inst) => {
        const month = inst?.upcoming?.month ?? 0;
        const dueDate = d(new Date((inst?.upcoming?.due_date ?? 0) * 1000), 'short') ?? '';
        const isDue = inst?.upcoming?.is_due ?? false;
        const totalAmount =
          inst?.upcoming?.installments
            ?.map(
              ins => ins?.bills?.reduce<number>((acc, bill) => acc + (bill?.amount ?? 0) + (bill?.penalty ?? 0), 0) ?? 0
            )
            .reduce((acc, curr) => acc + curr, 0) ?? 0;

        const installments =
          inst?.upcoming?.installments?.map(installmentItem => {
            // if the application if first time to appear , start the index from 0 else accumulate the index
            const filtersMonths = acc.filter(
              item => item.installments.find(inst => inst.displayId === installmentItem?.application_id)?.id
            );

            return {
              id: `${month} ${installmentItem?.application_id} ${filtersMonths.length ? filtersMonths.length : 0}`,
              displayId: installmentItem?.application_id?.toString() ?? '',
              dueDate: d(new Date(Number(installmentItem?.bills?.at(-1)?.due_date) * 1000), 'short') ?? '',
              amountDue:
                installmentItem?.bills?.reduce((acc, curr) => acc + (curr?.amount ?? 0) + (curr?.penalty ?? 0), 0) ?? 0,
              bills: installmentItem?.bills?.map(bill => bill?.id?.toString() ?? '') ?? [],
            };
          }) ?? [];

        return [
          ...acc,
          {
            month,
            dueDate,
            isDue,
            totalAmount,
            installments,
          },
        ];
      }, []) ?? [];
  });

  return {
    data,
    isFetching,
    error,
    refetch: execute,
    aggregations,
  };
}
