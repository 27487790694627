// https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
// https://developers.facebook.com/docs/facebook-pixel/reference
// this also will be supporting google analytics4
// !https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase

import { useContext } from '@nuxtjs/composition-api';

import { useEvent } from './events';
import { ProductData } from './product';
import { mapOrderDetails, mapOrderProduct } from './orders';

import { useAuth } from './auth';
import { getCartItem, mapCartItem } from './cart';

/**
 * list of event triggers
 * when triggered -> send corresponding gtm event
 */
export enum TRACKING_EVENTS {
  ADD_TO_CART = 'ADD_TO_CART', // already implemented
  ADD_TO_WISH_LIST = 'ADD_TO_WISH_LIST',
  COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION',
  SEARCH = 'Search',
  PURCHASE = 'Purchase',
  ON_PRODUCT = 'ON_PROUDCT', // product impresession
  PRODUCT_CLICK = 'PRODUCT_CLICK', // product click
  PRODUCT_VIEW = 'PRODUCT_VIEW', // product view
  PRODUCT_REMOVE_FROM_CART = 'PRODUCT_REMOVE_FROM_CART', // product remove from cart
  CHECKOUT_STEP = 'CHECKOUT_STEP', // checkout step
  CHECKOUT_STEP_OPTION = 'CHECKOUT_STEP_OPTION', // checkout step option
  LOGIN = 'WE_LOGIN',
  LOGOUT = 'WE_LOGOUT',
  SIGNUP = 'WE_SIGNUP',
  LANGUAGE_SELECTED = 'WE_LANGUAGE_SELECTED',
  BANNER_CLICKED = 'WE_BANNER_CLICKED',
  CATEGORY_VIEWED = 'WE_CATEGORY_VIEWED',
  SUBCATEGORY_VIEWED = 'WE_SUBCATEGORY_VIEWED',
  MICROCATEGORY_VIEWED = 'WE_MICROCATEGORY_VIEWED',
  REMOVED_FROM_WISHLIST = 'WE_REMOVED_FROM_WISHLIST',
  ADDED_TO_COMPARE = 'WE_ADDED_TO_COMPARE',
  REMOVED_FROM_COMPARE = 'WE_RMOVED_FROM_COMPARE',
  CART_UPDATED = 'WE_CART_UPDATED',
  COUPON_CODE_APPLIED = 'WE_COUPON_CODE_APPLIED',
  COUPON_FAILED = 'WE_COUPON_FAILED',
  CART_VIEWED = 'WE_CART_VIEWED',
  CHECKOUT_STARTED = 'WE_CHECKOUT_STARTED',
  CHECKOUT_COMPLETED = 'WE_CHECKOUT_COMPLETED',
  SHIPPING_DETAILS_UPDATED = 'WE_SHIPPING_DETAILS_UPDATED',
  MAINTENANCE_REQUEST = 'WE_MAINTENANCE_REQUEST',
  FILTER_SELECTED = 'WE_FILTER_SELECTED',
  OTP_VERIFIED = 'WE_OTP_VERIFIED',
  USER_SIGNUP = 'WE_USER_SIGNUP',
  PROCEED_WITH_INSTALLMENTS = 'WE_PROCEED_WITH_INSTALLMENTS',
  TAKSETY_FORM_STARTED = 'TAKSETY_FORM_STARTED',
  TAKSETY_FORM_SUBMITTED = 'TAKSETY_FORM_SUBMITTED',
  TAKSETY_PERSONAL_DATA = 'TAKSETY_PERSONAL_DATA',
  TAKSETY_ADDRESS_DATA = 'TAKSETY_ADDRESS_DATA',
  TAKSETY_OCCUPATION_DATA = 'TAKSETY_OCCUPATION_DATA',
  TAKSETY_ADDITIONAL_INFO_DATA = 'TAKSETY_ADDITIONAL_INFO_DATA',
}

export function useTrackingHandlers() {
  const { app } = useContext();
  const { user } = useAuth();

  useEvent(TRACKING_EVENTS.PURCHASE, (orders: ReturnType<typeof mapOrderDetails>[]) => {
    orders.forEach(order => {
      app.$gtag('purchase', {
        order: {
          number: order?.id || '',
          total: order?.total,
          shippingFees: order?.shippingFees,
        },
        items: order?.products,
        transactionData: {
          transactionId: order?.products?.map(product => product?.sku),
          transactionName: 'order_success',
          transactionTotal: order?.total,
          transactionCurrency: 'EGP',
          transactionType: 'product_group',
          transactionNumItems: order?.products?.length,
          transactionContent: order?.products?.map(product => ({
            id: product?.sku,
            quantity: product?.quantity || 0,
          })),
          transactionAddress: {
            firstname: order?.shippingAddress?.firstname,
            lastname: order?.shippingAddress?.lastname,
            email: user?.value?.email,
            phone_number: `+20${order?.shippingAddress?.telephone}`,
            street: order?.shippingAddress?.street,
            city: order?.shippingAddress?.city,
            region: order?.shippingAddress?.region,
            country: 'Egypt',
            postalcode: '12345',
          },
        },
        ga4: {
          currency: 'EGP',
          transaction_id: order?.id,
          value: order?.total,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          shipping: order?.shippingFees,
          tax: 0, // TODO be added later
          items: order?.products?.map((product, idx) => ({
            item_id: product?.sku,
            item_name: product?.name,
            affiliation: '', // TODO be added later
            coupon: '', // TODO be added later
            currency: 'EGP',
            discount: '',
            index: idx,
            item_brand: product?.product_data?.brand?.name,
            item_category: product?.product_data?.categories?.[0]?.name,
            price: product?.price,
            quantity: product?.quantity,
          })),
        },
        weData: {
          order_id: order?.id,
          payment_mode: order?.paymentMethod,
          product_id: order?.products.map(product => product?.sku),
          product_name: order?.products.map(product => product?.name),
          // categoryId: '',
          category_name_array: order?.products.map(
            product => product?.product_data?.categories && product?.product_data?.categories[0]?.name
          ),
          no_of_products: order?.products.length,
          total_amount: order?.total,
          currency: 'EGP',
          quantity: order?.productsCount,
          product_details: order?.products.map((item: ReturnType<typeof mapOrderProduct>) => {
            return {
              product_id: item?.sku,
              product_name: item?.name,
              category_name: item?.product_data?.categories ? item?.product_data?.categories[0]?.name : '',
              price: item?.priceBefore,
              price_with_discount: item?.price,
              quantity: item?.quantity,
              discount: (item?.priceBefore || 0) - (item?.price || 0),
              currency: 'EGP',
              image: [{ 'image url': item?.image.src }],
            };
          }),
          // discountAmount: '',
          coupon_code: order?.coupon,
          sub_total: order?.subtotal,
          shipping_charges: order?.shippingFees,
          tax_charged: order?.tax,
        },
      });
    });
  });

  useEvent(TRACKING_EVENTS.SEARCH, (keyword: string) => {
    app.$gtag('search', {
      transactionData: {
        transactionContent: keyword,
        transactionCurrency: 'EGP',
        transactionValue: user.value ? user.value.id : null,
      },
      ga4: {
        search_term: keyword,
      },
      weData: {
        search_keyword: keyword, // WE Data
      },
    });
  });

  useEvent(
    TRACKING_EVENTS.COMPLETE_REGISTRATION,
    (customer: { firstname: string; lastname: string; email: string; phone: string }) => {
      app.$gtag('completing_registration', {
        transactionData: {
          transactionContent: customer.firstname + ' ' + customer.lastname,
          transactionCurrency: 'EGP',
          transactionValue: user.value ? user.value.id : null,
          transactionStatus: 'active',
        },
        event: 'userSignedUp',
        weData: {
          mode: 'Email',
          name: customer.firstname + ' ' + customer.lastname,
          email: customer.email,
          phoneNumber: customer.phone,
        },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.USER_SIGNUP,
    (customer: { firstname: string; lastname: string; email: string; phone: string }) => {
      app.$gtag('userSignedUp', {
        event: 'userSignedUp',
        weData: {
          mode: 'Email',
          name: customer.firstname + ' ' + customer.lastname,
          email: customer.email,
          phoneNumber: customer.phone,
        },
      });
    }
  );
  useEvent(TRACKING_EVENTS.ADD_TO_WISH_LIST, (product: ProductData) => {
    app.$gtag('add_to_wishlist', {
      transactionData: {
        transactionName: product.name,
        transactionId: [product.id],
        transactionContent: product.description,
        transactionValue: user.value ? user.value.id : null, // user id
        transactionCategory: product.categories ? String(product.categories[0]?.name) : product.mainCategory,
        currency: 'EGP',
      },
      ga4: {
        currency: 'EGP',
        value: product.price,
        items: [product].map((product, idx) => ({
          item_id: product?.sku,
          item_name: product?.name,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          currency: 'EGP',
          discount: '',
          index: idx,
          item_brand: product?.brand?.name,
          item_category: product?.categories?.[0]?.name,
          price: product?.price,
          quantity: 1,
        })),
      },
      weData: {
        product_id: String(product.sku),
        product_name: product.name,
        category_id: product.categories ? String(product.categories[0]?.uid) : '',
        category_name: product.categories ? String(product.categories[0]?.name) : '',
        sub_category_id: product.categories ? String(product.categories[1]?.uid) : '',
        sub_category_name: product.categories ? product.categories[1]?.name : '',
        brand: product.brand?.name,
        seller:
          product.seller?.firstname || product.seller?.lastname
            ? product.seller?.firstname + ' ' + product.seller?.lastname
            : '',
        price_without_discount: product.priceBefore,
        price_with_discount: product.price,
        discount: (product.priceBefore || 0) - product.price,
        currency: 'EGP',
        image: product.image?.url || (product as any).thumb.src,
      },
    });
  });

  useEvent(TRACKING_EVENTS.ON_PRODUCT, (product: ProductData) => {
    app.$gtag('product_impression', { ...product });
  });

  useEvent(TRACKING_EVENTS.TAKSETY_FORM_STARTED, () => {
    app.$gtag('taksety_form_started', {
      ga4: {},
    });
  });

  useEvent(TRACKING_EVENTS.TAKSETY_FORM_SUBMITTED, () => {
    app.$gtag('taksety_form_submitted', {
      ga4: {},
    });
  });

  useEvent(TRACKING_EVENTS.TAKSETY_PERSONAL_DATA, () => {
    app.$gtag('taksety_form_personal_data', {
      ga4: {},
    });
  });

  useEvent(TRACKING_EVENTS.TAKSETY_ADDRESS_DATA, () => {
    app.$gtag('taksety_form_address_data', {
      ga4: {},
    });
  });

  useEvent(TRACKING_EVENTS.TAKSETY_OCCUPATION_DATA, () => {
    app.$gtag('taksety_form_occupation_data', {
      ga4: {},
    });
  });

  useEvent(TRACKING_EVENTS.TAKSETY_ADDITIONAL_INFO_DATA, () => {
    app.$gtag('taksety_form_additional_info_data', {
      ga4: {},
    });
  });

  useEvent(TRACKING_EVENTS.PRODUCT_CLICK, (product: ProductData) => {
    app.$gtag('product_click', {
      ...product,
      ga4: {
        items: [product].map((product, idx) => ({
          item_id: product?.sku,
          item_name: product?.name,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          currency: 'EGP',
          discount: '',
          index: idx,
          item_brand: product?.brand?.name,
          item_category: product?.categories?.[0]?.name,
          price: product?.price,
          quantity: 1,
        })),
      },
    });
  });

  useEvent(TRACKING_EVENTS.ADD_TO_CART, (cartItem: ReturnType<typeof getCartItem>) => {
    // Fire GTM event for successful add to cart
    app.$gtag('product_added_to_cart', {
      product: cartItem,
      quantity: cartItem?.quantity,
      transactionData: {
        transactionName: cartItem?.name,
        transactionTotal: Number(cartItem?.unitPrice).toFixed(2),
        transactionCurrency: 'EGP',
        transactionCategory: cartItem?.mainCategory,
        transactionType: 'product',
        transactionContent: [cartItem?.id],
      },

      ga4: {
        currency: 'EGP',
        value: (cartItem?.quantity || 1) * (cartItem?.unitPrice || 0),
        items: [cartItem].map((product, idx) => ({
          item_id: product?.sku,
          item_name: product?.name,
          currency: 'EGP',
          index: idx,
          item_brand: product?.brand,
          item_category: product?.mainCategory,
          price: product?.unitPrice,
          quantity: product?.quantity,
        })),
      },
      weData: {
        product_id: cartItem?.sku,
        product_name: cartItem?.name,
        category_name: cartItem?.mainCategory,
        seller: cartItem?.seller,
        price: cartItem?.oldPrice,
        price_with_discount: cartItem?.unitPrice,
        discount: (cartItem?.oldPrice || 0) - (cartItem?.unitPrice || 0),
        currency: 'EGP',
        image: cartItem?.image.src,
        brand: cartItem?.brand,
        quantity: cartItem?.quantity,
      },
    });
  });

  useEvent(TRACKING_EVENTS.PRODUCT_REMOVE_FROM_CART, (cartItem: ReturnType<typeof getCartItem>) => {
    app.$gtag('product_removed_from_cart', {
      product: cartItem,
      quantity: cartItem?.quantity,
      ga4: {
        currency: 'EGP',
        value: (cartItem?.quantity || 1) * (cartItem?.unitPrice || 0),
        items: [cartItem].map((product, idx) => ({
          item_id: product?.sku ?? 'N/A',
          item_name: product?.name,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          currency: 'EGP',
          discount: '',
          index: idx,
          item_brand: product?.brand,
          item_category: product?.mainCategory,
          price: product?.unitPrice,
          quantity: product?.quantity,
        })),
      },
      weData: {
        product_id: cartItem?.sku,
        product_name: cartItem?.name,
        category_name: cartItem?.mainCategory ? cartItem?.mainCategory : '',
        price: cartItem?.oldPrice,
        price_with_discount: cartItem?.unitPrice,
        discount: (cartItem?.oldPrice || 0) - (cartItem?.unitPrice || 0),
        brand: cartItem?.brand,
        currency: 'EGP',
        image: cartItem?.image.src,
        quantity: cartItem?.quantity,
      },
    });
  });

  useEvent(TRACKING_EVENTS.PRODUCT_VIEW, (product: ProductData) => {
    // Fire GTM event for product details view
    app.$gtag('product_detail_views', {
      product,
      transactionData: {
        transactionName: product.name,
        transactionTotal: Number(product.price).toFixed(2),
        transactionCurrency: 'EGP',
        transactionCategory: product.mainCategory,
        transactionType: 'product',
        transactionContent: [product.id],
      },
      ga4: {
        currency: 'EGP',
        value: product.price,
        items: [product].map((product, idx) => ({
          item_id: product?.sku,
          item_name: product?.name,
          affiliation: '', // TODO be added later
          coupon: '', // TODO be added later
          currency: 'EGP',
          discount: '',
          index: idx,
          item_brand: product.brand?.name,
          item_category: product?.mainCategory,
          price: product?.price,
          quantity: 1,
        })),
      },
      weData: {
        product_id: String(product.sku),
        product_name: product.name,
        product_category_id: product.categories ? String(product.categories[0]?.uid) : '',
        category_name: product.mainCategory,
        product_sub_category_id: product.categories ? String(product.categories[1]?.uid) : '',
        sub_category_name: product.categories ? product.categories[1]?.name : '',
        brand: product.brand?.name,
        seller:
          product.seller?.firstname || product.seller?.lastname
            ? product.seller?.firstname + ' ' + product.seller?.lastname
            : '',
        price_without_discount: product.priceBefore,
        price_with_discount: product.price,
        discount: (product.priceBefore || 0) - product.price,
        currency: 'EGP',
        product_image: product.image?.url,
      },
    });
  });

  useEvent(TRACKING_EVENTS.CHECKOUT_STEP, ({ step, products }: { step: number; products: ProductData[] }) => {
    products && products.length && app.$gtag('checkout_step', { step, items: products });
  });

  useEvent(
    TRACKING_EVENTS.CHECKOUT_STEP_OPTION,
    ({ step, products, option }: { step: number; products: ProductData[]; option: string }) => {
      products && products.length && app.$gtag('checkout_step_option', { step, items: products, option });
    }
  );

  useEvent(
    TRACKING_EVENTS.LOGIN,
    ({
      first_name,
      last_name,
      email,
      phone,
      language,
    }: {
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
      language: string;
    }) => {
      app.$gtag('userLoggedIn', {
        event: 'userLoggedIn',
        weData: {
          first_name,
          last_name,
          email,
          phoneNumber: phone,
          language,
        },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.BANNER_CLICKED,
    ({ banner_name, banner_category }: { banner_name: string; banner_category: string }) => {
      app.$gtag('bannerClicked', {
        event: 'bannerClicked',
        weData: {
          banner_category,
          ...(banner_name && { banner_name }),
        },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.CATEGORY_VIEWED,
    ({ category_id, category_name }: { category_id: string; category_name: string }) => {
      app.$gtag('categoryViewed', {
        event: 'categoryViewed',
        weData: { category_id, category_name },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.SUBCATEGORY_VIEWED,
    ({ subcategory_id, subcategory_name }: { subcategory_id: number; subcategory_name: string }) => {
      app.$gtag('subCategoryViewed', {
        event: 'subCategoryViewed',
        weData: { subcategory_id: String(subcategory_id), subcategory_name },
      });
    }
  );

  useEvent(TRACKING_EVENTS.REMOVED_FROM_WISHLIST, (product: ProductData) => {
    app.$gtag('removedFromWishlist', {
      event: 'removedFromWishlist',
      weData: {
        product_id: String(product.sku),
        product_name: product.name,
        category_id: product.categories ? String(product.categories[0]?.uid) : '',
        category_name: product.mainCategory,
        sub_category_id: product.categories ? String(product.categories[1]?.uid) : '',
        sub_category_name: product.categories ? product.categories[1]?.name : '',
        brand: product.brand?.name,
        seller: product.seller?.firstname + ' ' + product.seller?.lastname,
        price_without_discount: String(product.priceBefore),
        price_with_discount: product.price,
        discount: (product.priceBefore || 0) - product.price,
        currency: 'EGP',
        image: product.image?.url,
      },
    });
  });

  useEvent(TRACKING_EVENTS.ADDED_TO_COMPARE, (product: ProductData) => {
    app.$gtag('addedToCompareProducts', {
      event: 'addedToCompareProducts',
      weData: {
        product_id: product.sku,
        product_name: product.name,
        category_id: product.categories ? String(product.categories[0]?.uid) : '',
        category_name: product.mainCategory,
        sub_category_id: product.categories ? String(product.categories[1]?.uid) : '',
        sub_category_name: product.categories ? String(product.categories[1]?.name) : '',
        brand: product.brand?.name,
        seller: product.seller?.firstname + ' ' + product.seller?.lastname,
        price_without_discount: product.priceBefore,
        price_with_discount: product.price,
        discount: (product.priceBefore || 0) - product.price,
        currency: 'EGP',
        image: product.image?.url,
      },
    });
  });

  useEvent(TRACKING_EVENTS.REMOVED_FROM_COMPARE, (product: ProductData) => {
    app.$gtag('removedFromCompareProducts', {
      event: 'removedFromCompareProducts',
      weData: {
        product_id: product.sku,
        product_name: product.name,
        category_id: product.categories ? String(product.categories[0]?.uid) : '',
        category_name: product.mainCategory,
        sub_category_id: product.categories ? String(product.categories[1]?.uid) : '',
        sub_category_name: product.categories ? product.categories[1]?.name : '',
        brand: product.brand?.name,
        seller: product.seller?.firstname + ' ' + product.seller?.lastname,
        price_without_discount: product.priceBefore,
        price_with_discount: product.price,
        currency: 'EGP',
        image: product.image?.url,
      },
    });
  });

  useEvent(TRACKING_EVENTS.LANGUAGE_SELECTED, ({ language }: { language: string }) => {
    app.$gtag('languageSelected', {
      event: 'languageSelected',
      weData: {
        language,
      },
    });
  });

  useEvent(TRACKING_EVENTS.LOGOUT, () => {
    app.$gtag('userLoggedOut', {
      event: 'userLoggedOut',
      weData: {},
    });
  });

  useEvent(
    TRACKING_EVENTS.CART_UPDATED,
    ({
      items,
      quantity,
      total_amount,
    }: {
      items: ReturnType<typeof mapCartItem>[];
      quantity: number;
      total_amount: number;
    }) => {
      app.$gtag('cartUpdated', {
        event: 'cartUpdated',
        weData: {
          no_of_products: items.length,
          total_amount,
          quantity,
          product_details: items.map((item: ReturnType<typeof mapCartItem>) => {
            return {
              product_id: item?.sku,
              product_name: item?.name,
              category_name: item?.mainCategory,
              seller: item?.seller,
              price: item?.oldPrice,
              price_with_discount: item?.unitPrice,
              currency: 'EGP',
              quantity: item?.quantity,
              discount: (item?.oldPrice || 0) - (item?.unitPrice || 0),
              image: [{ 'image url': item?.image.src }],
            };
          }),
        },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.CART_VIEWED,
    ({
      items,
      quantity,
      total_amount,
    }: {
      items: ReturnType<typeof mapCartItem>[];
      quantity: number;
      total_amount: number;
    }) => {
      app.$gtag('cartViewed', {
        event: 'cartViewed',
        weData: {
          no_of_products: items?.length,
          total_amount,
          quantity,
          product_details: items?.map((item: ReturnType<typeof mapCartItem>) => {
            return {
              product_id: item?.sku,
              product_name: item?.name,
              category_name: item?.mainCategory,
              seller: item?.seller,
              price: item?.oldPrice,
              price_with_discount: item?.unitPrice,
              quantity: item?.quantity,
              brand: item.brand,
              currency: 'EGP',
              discount: (item?.oldPrice || 0) - (item?.unitPrice || 0),
              image: [{ 'image url': item?.image.src }],
            };
          }),
        },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.CHECKOUT_STARTED,
    ({
      items,
      total,
      subtotal,
      count,
    }: {
      items: ReturnType<typeof mapCartItem>[];
      total: number;
      subtotal: number;
      count: number;
    }) => {
      app.$gtag('checkoutStarted', {
        event: 'checkoutStarted',
        weData: {
          product_ids: items.map(product => product?.sku).join(' , '),
          product_names: items.map(product => product?.name).join(' , '),
          // categoryId: '',
          category_names: items.map(product => product?.mainCategory).join(' , '),
          no_of_products: items.length,
          total_amount: total,
          currency: 'EGP',
          quantity: count,
          product_details: items.map((item: ReturnType<typeof mapCartItem>) => {
            return {
              product_id: item?.sku,
              product_name: item?.name,
              category_name: item?.mainCategory,
              price: item?.oldPrice,
              price_with_discount: item?.unitPrice,
              discount: (item?.oldPrice || 0) - (item?.unitPrice || 0),
              currency: 'EGP',
              image: [{ 'image url': item?.image.src }],
              quantity: item?.quantity,
            };
          }),
          // discountAmount: '',
          sub_total: subtotal,
        },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.PROCEED_WITH_INSTALLMENTS,
    ({
      items,
      total,
      subtotal,
      count,
    }: {
      items: ReturnType<typeof mapCartItem>[];
      total: number;
      subtotal: number;
      count: number;
    }) => {
      app.$gtag('proceedWithInstallments', {
        event: 'proceedWithInstallments',
        weData: {
          product_ids: items.map(product => product?.sku).join(' , '),
          product_names: items.map(product => product?.name).join(' , '),
          // categoryId: '',
          category_names: items.map(product => product?.mainCategory).join(' , '),
          no_of_products: items.length,
          total_amount: total,
          currency: 'EGP',
          quantity: count,
          product_details: items.map((item: ReturnType<typeof mapCartItem>) => {
            return {
              product_id: item?.sku,
              product_name: item?.name,
              category_name: item?.mainCategory,
              price: item?.oldPrice,
              price_with_discount: item?.unitPrice,
              discount: (item?.oldPrice || 0) - (item?.unitPrice || 0),
              currency: 'EGP',
              image: [{ 'image url': item?.image.src }],
              quantity: item?.quantity,
            };
          }),
          // discountAmount: '',
          sub_total: subtotal,
        },
      });
    }
  );
  useEvent(
    TRACKING_EVENTS.COUPON_CODE_APPLIED,
    ({
      cart_value_before_discount,
      cart_value_after_discount,
      coupon_code,
      discount_amount,
    }: {
      cart_value_before_discount: number;
      cart_value_after_discount: number;
      coupon_code: string;
      discount_amount: number;
    }) => {
      app.$gtag('couponCodeApplied', {
        event: 'couponCodeApplied',
        weData: {
          cart_value_before_discount,
          cart_value_after_discount,
          coupon_code,
          discount_amount,
        },
      });
    }
  );
  useEvent(TRACKING_EVENTS.COUPON_FAILED, ({ coupon_code }: { coupon_code: string }) => {
    app.$gtag('couponCodeFailed', {
      event: 'couponCodeFailed',
      weData: { coupon_code },
    });
  });

  useEvent(
    TRACKING_EVENTS.FILTER_SELECTED,
    ({ filter_name, filter_value }: { filter_name: string; filter_value: string }) => {
      app.$gtag('filterSelected', {
        event: 'filterSelected',
        weData: { filter_name, filter_value },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.MAINTENANCE_REQUEST,
    ({
      name,
      phone,
      brand,
      category,
      service_type,
      model,
      store_location,
    }: {
      name: string;
      phone: string;
      brand: string;
      category: string;
      service_type: string;
      model: string;
      serial_number: string;
      store_location: string;
    }) => {
      app.$gtag('maintenanceRequestFormSubmitted', {
        event: 'maintenanceRequestFormSubmitted',
        weData: { name, phone, brand, category, service_type, model, store_location },
      });
    }
  );

  useEvent(
    TRACKING_EVENTS.SHIPPING_DETAILS_UPDATED,
    ({
      shipping_address,
      first_name,
      last_name,
      phone_number,
      city,
      state,
      country,
    }: {
      shipping_address: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      city: string;
      state: string;
      country: string;
    }) => {
      app.$gtag('shippingDetailsUpdated', {
        event: 'shippingDetailsUpdated',
        weData: { shipping_address, first_name, last_name, phone_number, city, state, country },
      });
    }
  );

  useEvent(TRACKING_EVENTS.OTP_VERIFIED, ({ status }: { status: boolean }) => {
    app.$gtag('otpVerified', {
      event: 'otpVerified',
      weData: { status },
    });
  });
}
