import { useQuery } from 'villus';
import { SearchProductsDocument } from '../graphql/Product';
import { useGetEliteInfo } from './elite';

export function useSearchSuggestions() {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute } = useQuery({
    query: SearchProductsDocument,
    variables: { corporateId: attachedCorporate?.value?.id },
    fetchOnMount: false,
  });
  return { getSearchSuggestions: execute };
}
