export function lastItem<T>(arr: T[]) {
  return arr[arr.length - 1];
}

export function toNonNullable<T>(arr: Array<T | null | undefined> | null | undefined): T[] {
  if (!arr) {
    return [];
  }

  return arr.filter(x => !!x) as T[];
}

/**
 * A typed version of Object.keys
 */
export function keysOf<TRecord extends Record<string, any>>(record: TRecord | undefined | null): (keyof TRecord)[] {
  if (!record) {
    return [];
  }

  return Object.keys(record);
}

export function sortByPosition<T extends null | undefined | { position?: number | null }>(dir: 'ASC' | 'DESC') {
  if (dir === 'ASC') {
    return (a: T, b: T) => (a?.position || 0) - (b?.position || 0);
  }

  return (a: T, b: T) => (b?.position || 0) - (a?.position || 0);
}
export const sortByPositionAsc = sortByPosition('ASC');

export function toNonDuplicateArrayBy<T>(arr: Array<T | null | undefined> | null | undefined, key: keyof T): T[] {
  if (!arr) {
    return [];
  }
  return [...new Map(toNonNullable(arr).map(item => [item[key], item])).values()] as T[];
}

export const pipe = <T>(...fns: Array<(arg: T) => T>) => (value: T) => fns.reduce((acc, fn) => fn(acc), value);
