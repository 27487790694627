// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/sprite/svg/illustration.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/img/repeated-illustrations.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\nform[data-v-ca4f47d4]{\n  width:100%;\n  max-width:570px\n}\n@media (min-width: 1024px){\n.layout-page[data-v-ca4f47d4]{\n    background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n\n    background-repeat:no-repeat;\n\n    background-size:auto auto,auto 560px\n}\n[dir=\"ltr\"] .layout-page[data-v-ca4f47d4]{\n\n    background-position:-159px 130px,100% 0\n}\n[dir=\"rtl\"] .layout-page[data-v-ca4f47d4]{\n\n    background-position:-159px 130px,0% 0\n}\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
