import { render, staticRenderFns } from "./AsideInsuranceInfo.vue?vue&type=template&id=2fe6ce15&"
import script from "./AsideInsuranceInfo.vue?vue&type=script&lang=ts&"
export * from "./AsideInsuranceInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InsuranceInfoContent: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/InsuranceInfoContent.vue').default,SideOverlayView: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/SideOverlayView.vue').default})
