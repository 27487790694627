export function copyToClipboard(str: string) {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selection = document.getSelection() || { rangeCount: 0 };

  const selected =
    selection.rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection()?.getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection()?.removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection()?.addRange(selected); // Restore the original selection
  }
}

/**
 * Replaces placeholder values in a string with their actual values
 * @example interpolate('Hello, my name is {name}', { name: 'John Doe' });
 */
export function interpolate(templateStr: string, placeholders: Record<string, any>) {
  return templateStr.replace(/{([^}]+)}/g, function (_, p) {
    return p in placeholders ? placeholders[p] : `{${p}}`;
  });
}

/**
 * Generates a unique hash number based on string contents
 */
export function hash(x: string) {
  let h, i, l;
  for (h = 5381 | 0, i = 0, l = x.length | 0; i < l; i++) {
    h = (h << 5) + h + x.charCodeAt(i);
  }

  return h >>> 0;
}

function youtubeParser(url: string | null | undefined) {
  if (!url) return null;
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : null;
}

export function youtubeWrapper(url: string | null | undefined) {
  if (!url) return null;
  const id = youtubeParser(url);
  return id ? `https://www.youtube.com/embed/${id}?playlist=${id}&loop=1` : null;
}

export function kebabToHumanReadable(str: string) {
  return str
    .replace('-', ' ')
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
}

export function snakeCaseToHumanReadable(str: string) {
  return str
    .replace('_', ' ')
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
}

export function isEmail(str: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
}

/**
 * helper function
 */
export const fromArrayOfStringsToKeyMap = (keys: string[]) => {
  const transformedKeys = keys
    .map(key =>
      key
        .trim()
        .replace(/\s\s+/g, ' ')
        .split(' ')
        .map(i => i.charAt(0).toUpperCase() + i.slice(1))
        .join()
    )
    .map(f => f.charAt(0).toLowerCase() + f.slice(1));

  return <T>(data: string[]): T => {
    return data.reduce((accu, current, index) => ({ ...accu, [transformedKeys[index]]: current }), {}) as T;
  };
};
