const encodeStringToByteString = (str: string): string => {
  const utf8Bytes = new TextEncoder().encode(str);

  // Convert the byte array to a base64-encoded string
  let byteString = '';
  for (const byte of utf8Bytes) {
    byteString += String.fromCharCode(byte);
  }
  return byteString;
};

const decodeByteStringToString = (byteString: string): string => {
  const utf8Bytes = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    utf8Bytes[i] = byteString.charCodeAt(i);
  }
  return new TextDecoder().decode(utf8Bytes);
};

// handle base64 in client side and server side

// encode base64
export const encodeBase64 = <T>(obj: T): string => {
  if (typeof window !== 'undefined') {
    return window.btoa(encodeStringToByteString(JSON.stringify(obj)));
  }
  return Buffer.from(JSON.stringify(obj)).toString('base64');
};

// decode base64
export const decodeBase64 = <T>(str: string): T => {
  if (typeof window !== 'undefined') {
    return JSON.parse(decodeByteStringToString(window.atob(str))) as T;
  }
  return JSON.parse(Buffer.from(str, 'base64').toString('utf-8')) as T;
};
