/**
 *  this is only used for excluding components from showing in specific pages
 *  Ex: comparison page shouldn't contain comparison menu
 **/

import { useRouter } from './router';

const blackList: Record<string, { routes: Array<string> }> = {
  comparisonMenu: {
    routes: ['/en/compare', '/ar/compare'],
  },
};

export function useBlackList() {
  const { route } = useRouter();

  function isExcluded(key: string) {
    const currentRoute = route.value.path;
    return blackList[key].routes.includes(currentRoute);
  }

  return {
    isExcluded,
  };
}
