



















































































































import { computed, defineComponent, PropType, reactive, ref, watch } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { useI18n } from '~/features/i18n';
import { installmentAmountPerMonth } from '~/features/installments';

export default defineComponent({
  props: {
    totalPrice: {
      type: Number,
      default: 0,
    },
    paymentPlans: {
      type: Array as PropType<
        {
          months: number;
          interest: number;
          down_payment: number;
        }[]
      >,
      default: () => [],
    },
  },
  components: {
    ValidationObserver,
  },
  setup(props) {
    const form = ref<InstanceType<typeof ValidationObserver> | null>(null);
    const input = reactive<{
      downPayment: string | number;
      paymentPlan: {
        months: number;
        interest: number;
        down_payment: number;
      } | null;
    }>({
      downPayment: 0,
      paymentPlan: null,
    });
    const { t, tc } = useI18n();

    const paymentPlansOptions = computed(() => {
      return props.paymentPlans
        .map((plan, idx) => {
          const pricePerMonth = installmentAmountPerMonth(
            props.totalPrice,
            plan.interest,
            /**
             * if this is the currently selected payment use the changeable input down payment for the calculation
             * else use the intial down payment value
             */
            selectedPaymentPlan.value !== 0 && selectedPaymentPlan.value.months === plan.months
              ? Number(input.downPayment)
              : Number(plan.down_payment),
            plan.months
          );

          return pricePerMonth > 0
            ? {
                label: `${pricePerMonth.toFixed(2)} EGP /${plan.months} ${t('months')}`,
                value: plan,
                price: pricePerMonth.toFixed(2),
                months: `${tc('monthsCount', plan.months)}`,
                idx,
              }
            : null;
        })
        .filter(item => item);
    });

    watch(
      () => props.paymentPlans,
      newPlans => {
        if (newPlans.length) {
          input.paymentPlan = newPlans[0];
          input.downPayment = newPlans[0].down_payment;
        }
      },
      {
        immediate: true,
      }
    );

    const selectedPaymentPlan = computed(() => {
      return input.paymentPlan || 0;
    });

    /**
     *
     * @description is valid plan item if the calculated payment plan per month is greater than '1'
     */
    const isValidPlan = computed(() => {
      return props.paymentPlans.every(item => {
        return item.down_payment < Number(props.totalPrice);
      });
    });

    watch(selectedPaymentPlan, newSelectedPlan => {
      // whenever a new plan is selected update the prefilled  down payment value
      if (newSelectedPlan) {
        input.downPayment = newSelectedPlan.down_payment;
      }
    });

    return {
      input,
      paymentPlansOptions,
      form,
      selectedPaymentPlan,
      isValidPlan,
    };
  },
});
