// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/Rectangle.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/sprite/svg/letter-a.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n@media (min-width: 1024px){\n.FooterLinks[data-v-211a307e]{\n    display:grid;\n    grid-template-columns:1fr 1fr 1fr 300px\n}\n}\nfooter[data-v-211a307e]{\n  background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat:no-repeat;\n  background-size:465px 400px,285px 255px;\n  background-position:-180px 0,1066px 100%;\n  padding-top:35px;\n}\n[dir=\"ltr\"] footer[data-v-211a307e] input{\n    padding-right:2.25rem\n}\n[dir=\"rtl\"] footer[data-v-211a307e] input{\n    padding-left:2.25rem\n}\n@media (min-width: 1024px){\nfooter[data-v-211a307e]{\n    background-size:465px 400px,285px 255px;\n\n    background-position:-180px 0,1066px 100%;\n\n    padding-top:67px\n}\n}\nfooter .FooterLinks a[data-v-211a307e]{\n      position:relative\n}\nfooter .FooterLinks a[data-v-211a307e]:before{\n      content:\"\";\n      width:90%;\n      transform:scaleX(0);\n      position:absolute;\n      right:0px;\n      bottom:-0.25rem;\n      left:0px;\n      --tw-text-opacity:1;\n      color:rgba(253, 162, 56, var(--tw-text-opacity));\n      box-shadow:0 2px 0 1px currentColor;\n      transition:transform .3s ease-in-out\n}\n[dir=\"ltr\"] footer .FooterLinks a[data-v-211a307e]:before{\n      margin-left:-10px\n}\n[dir=\"rtl\"] footer .FooterLinks a[data-v-211a307e]:before{\n      margin-right:-10px\n}\nfooter .FooterLinks a[data-v-211a307e]:hover{\n      font-weight:700\n}\nfooter .FooterLinks a[data-v-211a307e]:hover:before{\n      transform:scaleX(1)\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
