












































import { Organization, SearchAction, WebSite } from 'schema-dts';

import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  watch,
  watchEffect,
} from '@nuxtjs/composition-api';
import { throttle } from 'throttle-debounce';
import { keyBy } from 'lodash-es';
import { useJsonLd, useSeoLinks } from '~/features/seo';
import { useI18n, useI18nCookie } from '~/features/i18n';
import { useBlackList } from '~/features/blackList';
import useCookies from '~/features/cookies';
import { useRouter } from '~/features/router';
import TheHeader from '~/components/TheHeader.vue';
import { useEvent } from '~/features/events';
import { useComparison } from '~/features/compare';
import { useFeatureFlags } from '~/features/storeConfig';
import { useActiveCampain } from '~/features/wheel';
import { isPendingAuth } from '~/features/auth';

export default defineComponent({
  setup() {
    useSeoLinks();
    useI18nCookie();
    const { $config } = useContext();
    const url = $config.appURL;
    const { isExcluded } = useBlackList();
    const { setCookie } = useCookies();
    const { route } = useRouter();
    const { fetchFlags } = useFeatureFlags();

    const isProductPage = ref(false);
    useEvent('IS_PRODUCT_PAGE', (value: boolean) => {
      isProductPage.value = value;
    });

    const showHeader = ref(true);
    const headerRef = ref<InstanceType<typeof TheHeader> | null>(null);
    const lastScrollPosition = ref(0);
    const scrollOffset = ref(100);
    const currentScrollPosition = ref(0);
    const featureFlags = ref<any>(undefined);
    const { products } = useComparison();
    const { getLocale } = useI18n();
    const { data: compain, isFetching: isCampainFetching } = useActiveCampain();

    const isHome = computed(() => {
      return route.value?.name === `index___${getLocale()}`;
    });

    /**
     * Control try your luck button visability
     * In home page with customer who never be won
     * In home page with guest
     */
    const isShowWheelModal = computed(() => {
      if (!featureFlags.value?.spinTheWheel?.enabled) return false;
      if (isPendingAuth.value || isCampainFetching.value) return false;
      return isHome?.value && !!compain?.value && !compain?.value?.response?.userWon;
    });

    const onScroll = throttle(300, () => {
      currentScrollPosition.value = window.pageYOffset;

      if (window.pageYOffset < 0) {
        return;
      }
      // if the header's distance covered still hasn't exceeded the scrolloffset -> return
      if (Math.abs(window.pageYOffset - lastScrollPosition.value) < scrollOffset.value) {
        return;
      }

      showHeader.value = window.pageYOffset < lastScrollPosition.value;
      lastScrollPosition.value = window.pageYOffset;
    });

    watchEffect(() => {
      if (process.server) {
        return;
      }
      // set current scroll position in case page is loaded with scroll
      currentScrollPosition.value = window.pageYOffset;
      window.addEventListener('scroll', onScroll);
    });

    onMounted(() => {
      // if we have a specific medium source -> save it
      if (route.value.query.source) {
        const queryParam = route.value.query.source as string;
        localStorage.setItem('ORDER_SOURCE', queryParam);
      }
      !process.server && setCookie('LS_ROUTE_KEY', route.value.path);

      fetchFlags().then(data => {
        featureFlags.value = keyBy(data.data?.features, 'feature_name');
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll);
    });

    const registerObserver = () => {
      if (!headerRef.value) return;

      const resizeObserver = new ResizeObserver(() => {
        if (!headerRef.value) return;
        // set header height to the new height
        const newHeight = headerRef.value.$el?.clientHeight;
        if (newHeight) {
          document.getElementById('app')?.style.setProperty('--header-height', `${newHeight}px`);
        }
      });
      resizeObserver.observe(headerRef.value.$el as Element);
    };

    // watch when header height changes
    watch(
      () => headerRef.value,
      () => {
        if (headerRef.value) registerObserver();
      }
    );

    // https://developers.google.com/search/docs/data-types/sitelinks-searchbox
    const websiteWithSearchAction: WebSite = {
      '@type': 'WebSite',
      url,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${url}/search?q={search_term_string}`,
        'query-input': 'required name=search_term_string',
      } as SearchAction,
    };

    const org: Organization = {
      '@id': url,
      '@type': 'Organization',
      // TODO: Organization name
      name: 'Unique',
      url,
      logo: `${url}/img/logo.png`,
      sameAs: [
        // TODO: Any social links to facebook/twitter/whatever
      ],
    };

    useJsonLd({
      '@context': {
        '@vocab': 'http://schema.org/',
      },
      '@graph': [websiteWithSearchAction, org],
    });

    return {
      isExcluded,
      showHeader,
      currentScrollPosition,
      headerRef,
      isProductPage,
      products,
      featureFlags,
      isShowWheelModal,
      compain,
    };
  },
  head: {},
});
