import { useQuery } from 'villus';
import { computed, ref, useAsync, useFetch } from '@nuxtjs/composition-api';
import { useCachedSsrRef } from './serverCache';
import { useSetLocaleToCacheParams } from './i18n';
import { useSetCorporateSourceToCacheParams, useGetEliteInfo } from './elite';
import { CategoriesByBrandDocument, CategoryListDocument, CategoryListQuery } from '@/graphql/Category';
import { Unpacked } from '~/types/utils';
import { pipe, toNonNullable } from '~/utils/collections';
import { OfferCardFragment } from '~/graphql/fragments';
import { CategoryTree } from '~/graphql-types.gen';

type CategoryApiItem = NonNullable<Unpacked<NonNullable<Unpacked<CategoryListQuery['categories']>>['items']>>;

export type Category = Omit<CategoryApiItem, 'children'> & {
  children?: Category[];
  siblings?: Category[];
  parent?: Partial<Category> | null;
  meta_title?: string;
  meta_description?: string;
  meta_keywords?: string;
  offer?: OfferCardFragment;
  level: number;
  featured_sort?: number;
};

/**
 * used for tracking the race condition
 * loading means to lock this statement until it get changed to its value
 */
const CATEGORY_QUERY_PENDING = new Map<string, Partial<Category>[] | 'loading' | undefined>();
const QUERY_THRESHOLD = 450;

export function useCategories() {
  const { cacheParam } = useSetLocaleToCacheParams();
  const { attachedCorporate } = useGetEliteInfo();
  const { corporateCacheParam } = useSetCorporateSourceToCacheParams();
  const cachingKey = pipe(cacheParam, corporateCacheParam)('categories');

  const categories = useCachedSsrRef<ReturnType<typeof mapCategoryTree>[]>(cachingKey, []);

  const { execute } = useQuery({
    query: CategoryListDocument,
    fetchOnMount: false,
    variables: {
      corporateId: attachedCorporate?.value?.id,
    },
  });

  useFetch(async () => {
    // Don't refetch categories unless cache expired
    if (categories.value.length) {
      return;
    }

    const { data, error } = await execute();

    if (data) {
      categories.value = sortByPosition(
        toNonNullable(data?.categories?.items?.[0]?.children).map(c =>
          mapCategoryTree(c as Category, data?.categories?.items?.[0]?.children as Category[])
        )
      );
    }
    if (error) {
      throw new Error(error.message);
    }
  });

  const flatCategories = computed(() => {
    return flatten(categories.value);
  });

  /*
   *
   * Mapping categories in order to fetch category directly by id in O(1)
   */
  const mappedCategories = computed<{ [key: string]: Category }>(() => {
    return flatCategories.value.reduce((accu, category) => {
      return { ...accu, [category.id || '-']: category };
    }, {});
  });

  // const featured = computed(() => {
  //   return flatCategories.value.filter(category => (category as any).is_homepage_featured);
  // });

  const findBySlug = (slug: string) => {
    return flatCategories.value.find((c: Category) => 'url_key' in c && c.url_key === slug);
  };

  const findById = (id: string) => {
    return flatCategories.value.find((c: Category) => 'id' in c && c.id === Number(id));
  };

  const searchByName = (name: string) => {
    return flatCategories.value.filter(category => category.name?.toLocaleLowerCase().includes(name));
  };

  return {
    categoryTree: categories,
    findBySlug,
    findById,
    searchByName,
    // featured,
    featuredCategories: computed(() =>
      flatCategories.value
        .filter(category => category && category.featured_sort)
        .sort((a, b) => (a.featured_sort || Number.MAX_VALUE) - (b.featured_sort || Number.MAX_VALUE))
    ),
    mappedCategories,
    featuredCategorySlider: computed(() =>
      flatCategories.value.filter(category => category && category.is_shown_in_homepage)
    ),
  };
}

/**
 * get featured categories to use for the category sliders
 * @returns
 */
export function useFeaturedCategorySlider() {
  const { cacheParam } = useSetLocaleToCacheParams();
  const { corporateCacheParam } = useSetCorporateSourceToCacheParams();
  const { attachedCorporate } = useGetEliteInfo();
  const cachingKey = pipe(cacheParam, corporateCacheParam)('categories');

  const cachedCategories = useCachedSsrRef<ReturnType<typeof mapCategoryTree>[]>(cachingKey, []);

  const { execute } = useQuery({
    query: CategoryListDocument,
    fetchOnMount: false,
    variables: {
      corporateId: attachedCorporate?.value?.id,
    },
  });

  async function categories() {
    if (cachedCategories.value && cachedCategories.value?.length) {
      cachedCategories.value = flatten(cachedCategories.value);
      return prepCategoriesForSliders(cachedCategories.value);
    }

    if (CATEGORY_QUERY_PENDING.get(cachingKey) === 'loading') {
      let count = 0;
      // Wait until PENDING is false
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (CATEGORY_QUERY_PENDING.get(cachingKey) !== 'loading') {
            // eslint-disable-next-line no-console
            console.log(
              '[Category Caching]: Result is fetched , Now Setting the category to the one got from another process , ..continue'
            );

            clearInterval(interval);
            resolve(true);
          }

          if (count > QUERY_THRESHOLD) {
            // eslint-disable-next-line no-console
            console.log(
              '[Category Caching]: Timeout : Unable to get category query from another process , resetting Category query and refetch again '
            );
            resolve(true);
            CATEGORY_QUERY_PENDING.set(cachingKey, undefined);
          } else {
            count++;
          }
        }, 100);
      });
    }

    if (
      CATEGORY_QUERY_PENDING.get(cachingKey) !== 'loading' &&
      CATEGORY_QUERY_PENDING.get(cachingKey)?.length &&
      CATEGORY_QUERY_PENDING.get(cachingKey) !== undefined
    ) {
      return prepCategoriesForSliders(CATEGORY_QUERY_PENDING.get(cachingKey) as Category[]);
    }

    if (cachedCategories.value && cachedCategories.value?.length) {
      cachedCategories.value = flatten(cachedCategories.value);
      return prepCategoriesForSliders(cachedCategories.value);
    }

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    return prepCategoriesForSliders(
      toNonNullable(flatten((data?.categories?.items?.[0]?.children || []) as any)).map(c =>
        mapSimpleCategoryTree(c as Category)
      )
    );
  }

  return {
    categories,
  };
}

export function useMegaMenuCategories() {
  const { cacheParam } = useSetLocaleToCacheParams();
  const { attachedCorporate } = useGetEliteInfo();
  const { corporateCacheParam } = useSetCorporateSourceToCacheParams();
  const cacheKey = pipe(cacheParam, corporateCacheParam)('categories');
  const categories = useCachedSsrRef<ReturnType<typeof mapCategoryTree>[]>(cacheKey, []);

  const { execute } = useQuery({
    query: CategoryListDocument,
    fetchOnMount: false,
    variables: {
      corporateId: attachedCorporate?.value?.id,
    },
  });

  useAsync(async () => {
    if (CATEGORY_QUERY_PENDING.get(cacheKey) === 'loading') {
      // Wait until PENDING is false
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (CATEGORY_QUERY_PENDING.get(cacheKey) !== 'loading') {
            clearInterval(interval);
            resolve(true);
          }
        }, 100);
      });
    }

    if (CATEGORY_QUERY_PENDING.get(cacheKey) !== 'loading' && CATEGORY_QUERY_PENDING.get(cacheKey)?.length) {
    }

    // Don't refetch categories unless cache expired
    if (categories.value.length) {
      return;
    }

    CATEGORY_QUERY_PENDING.set(cacheKey, 'loading');

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    categories.value = sortByPosition(
      toNonNullable(data?.categories?.items?.[0]?.children).map(c => mapMegaMenuCategoryTree(c as Category))
    );
    CATEGORY_QUERY_PENDING.set(cacheKey, flatten(categories.value));
  });

  const flatCategories = computed(() => {
    return flatten(categories.value);
  });

  const findBySlug = (slug: string) => {
    return flatCategories.value.find((c: any) => 'url_key' in c && c.url_key === slug);
  };

  return {
    megaMenuCategories: computed({
      get: () =>
        categories.value.filter(category => category.includeInMenu === 1 && category.featured_sort).slice(0, 9),
      set: () => {},
    }),
    findBySlug,
  };
}

/**
 * used to render the list of featured categories in the homepage
 * @returns
 */
export function useHomePageFeaturedCategories() {
  const { cacheParam } = useSetLocaleToCacheParams();
  const { attachedCorporate } = useGetEliteInfo();
  const { corporateCacheParam } = useSetCorporateSourceToCacheParams();
  const cacheKey = pipe(cacheParam, corporateCacheParam)('categories');

  const categories = useCachedSsrRef<Partial<Category>[] | []>(cacheKey, []);

  const { execute } = useQuery({
    query: CategoryListDocument,
    fetchOnMount: false,
    variables: {
      corporateId: attachedCorporate?.value?.id,
    },
  });

  useAsync(async () => {
    if (categories.value?.length) {
      return;
    }
    if (CATEGORY_QUERY_PENDING.get(cacheKey) === 'loading') {
      // Wait until PENDING is false
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (CATEGORY_QUERY_PENDING.get(cacheKey) !== 'loading') {
            clearInterval(interval);
            resolve(true);
          }
        }, 100);
      });
    }

    if (
      CATEGORY_QUERY_PENDING.get(cacheKey) &&
      CATEGORY_QUERY_PENDING.get(cacheKey) !== 'loading' &&
      CATEGORY_QUERY_PENDING.get(cacheKey)?.length
    ) {
      categories.value = CATEGORY_QUERY_PENDING.get(cacheKey) as Partial<Category>[];
      return;
    }

    // Don't refetch categories unless cache expired
    if (categories.value.length) {
      return;
    }
    CATEGORY_QUERY_PENDING.set(cacheKey, 'loading');

    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    categories.value = sortByPosition(
      toNonNullable(data?.categories?.items?.[0]?.children).map(c => mapMegaMenuCategoryTree(c as CategoryApiItem))
    );

    CATEGORY_QUERY_PENDING.set(cacheKey, categories.value);
  });

  return {
    featuredCategories: computed({
      get: () =>
        categories.value.filter(category => category.includeInMenu === 1 && category.featured_sort).slice(0, 9),
      set: () => {},
    }),
  };
}

export function useFeaturedCategoriesByBrand() {
  const categories = ref<Partial<Category>[]>([]);

  const { execute } = useQuery({
    query: CategoriesByBrandDocument,
    fetchOnMount: false,
  });

  async function getFeaturedCategoriesByBrand(brandId: number) {
    const { data, error } = await execute({
      variables: {
        brandId,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    categories.value = sortByPosition(
      toNonNullable(data?.categoriesByBrand?.items?.[0]?.children).map(c => mapMegaMenuCategoryTree(c as Category))
    );

    return categories.value.slice(0, 8);
  }

  return {
    getFeaturedCategoriesByBrand,
  };
}

export function useFeaturedCategoriesOfCustomPage() {
  const categories = ref<Partial<Category>[]>([]);

  const { execute } = useQuery({
    query: CategoryListDocument,
    fetchOnMount: false,
  });

  async function getFeaturedCategoriesOfCustomPage(includedCategoryIds: string[]) {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    categories.value = sortByPosition(
      toNonNullable(data?.categories?.items?.[0]?.children).map(c => mapMegaMenuCategoryTree(c as Category))
    );

    return categories.value.filter(category => includedCategoryIds.includes(category.id?.toString() || ''));
  }

  return {
    getFeaturedCategoriesOfCustomPage,
  };
}

function mapCategoryTree(
  apiItem: CategoryApiItem,
  siblings: Category[] = [],
  parent: Partial<Category> | null = null
): Category {
  return {
    ...apiItem,
    featured_sort: apiItem.featured_sort || 0,
    level: ((apiItem as any).level ?? 1) - 1,
    children: apiItem.children
      ? toNonNullable(apiItem.children as CategoryApiItem[]).map(c =>
          mapCategoryTree(c, apiItem.children as Category[], {
            name: apiItem.name,
            url_key: apiItem.url_key,
            id: apiItem.id,
            image: apiItem.image,
          })
        )
      : undefined,
    siblings: siblings || [],
    parent,
  };
}

/**
 *
 * @param apiItem
 * @param siblings
 * @param parent
 * @description mapping to optimized version of mega menu categories tree
 * @returns
 */
export function mapMegaMenuCategoryTree(apiItem: CategoryApiItem): Category {
  return {
    id: apiItem.id,
    uid: apiItem.uid,
    name: apiItem.name,
    url_key: apiItem.url_key,
    brand_ids: apiItem.brand_ids,
    includeInMenu: apiItem.includeInMenu,
    meta_title: (apiItem as any).meta_title,
    meta_description: (apiItem as any).meta_description,
    level: ((apiItem as any).level ?? 1) - 1,
    ...((apiItem as Category).offer ? { offer: (apiItem as Category).offer } : {}),
    ...((apiItem as Category).featuredBrands ? { featuredBrands: (apiItem as Category).featuredBrands } : {}),
    image: apiItem.image,
    path: apiItem.path,
    pageOffers: apiItem.pageOffers,
    children: apiItem.children
      ? toNonNullable(apiItem.children as CategoryApiItem[]).map(c => mapMegaMenuCategoryTree(c))
      : undefined,
    footer_content: apiItem.footer_content,
    is_shown_in_homepage: apiItem.is_shown_in_homepage,
    featured_sort: apiItem.featured_sort || 0,
  };
}

export function mapSimpleCategoryTree(apiItem: CategoryApiItem): Category {
  return {
    id: apiItem.id,
    uid: apiItem.uid,
    name: apiItem.name,
    url_key: apiItem.url_key,
    is_shown_in_homepage: apiItem.is_shown_in_homepage,
    pageOffers: apiItem.pageOffers,
    level: (apiItem.level ?? 1) - 1,
    ...((apiItem as Category).offer ? { offer: (apiItem as Category).offer } : {}),
    ...((apiItem as Category).featuredBrands ? { featuredBrands: (apiItem as Category).featuredBrands } : {}),
    children: apiItem.children
      ? toNonNullable(apiItem.children as CategoryApiItem[]).map(c => mapSimpleCategoryTree(c))
      : undefined,
  };
}

function flatten<T extends { children?: T[] }>(arr: T[]): T[] {
  return arr.reduce((acc: T[], value) => {
    const category = { ...value };
    acc.push(category);
    if (value.children) {
      acc = acc.concat(flatten(value.children));
    }

    return acc;
  }, []);
}

/**
 * Recursively sorts the categories by position
 */
function sortByPosition<T extends { children?: T[]; position?: number }>(arr: T[]): T[] {
  const sorted: T[] = [];
  arr.forEach(item => {
    if (item.children && item.children.length) {
      item.children = sortByPosition(item.children);
    }

    sorted.push(item);
  });

  sorted.sort((a, b) => (a.position || Number.MAX_VALUE) - (b.position || Number.MAX_VALUE));

  return sorted;
}

/**
 * sort categories by featured sort order
 * then filter them by is_shown_in_homepage flag
 * then get the first two in the list
 * to display their content in the two featured category sliders in the homepage
 */
function prepCategoriesForSliders(categories: (CategoryTree | Partial<Category>)[]) {
  return toNonNullable(categories)
    .filter(category => category && category.is_shown_in_homepage)
    .map(c => ({
      id: c?.id,
      uid: c?.uid,
      name: c?.name,
      url_key: c?.url_key,
      featured_sort: c?.featured_sort || undefined,
      is_shown_in_homepage: c?.is_shown_in_homepage || 0,
      image: c?.image,
    }))
    .sort((a, b) => (a.featured_sort || Number.MAX_VALUE) - (b.featured_sort || Number.MAX_VALUE))
    .slice(0, 2);
}
