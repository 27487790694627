import { render, staticRenderFns } from "./VerifyNationalId.vue?vue&type=template&id=01d2f1dd&scoped=true&"
import script from "./VerifyNationalId.vue?vue&type=script&lang=ts&"
export * from "./VerifyNationalId.vue?vue&type=script&lang=ts&"
import style0 from "./VerifyNationalId.vue?vue&type=style&index=0&id=01d2f1dd&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01d2f1dd",
  null
  
)

/* custom blocks */
import block0 from "./VerifyNationalId.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Spinner: require('/builds/robustastudio/raya/b2c/raya-b2c-frontend/components/Spinner.vue').default})
