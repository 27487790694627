import { ref } from '@nuxtjs/composition-api';

interface AlertOptions {
  id: number;
  title: string;
  subtitle?: string;
  type: 'success' | 'danger' | 'info' | 'warning';
}

const alerts = ref<AlertOptions[]>([]);
let ID_COUNTER = 0;

export function useAlerts() {
  function notify(title: string, subtitle: string, type: AlertOptions['type']) {
    const id = ID_COUNTER++;
    alerts.value.push({
      id,
      title,
      subtitle,
      type,
    });

    setTimeout(() => {
      dismiss(id);
    }, 3 * 1000); // 3 sec.
  }

  function success(title: string, subtitle?: string) {
    notify(title, subtitle || '', 'success');
  }
  function error(title: string, subtitle?: string) {
    notify(title, subtitle || '', 'danger');
  }
  function warn(title: string, subtitle?: string) {
    notify(title, subtitle || '', 'warning');
  }
  function info(title: string, subtitle?: string) {
    notify(title, subtitle || '', 'info');
  }

  function dismiss(id: number) {
    const idx = alerts.value.findIndex(i => i.id === id);
    if (idx === -1) {
      return;
    }

    alerts.value.splice(idx, 1);
  }

  return {
    alerts,
    success,
    info,
    error,
    warn,
    dismiss,
  };
}
