import { Application, Router, NextFunction, Request, Response } from 'express';

const fetch = require('node-fetch');

/**
 * Executes a simple JSON request without credentials
 */
export function makeHttpJSONRequest(
  method: string,
  endpoint: string,
  body: any,
  headers: Record<string, string | string[]>
) {
  const response = fetch(endpoint, {
    headers: {
      'content-type': 'application/json',
      ...(headers || {}),
    },
    method,
    body: JSON.stringify(body),
  });

  return response.then((res: any) => res.json());
}

/**
 * Installs express for a specific router instance
 * By default nuxt doesn't use express under the hood.
 */
export function useExpressForRoute(app: Application, router: Router) {
  // Install express for those requests.
  router.use((req: Request, res: Response, next: NextFunction) => {
    Object.setPrototypeOf(req, (app as any).request);
    Object.setPrototypeOf(res, (app as any).response);
    (req as any).res = res;
    (res as any).req = req;
    next();
  });
}
