import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _daee0150 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _72d12493 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _36c7b7b3 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _6aaa6bf6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5eac8ac9 = () => interopDefault(import('../pages/account/addresses/index.vue' /* webpackChunkName: "pages/account/addresses/index" */))
const _0842d370 = () => interopDefault(import('../pages/account/bills.vue' /* webpackChunkName: "pages/account/bills" */))
const _8f36c2ce = () => interopDefault(import('../pages/account/edit/index.vue' /* webpackChunkName: "pages/account/edit/index" */))
const _0281bf98 = () => interopDefault(import('../pages/account/elite-info.vue' /* webpackChunkName: "pages/account/elite-info" */))
const _44843aae = () => interopDefault(import('../pages/account/installments/index.vue' /* webpackChunkName: "pages/account/installments/index" */))
const _244f0e53 = () => interopDefault(import('../pages/account/my-promo-codes.vue' /* webpackChunkName: "pages/account/my-promo-codes" */))
const _06dcd0b4 = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _a87c8e5e = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _4ff68057 = () => interopDefault(import('../pages/account/addresses/new.vue' /* webpackChunkName: "pages/account/addresses/new" */))
const _68322652 = () => interopDefault(import('../pages/account/edit/application.vue' /* webpackChunkName: "pages/account/edit/application" */))
const _66e94c63 = () => interopDefault(import('../pages/account/edit/email.vue' /* webpackChunkName: "pages/account/edit/email" */))
const _16380924 = () => interopDefault(import('../pages/account/edit/password.vue' /* webpackChunkName: "pages/account/edit/password" */))
const _52c112a5 = () => interopDefault(import('../pages/account/elite/installments/index.vue' /* webpackChunkName: "pages/account/elite/installments/index" */))
const _7718923b = () => interopDefault(import('../pages/account/elite/installments/pay.vue' /* webpackChunkName: "pages/account/elite/installments/pay" */))
const _1048c6e7 = () => interopDefault(import('../pages/account/elite/installments/pay-installment.vue' /* webpackChunkName: "pages/account/elite/installments/pay-installment" */))
const _334eb27e = () => interopDefault(import('../pages/account/elite/installments/_month/index.vue' /* webpackChunkName: "pages/account/elite/installments/_month/index" */))
const _afb8fdd8 = () => interopDefault(import('../pages/account/installments/_id/index.vue' /* webpackChunkName: "pages/account/installments/_id/index" */))
const _2cb6719f = () => interopDefault(import('../pages/account/orders/_id/index.vue' /* webpackChunkName: "pages/account/orders/_id/index" */))
const _31991398 = () => interopDefault(import('../pages/account/addresses/_id/edit.vue' /* webpackChunkName: "pages/account/addresses/_id/edit" */))
const _d45c5f2c = () => interopDefault(import('../pages/account/installments/_id/pay.vue' /* webpackChunkName: "pages/account/installments/_id/pay" */))
const _67233696 = () => interopDefault(import('../pages/account/installments/_id/pay-installment.vue' /* webpackChunkName: "pages/account/installments/_id/pay-installment" */))
const _7ea46424 = () => interopDefault(import('../pages/account/orders/_id/rate-order.vue' /* webpackChunkName: "pages/account/orders/_id/rate-order" */))
const _07a607d5 = () => interopDefault(import('../pages/bills/index.vue' /* webpackChunkName: "pages/bills/index" */))
const _797ecc6c = () => interopDefault(import('../pages/cache-clearing.vue' /* webpackChunkName: "pages/cache-clearing" */))
const _455f29ec = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _8a1e7da0 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _b68a9d64 = () => interopDefault(import('../pages/checkout/account.vue' /* webpackChunkName: "pages/checkout/account" */))
const _fc21ca62 = () => interopDefault(import('../pages/checkout/elite-pay.vue' /* webpackChunkName: "pages/checkout/elite-pay" */))
const _6ed80ec9 = () => interopDefault(import('../pages/checkout/pay.vue' /* webpackChunkName: "pages/checkout/pay" */))
const _c723d146 = () => interopDefault(import('../pages/checkout/shipping.vue' /* webpackChunkName: "pages/checkout/shipping" */))
const _2ad9632a = () => interopDefault(import('../pages/compare.vue' /* webpackChunkName: "pages/compare" */))
const _05972026 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _0e521895 = () => interopDefault(import('../pages/custom-deals.vue' /* webpackChunkName: "pages/custom-deals" */))
const _17772360 = () => interopDefault(import('../pages/deals/index.vue' /* webpackChunkName: "pages/deals/index" */))
const _db8ec816 = () => interopDefault(import('../pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _9b135eca = () => interopDefault(import('../pages/edit-address.vue' /* webpackChunkName: "pages/edit-address" */))
const _37a0b938 = () => interopDefault(import('../pages/edit-job.vue' /* webpackChunkName: "pages/edit-job" */))
const _29e60a02 = () => interopDefault(import('../pages/edit-personal-info.vue' /* webpackChunkName: "pages/edit-personal-info" */))
const _da7df6ce = () => interopDefault(import('../pages/elite.vue' /* webpackChunkName: "pages/elite" */))
const _5c08d11a = () => interopDefault(import('../pages/elite/login.vue' /* webpackChunkName: "pages/elite/login" */))
const _a9632eee = () => interopDefault(import('../pages/elite/register.vue' /* webpackChunkName: "pages/elite/register" */))
const _1fc1121e = () => interopDefault(import('../pages/elite-index.vue' /* webpackChunkName: "pages/elite-index" */))
const _dd814bdc = () => interopDefault(import('../pages/elite-info.vue' /* webpackChunkName: "pages/elite-info" */))
const _786ba2b0 = () => interopDefault(import('../pages/elite-offer.vue' /* webpackChunkName: "pages/elite-offer" */))
const _96877aa6 = () => interopDefault(import('../pages/elite-store.vue' /* webpackChunkName: "pages/elite-store" */))
const _5361c4e4 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _13310187 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _f05852f6 = () => interopDefault(import('../pages/gig-terms.vue' /* webpackChunkName: "pages/gig-terms" */))
const _546c2ec8 = () => interopDefault(import('../pages/guest-installments/index.vue' /* webpackChunkName: "pages/guest-installments/index" */))
const _01039402 = () => interopDefault(import('../pages/installment.vue' /* webpackChunkName: "pages/installment" */))
const _3f51ecc0 = () => interopDefault(import('../pages/installment/application.vue' /* webpackChunkName: "pages/installment/application" */))
const _b89179ee = () => interopDefault(import('../pages/installment/oldapplication.vue' /* webpackChunkName: "pages/installment/oldapplication" */))
const _12f08418 = () => interopDefault(import('../pages/installment/pay.vue' /* webpackChunkName: "pages/installment/pay" */))
const _64a02eb7 = () => interopDefault(import('../pages/installment/pay-with-installment.vue' /* webpackChunkName: "pages/installment/pay-with-installment" */))
const _15b4c924 = () => interopDefault(import('../pages/installment/shipping.vue' /* webpackChunkName: "pages/installment/shipping" */))
const _3af2c742 = () => interopDefault(import('../pages/installment-account.vue' /* webpackChunkName: "pages/installment-account" */))
const _1817a9f8 = () => interopDefault(import('../pages/installments.vue' /* webpackChunkName: "pages/installments" */))
const _e593db40 = () => interopDefault(import('../pages/insurance.vue' /* webpackChunkName: "pages/insurance" */))
const _5c30a26f = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4e093d4c = () => interopDefault(import('../pages/loyalty.vue' /* webpackChunkName: "pages/loyalty" */))
const _e63df5c8 = () => interopDefault(import('../pages/national-id.vue' /* webpackChunkName: "pages/national-id" */))
const _07744b41 = () => interopDefault(import('../pages/nearest-store-elite-application.vue' /* webpackChunkName: "pages/nearest-store-elite-application" */))
const _801bca10 = () => interopDefault(import('../pages/predict-and-win.vue' /* webpackChunkName: "pages/predict-and-win" */))
const _5cc32b8e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _41ae31ca = () => interopDefault(import('../pages/raya-info.vue' /* webpackChunkName: "pages/raya-info" */))
const _231ffcb4 = () => interopDefault(import('../pages/raya-info/merchants.vue' /* webpackChunkName: "pages/raya-info/merchants" */))
const _243afbab = () => interopDefault(import('../pages/raya-info/raya-plus.vue' /* webpackChunkName: "pages/raya-info/raya-plus" */))
const _bb450280 = () => interopDefault(import('../pages/raya-info/redemption.vue' /* webpackChunkName: "pages/raya-info/redemption" */))
const _5ea18daa = () => interopDefault(import('../pages/raya-info/transactions.vue' /* webpackChunkName: "pages/raya-info/transactions" */))
const _0410a7e6 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _74d97d5b = () => interopDefault(import('../pages/request-maintenance.vue' /* webpackChunkName: "pages/request-maintenance" */))
const _373acecc = () => interopDefault(import('../pages/return.vue' /* webpackChunkName: "pages/return" */))
const _4f4bcbb0 = () => interopDefault(import('../pages/seller-dashboard.vue' /* webpackChunkName: "pages/seller-dashboard" */))
const _6e1cce33 = () => interopDefault(import('../pages/seller-dashboard/index.vue' /* webpackChunkName: "pages/seller-dashboard/index" */))
const _da004052 = () => interopDefault(import('../pages/seller-dashboard/orders/index.vue' /* webpackChunkName: "pages/seller-dashboard/orders/index" */))
const _39c3d1d6 = () => interopDefault(import('../pages/seller-dashboard/products/index.vue' /* webpackChunkName: "pages/seller-dashboard/products/index" */))
const _da308cf8 = () => interopDefault(import('../pages/seller-dashboard/transactions.vue' /* webpackChunkName: "pages/seller-dashboard/transactions" */))
const _76b1917c = () => interopDefault(import('../pages/seller-dashboard/orders/_id/index.vue' /* webpackChunkName: "pages/seller-dashboard/orders/_id/index" */))
const _b7d7dc7e = () => interopDefault(import('../pages/seller-dashboard/products/_id/index.vue' /* webpackChunkName: "pages/seller-dashboard/products/_id/index" */))
const _c4e6a73e = () => interopDefault(import('../pages/seller-dashboard/orders/_id/rate-order.vue' /* webpackChunkName: "pages/seller-dashboard/orders/_id/rate-order" */))
const _66a523c8 = () => interopDefault(import('../pages/stores.vue' /* webpackChunkName: "pages/stores" */))
const _d026a8fe = () => interopDefault(import('../pages/taksety-application/index.vue' /* webpackChunkName: "pages/taksety-application/index" */))
const _3c86bd8d = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _cedba908 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _330e3f13 = () => interopDefault(import('../pages/track-request.vue' /* webpackChunkName: "pages/track-request" */))
const _3070e767 = () => interopDefault(import('../pages/auth/recover.vue' /* webpackChunkName: "pages/auth/recover" */))
const _26535326 = () => interopDefault(import('../pages/auth/verify-account.vue' /* webpackChunkName: "pages/auth/verify-account" */))
const _51e74227 = () => interopDefault(import('../pages/auth/verify-phone.vue' /* webpackChunkName: "pages/auth/verify-phone" */))
const _2bcdd92a = () => interopDefault(import('../pages/bills/pay.vue' /* webpackChunkName: "pages/bills/pay" */))
const _598b6fc6 = () => interopDefault(import('../pages/bills/success.vue' /* webpackChunkName: "pages/bills/success" */))
const _3dfc2948 = () => interopDefault(import('../pages/orders/error.vue' /* webpackChunkName: "pages/orders/error" */))
const _2fb0d07a = () => interopDefault(import('../pages/orders/success.vue' /* webpackChunkName: "pages/orders/success" */))
const _1a904f1c = () => interopDefault(import('../pages/taksety-application/success.vue' /* webpackChunkName: "pages/taksety-application/success" */))
const _1e99c02d = () => interopDefault(import('../pages/orders/installment/success.vue' /* webpackChunkName: "pages/orders/installment/success" */))
const _2b060787 = () => interopDefault(import('../pages/shop-by/brand/_slug.vue' /* webpackChunkName: "pages/shop-by/brand/_slug" */))
const _20fd08a9 = () => interopDefault(import('../pages/orders/installments/_id/success.vue' /* webpackChunkName: "pages/orders/installments/_id/success" */))
const _734dfb7d = () => interopDefault(import('../pages/bills/_id.vue' /* webpackChunkName: "pages/bills/_id" */))
const _553c101e = () => interopDefault(import('../pages/brands/_slug.vue' /* webpackChunkName: "pages/brands/_slug" */))
const _242535ca = () => interopDefault(import('../pages/cms/_slug.vue' /* webpackChunkName: "pages/cms/_slug" */))
const _0a7ef00d = () => interopDefault(import('../pages/custom-pages/_slug.vue' /* webpackChunkName: "pages/custom-pages/_slug" */))
const _63ec2337 = () => interopDefault(import('../pages/guest-installments/_application.vue' /* webpackChunkName: "pages/guest-installments/_application" */))
const _3166b4ad = () => interopDefault(import('../pages/search/_name.vue' /* webpackChunkName: "pages/search/_name" */))
const _eb884438 = () => interopDefault(import('../pages/seller/_slug.vue' /* webpackChunkName: "pages/seller/_slug" */))
const _8a17b4c6 = () => interopDefault(import('../pages/guest-installments/_id/pay.vue' /* webpackChunkName: "pages/guest-installments/_id/pay" */))
const _f2f78842 = () => interopDefault(import('../pages/orders/_id/cancel.vue' /* webpackChunkName: "pages/orders/_id/cancel" */))
const _65a5ff95 = () => interopDefault(import('../pages/orders/_id/return.vue' /* webpackChunkName: "pages/orders/_id/return" */))
const _5eb80aee = () => interopDefault(import('../pages/orders/_id/success.vue' /* webpackChunkName: "pages/orders/_id/success" */))
const _8de6adc2 = () => interopDefault(import('../pages/_/create-review.vue' /* webpackChunkName: "pages/_/create-review" */))
const _2540d3b0 = () => interopDefault(import('../pages/_/index.vue' /* webpackChunkName: "pages/_/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ar",
    component: _daee0150,
    name: "index___ar"
  }, {
    path: "/en",
    component: _daee0150,
    name: "index___en"
  }, {
    path: "/ar/about",
    component: _72d12493,
    name: "about___ar"
  }, {
    path: "/ar/account",
    component: _36c7b7b3,
    children: [{
      path: "",
      component: _6aaa6bf6,
      name: "account___ar"
    }, {
      path: "addresses",
      component: _5eac8ac9,
      name: "account-addresses___ar"
    }, {
      path: "bills",
      component: _0842d370,
      name: "account-bills___ar"
    }, {
      path: "edit",
      component: _8f36c2ce,
      name: "account-edit___ar"
    }, {
      path: "elite-info",
      component: _0281bf98,
      name: "account-elite-info___ar"
    }, {
      path: "installments",
      component: _44843aae,
      name: "account-installments___ar"
    }, {
      path: "my-promo-codes",
      component: _244f0e53,
      name: "account-my-promo-codes___ar"
    }, {
      path: "orders",
      component: _06dcd0b4,
      name: "account-orders___ar"
    }, {
      path: "wishlist",
      component: _a87c8e5e,
      name: "account-wishlist___ar"
    }, {
      path: "addresses/new",
      component: _4ff68057,
      name: "account-addresses-new___ar"
    }, {
      path: "edit/application",
      component: _68322652,
      name: "account-edit-application___ar"
    }, {
      path: "edit/email",
      component: _66e94c63,
      name: "account-edit-email___ar"
    }, {
      path: "edit/password",
      component: _16380924,
      name: "account-edit-password___ar"
    }, {
      path: "elite/installments",
      component: _52c112a5,
      name: "account-elite-installments___ar"
    }, {
      path: "elite/installments/pay",
      component: _7718923b,
      name: "account-elite-installments-pay___ar"
    }, {
      path: "elite/installments/pay-installment",
      component: _1048c6e7,
      name: "account-elite-installments-pay-installment___ar"
    }, {
      path: "elite/installments/:month",
      component: _334eb27e,
      name: "account-elite-installments-month___ar"
    }, {
      path: "installments/:id",
      component: _afb8fdd8,
      name: "account-installments-id___ar"
    }, {
      path: "orders/:id",
      component: _2cb6719f,
      name: "account-orders-id___ar"
    }, {
      path: "addresses/:id/edit",
      component: _31991398,
      name: "account-addresses-id-edit___ar"
    }, {
      path: "installments/:id/pay",
      component: _d45c5f2c,
      name: "account-installments-id-pay___ar"
    }, {
      path: "installments/:id/pay-installment",
      component: _67233696,
      name: "account-installments-id-pay-installment___ar"
    }, {
      path: "orders/:id/rate-order",
      component: _7ea46424,
      name: "account-orders-id-rate-order___ar"
    }]
  }, {
    path: "/ar/bills",
    component: _07a607d5,
    name: "bills___ar"
  }, {
    path: "/ar/cache-clearing",
    component: _797ecc6c,
    name: "cache-clearing___ar"
  }, {
    path: "/ar/cart",
    component: _455f29ec,
    name: "cart___ar"
  }, {
    path: "/ar/checkout",
    component: _8a1e7da0,
    name: "checkout___ar",
    children: [{
      path: "account",
      component: _b68a9d64,
      name: "checkout-account___ar"
    }, {
      path: "elite-pay",
      component: _fc21ca62,
      name: "checkout-elite-pay___ar"
    }, {
      path: "pay",
      component: _6ed80ec9,
      name: "checkout-pay___ar"
    }, {
      path: "shipping",
      component: _c723d146,
      name: "checkout-shipping___ar"
    }]
  }, {
    path: "/ar/compare",
    component: _2ad9632a,
    name: "compare___ar"
  }, {
    path: "/ar/contact",
    component: _05972026,
    name: "contact___ar"
  }, {
    path: "/ar/custom-deals",
    component: _0e521895,
    name: "custom-deals___ar"
  }, {
    path: "/ar/deals",
    component: _17772360,
    name: "deals___ar"
  }, {
    path: "/ar/delete-account",
    component: _db8ec816,
    name: "delete-account___ar"
  }, {
    path: "/ar/edit-address",
    component: _9b135eca,
    name: "edit-address___ar"
  }, {
    path: "/ar/edit-job",
    component: _37a0b938,
    name: "edit-job___ar"
  }, {
    path: "/ar/edit-personal-info",
    component: _29e60a02,
    name: "edit-personal-info___ar"
  }, {
    path: "/ar/elite",
    component: _da7df6ce,
    name: "elite___ar",
    children: [{
      path: "login",
      component: _5c08d11a,
      name: "elite-login___ar"
    }, {
      path: "register",
      component: _a9632eee,
      name: "elite-register___ar"
    }]
  }, {
    path: "/ar/elite-index",
    component: _1fc1121e,
    name: "elite___ar"
  }, {
    path: "/ar/elite-info",
    component: _dd814bdc,
    name: "elite-info___ar"
  }, {
    path: "/ar/elite-offer",
    component: _786ba2b0,
    name: "elite-offer___ar"
  }, {
    path: "/ar/elite-store",
    component: _96877aa6,
    name: "elite-store___ar"
  }, {
    path: "/ar/error",
    component: _5361c4e4,
    name: "error___ar"
  }, {
    path: "/ar/faqs",
    component: _13310187,
    name: "faqs___ar"
  }, {
    path: "/ar/gig-terms",
    component: _f05852f6,
    name: "gig-terms___ar"
  }, {
    path: "/ar/guest-installments",
    component: _546c2ec8,
    name: "guest-installments___ar"
  }, {
    path: "/ar/installment",
    component: _01039402,
    name: "installment___ar",
    children: [{
      path: "application",
      component: _3f51ecc0,
      name: "installment-application___ar"
    }, {
      path: "oldapplication",
      component: _b89179ee,
      name: "installment-oldapplication___ar"
    }, {
      path: "pay",
      component: _12f08418,
      name: "installment-pay___ar"
    }, {
      path: "pay-with-installment",
      component: _64a02eb7,
      name: "installment-pay-with-installment___ar"
    }, {
      path: "shipping",
      component: _15b4c924,
      name: "installment-shipping___ar"
    }]
  }, {
    path: "/ar/installment-account",
    component: _3af2c742,
    name: "installment-account___ar"
  }, {
    path: "/ar/installments",
    component: _1817a9f8,
    name: "installments___ar"
  }, {
    path: "/ar/insurance",
    component: _e593db40,
    name: "insurance___ar"
  }, {
    path: "/ar/login",
    component: _5c30a26f,
    name: "login___ar"
  }, {
    path: "/ar/loyalty",
    component: _4e093d4c,
    name: "loyalty___ar"
  }, {
    path: "/ar/national-id",
    component: _e63df5c8,
    name: "national-id___ar"
  }, {
    path: "/ar/nearest-store-elite-application",
    component: _07744b41,
    name: "nearest-store-elite-application___ar"
  }, {
    path: "/ar/predict-and-win",
    component: _801bca10,
    name: "predict-and-win___ar"
  }, {
    path: "/ar/privacy",
    component: _5cc32b8e,
    name: "privacy___ar"
  }, {
    path: "/ar/raya-info",
    component: _41ae31ca,
    name: "raya-info___ar",
    children: [{
      path: "merchants",
      component: _231ffcb4,
      name: "raya-info-merchants___ar"
    }, {
      path: "raya-plus",
      component: _243afbab,
      name: "raya-info-raya-plus___ar"
    }, {
      path: "redemption",
      component: _bb450280,
      name: "raya-info-redemption___ar"
    }, {
      path: "transactions",
      component: _5ea18daa,
      name: "raya-info-transactions___ar"
    }]
  }, {
    path: "/ar/register",
    component: _0410a7e6,
    name: "register___ar"
  }, {
    path: "/ar/request-maintenance",
    component: _74d97d5b,
    name: "request-maintenance___ar"
  }, {
    path: "/ar/return",
    component: _373acecc,
    name: "return___ar"
  }, {
    path: "/ar/seller-dashboard",
    component: _4f4bcbb0,
    children: [{
      path: "",
      component: _6e1cce33,
      name: "seller-dashboard___ar"
    }, {
      path: "orders",
      component: _da004052,
      name: "seller-dashboard-orders___ar"
    }, {
      path: "products",
      component: _39c3d1d6,
      name: "seller-dashboard-products___ar"
    }, {
      path: "transactions",
      component: _da308cf8,
      name: "seller-dashboard-transactions___ar"
    }, {
      path: "orders/:id",
      component: _76b1917c,
      name: "seller-dashboard-orders-id___ar"
    }, {
      path: "products/:id",
      component: _b7d7dc7e,
      name: "seller-dashboard-products-id___ar"
    }, {
      path: "orders/:id/rate-order",
      component: _c4e6a73e,
      name: "seller-dashboard-orders-id-rate-order___ar"
    }]
  }, {
    path: "/ar/stores",
    component: _66a523c8,
    name: "stores___ar"
  }, {
    path: "/ar/taksety-application",
    component: _d026a8fe,
    name: "taksety-application___ar"
  }, {
    path: "/ar/terms",
    component: _3c86bd8d,
    name: "terms___ar"
  }, {
    path: "/ar/test",
    component: _cedba908,
    name: "test___ar"
  }, {
    path: "/ar/track-request",
    component: _330e3f13,
    name: "track-request___ar"
  }, {
    path: "/en/about",
    component: _72d12493,
    name: "about___en"
  }, {
    path: "/en/account",
    component: _36c7b7b3,
    children: [{
      path: "",
      component: _6aaa6bf6,
      name: "account___en"
    }, {
      path: "addresses",
      component: _5eac8ac9,
      name: "account-addresses___en"
    }, {
      path: "bills",
      component: _0842d370,
      name: "account-bills___en"
    }, {
      path: "edit",
      component: _8f36c2ce,
      name: "account-edit___en"
    }, {
      path: "elite-info",
      component: _0281bf98,
      name: "account-elite-info___en"
    }, {
      path: "installments",
      component: _44843aae,
      name: "account-installments___en"
    }, {
      path: "my-promo-codes",
      component: _244f0e53,
      name: "account-my-promo-codes___en"
    }, {
      path: "orders",
      component: _06dcd0b4,
      name: "account-orders___en"
    }, {
      path: "wishlist",
      component: _a87c8e5e,
      name: "account-wishlist___en"
    }, {
      path: "addresses/new",
      component: _4ff68057,
      name: "account-addresses-new___en"
    }, {
      path: "edit/application",
      component: _68322652,
      name: "account-edit-application___en"
    }, {
      path: "edit/email",
      component: _66e94c63,
      name: "account-edit-email___en"
    }, {
      path: "edit/password",
      component: _16380924,
      name: "account-edit-password___en"
    }, {
      path: "elite/installments",
      component: _52c112a5,
      name: "account-elite-installments___en"
    }, {
      path: "elite/installments/pay",
      component: _7718923b,
      name: "account-elite-installments-pay___en"
    }, {
      path: "elite/installments/pay-installment",
      component: _1048c6e7,
      name: "account-elite-installments-pay-installment___en"
    }, {
      path: "elite/installments/:month",
      component: _334eb27e,
      name: "account-elite-installments-month___en"
    }, {
      path: "installments/:id",
      component: _afb8fdd8,
      name: "account-installments-id___en"
    }, {
      path: "orders/:id",
      component: _2cb6719f,
      name: "account-orders-id___en"
    }, {
      path: "addresses/:id/edit",
      component: _31991398,
      name: "account-addresses-id-edit___en"
    }, {
      path: "installments/:id/pay",
      component: _d45c5f2c,
      name: "account-installments-id-pay___en"
    }, {
      path: "installments/:id/pay-installment",
      component: _67233696,
      name: "account-installments-id-pay-installment___en"
    }, {
      path: "orders/:id/rate-order",
      component: _7ea46424,
      name: "account-orders-id-rate-order___en"
    }]
  }, {
    path: "/en/bills",
    component: _07a607d5,
    name: "bills___en"
  }, {
    path: "/en/cache-clearing",
    component: _797ecc6c,
    name: "cache-clearing___en"
  }, {
    path: "/en/cart",
    component: _455f29ec,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _8a1e7da0,
    name: "checkout___en",
    children: [{
      path: "account",
      component: _b68a9d64,
      name: "checkout-account___en"
    }, {
      path: "elite-pay",
      component: _fc21ca62,
      name: "checkout-elite-pay___en"
    }, {
      path: "pay",
      component: _6ed80ec9,
      name: "checkout-pay___en"
    }, {
      path: "shipping",
      component: _c723d146,
      name: "checkout-shipping___en"
    }]
  }, {
    path: "/en/compare",
    component: _2ad9632a,
    name: "compare___en"
  }, {
    path: "/en/contact",
    component: _05972026,
    name: "contact___en"
  }, {
    path: "/en/custom-deals",
    component: _0e521895,
    name: "custom-deals___en"
  }, {
    path: "/en/deals",
    component: _17772360,
    name: "deals___en"
  }, {
    path: "/en/delete-account",
    component: _db8ec816,
    name: "delete-account___en"
  }, {
    path: "/en/edit-address",
    component: _9b135eca,
    name: "edit-address___en"
  }, {
    path: "/en/edit-job",
    component: _37a0b938,
    name: "edit-job___en"
  }, {
    path: "/en/edit-personal-info",
    component: _29e60a02,
    name: "edit-personal-info___en"
  }, {
    path: "/en/elite",
    component: _da7df6ce,
    name: "elite___en",
    children: [{
      path: "login",
      component: _5c08d11a,
      name: "elite-login___en"
    }, {
      path: "register",
      component: _a9632eee,
      name: "elite-register___en"
    }]
  }, {
    path: "/en/elite-index",
    component: _1fc1121e,
    name: "elite___en"
  }, {
    path: "/en/elite-info",
    component: _dd814bdc,
    name: "elite-info___en"
  }, {
    path: "/en/elite-offer",
    component: _786ba2b0,
    name: "elite-offer___en"
  }, {
    path: "/en/elite-store",
    component: _96877aa6,
    name: "elite-store___en"
  }, {
    path: "/en/error",
    component: _5361c4e4,
    name: "error___en"
  }, {
    path: "/en/faqs",
    component: _13310187,
    name: "faqs___en"
  }, {
    path: "/en/gig-terms",
    component: _f05852f6,
    name: "gig-terms___en"
  }, {
    path: "/en/guest-installments",
    component: _546c2ec8,
    name: "guest-installments___en"
  }, {
    path: "/en/installment",
    component: _01039402,
    name: "installment___en",
    children: [{
      path: "application",
      component: _3f51ecc0,
      name: "installment-application___en"
    }, {
      path: "oldapplication",
      component: _b89179ee,
      name: "installment-oldapplication___en"
    }, {
      path: "pay",
      component: _12f08418,
      name: "installment-pay___en"
    }, {
      path: "pay-with-installment",
      component: _64a02eb7,
      name: "installment-pay-with-installment___en"
    }, {
      path: "shipping",
      component: _15b4c924,
      name: "installment-shipping___en"
    }]
  }, {
    path: "/en/installment-account",
    component: _3af2c742,
    name: "installment-account___en"
  }, {
    path: "/en/installments",
    component: _1817a9f8,
    name: "installments___en"
  }, {
    path: "/en/insurance",
    component: _e593db40,
    name: "insurance___en"
  }, {
    path: "/en/login",
    component: _5c30a26f,
    name: "login___en"
  }, {
    path: "/en/loyalty",
    component: _4e093d4c,
    name: "loyalty___en"
  }, {
    path: "/en/national-id",
    component: _e63df5c8,
    name: "national-id___en"
  }, {
    path: "/en/nearest-store-elite-application",
    component: _07744b41,
    name: "nearest-store-elite-application___en"
  }, {
    path: "/en/predict-and-win",
    component: _801bca10,
    name: "predict-and-win___en"
  }, {
    path: "/en/privacy",
    component: _5cc32b8e,
    name: "privacy___en"
  }, {
    path: "/en/raya-info",
    component: _41ae31ca,
    name: "raya-info___en",
    children: [{
      path: "merchants",
      component: _231ffcb4,
      name: "raya-info-merchants___en"
    }, {
      path: "raya-plus",
      component: _243afbab,
      name: "raya-info-raya-plus___en"
    }, {
      path: "redemption",
      component: _bb450280,
      name: "raya-info-redemption___en"
    }, {
      path: "transactions",
      component: _5ea18daa,
      name: "raya-info-transactions___en"
    }]
  }, {
    path: "/en/register",
    component: _0410a7e6,
    name: "register___en"
  }, {
    path: "/en/request-maintenance",
    component: _74d97d5b,
    name: "request-maintenance___en"
  }, {
    path: "/en/return",
    component: _373acecc,
    name: "return___en"
  }, {
    path: "/en/seller-dashboard",
    component: _4f4bcbb0,
    children: [{
      path: "",
      component: _6e1cce33,
      name: "seller-dashboard___en"
    }, {
      path: "orders",
      component: _da004052,
      name: "seller-dashboard-orders___en"
    }, {
      path: "products",
      component: _39c3d1d6,
      name: "seller-dashboard-products___en"
    }, {
      path: "transactions",
      component: _da308cf8,
      name: "seller-dashboard-transactions___en"
    }, {
      path: "orders/:id",
      component: _76b1917c,
      name: "seller-dashboard-orders-id___en"
    }, {
      path: "products/:id",
      component: _b7d7dc7e,
      name: "seller-dashboard-products-id___en"
    }, {
      path: "orders/:id/rate-order",
      component: _c4e6a73e,
      name: "seller-dashboard-orders-id-rate-order___en"
    }]
  }, {
    path: "/en/stores",
    component: _66a523c8,
    name: "stores___en"
  }, {
    path: "/en/taksety-application",
    component: _d026a8fe,
    name: "taksety-application___en"
  }, {
    path: "/en/terms",
    component: _3c86bd8d,
    name: "terms___en"
  }, {
    path: "/en/test",
    component: _cedba908,
    name: "test___en"
  }, {
    path: "/en/track-request",
    component: _330e3f13,
    name: "track-request___en"
  }, {
    path: "/ar/auth/recover",
    component: _3070e767,
    name: "auth-recover___ar"
  }, {
    path: "/ar/auth/verify-account",
    component: _26535326,
    name: "auth-verify-account___ar"
  }, {
    path: "/ar/auth/verify-phone",
    component: _51e74227,
    name: "auth-verify-phone___ar"
  }, {
    path: "/ar/bills/pay",
    component: _2bcdd92a,
    name: "bills-pay___ar"
  }, {
    path: "/ar/bills/success",
    component: _598b6fc6,
    name: "bills-success___ar"
  }, {
    path: "/ar/orders/error",
    component: _3dfc2948,
    name: "orders-error___ar"
  }, {
    path: "/ar/orders/success",
    component: _2fb0d07a,
    name: "orders-success___ar"
  }, {
    path: "/ar/taksety-application/success",
    component: _1a904f1c,
    name: "taksety-application-success___ar"
  }, {
    path: "/en/auth/recover",
    component: _3070e767,
    name: "auth-recover___en"
  }, {
    path: "/en/auth/verify-account",
    component: _26535326,
    name: "auth-verify-account___en"
  }, {
    path: "/en/auth/verify-phone",
    component: _51e74227,
    name: "auth-verify-phone___en"
  }, {
    path: "/en/bills/pay",
    component: _2bcdd92a,
    name: "bills-pay___en"
  }, {
    path: "/en/bills/success",
    component: _598b6fc6,
    name: "bills-success___en"
  }, {
    path: "/en/orders/error",
    component: _3dfc2948,
    name: "orders-error___en"
  }, {
    path: "/en/orders/success",
    component: _2fb0d07a,
    name: "orders-success___en"
  }, {
    path: "/en/taksety-application/success",
    component: _1a904f1c,
    name: "taksety-application-success___en"
  }, {
    path: "/ar/orders/installment/success",
    component: _1e99c02d,
    name: "orders-installment-success___ar"
  }, {
    path: "/en/orders/installment/success",
    component: _1e99c02d,
    name: "orders-installment-success___en"
  }, {
    path: "/ar/shop-by/brand/:slug?",
    component: _2b060787,
    name: "shop-by-brand-slug___ar"
  }, {
    path: "/en/shop-by/brand/:slug?",
    component: _2b060787,
    name: "shop-by-brand-slug___en"
  }, {
    path: "/ar/orders/installments/:id?/success",
    component: _20fd08a9,
    name: "orders-installments-id-success___ar"
  }, {
    path: "/en/orders/installments/:id?/success",
    component: _20fd08a9,
    name: "orders-installments-id-success___en"
  }, {
    path: "/ar/bills/:id",
    component: _734dfb7d,
    name: "bills-id___ar"
  }, {
    path: "/ar/brands/:slug?",
    component: _553c101e,
    name: "brands-slug___ar"
  }, {
    path: "/ar/cms/:slug?",
    component: _242535ca,
    name: "cms-slug___ar"
  }, {
    path: "/ar/custom-pages/:slug?",
    component: _0a7ef00d,
    name: "custom-pages-slug___ar"
  }, {
    path: "/ar/guest-installments/:application?",
    component: _63ec2337,
    name: "guest-installments-application___ar"
  }, {
    path: "/ar/search/:name?",
    component: _3166b4ad,
    name: "search-name___ar"
  }, {
    path: "/ar/seller/:slug?",
    component: _eb884438,
    name: "seller-slug___ar"
  }, {
    path: "/en/bills/:id",
    component: _734dfb7d,
    name: "bills-id___en"
  }, {
    path: "/en/brands/:slug?",
    component: _553c101e,
    name: "brands-slug___en"
  }, {
    path: "/en/cms/:slug?",
    component: _242535ca,
    name: "cms-slug___en"
  }, {
    path: "/en/custom-pages/:slug?",
    component: _0a7ef00d,
    name: "custom-pages-slug___en"
  }, {
    path: "/en/guest-installments/:application?",
    component: _63ec2337,
    name: "guest-installments-application___en"
  }, {
    path: "/en/search/:name?",
    component: _3166b4ad,
    name: "search-name___en"
  }, {
    path: "/en/seller/:slug?",
    component: _eb884438,
    name: "seller-slug___en"
  }, {
    path: "/ar/guest-installments/:id?/pay",
    component: _8a17b4c6,
    name: "guest-installments-id-pay___ar"
  }, {
    path: "/ar/orders/:id?/cancel",
    component: _f2f78842,
    name: "orders-id-cancel___ar"
  }, {
    path: "/ar/orders/:id?/return",
    component: _65a5ff95,
    name: "orders-id-return___ar"
  }, {
    path: "/ar/orders/:id?/success",
    component: _5eb80aee,
    name: "orders-id-success___ar"
  }, {
    path: "/en/guest-installments/:id?/pay",
    component: _8a17b4c6,
    name: "guest-installments-id-pay___en"
  }, {
    path: "/en/orders/:id?/cancel",
    component: _f2f78842,
    name: "orders-id-cancel___en"
  }, {
    path: "/en/orders/:id?/return",
    component: _65a5ff95,
    name: "orders-id-return___en"
  }, {
    path: "/en/orders/:id?/success",
    component: _5eb80aee,
    name: "orders-id-success___en"
  }, {
    path: "/ar/*/create-review",
    component: _8de6adc2,
    name: "all-create-review___ar"
  }, {
    path: "/en/*/create-review",
    component: _8de6adc2,
    name: "all-create-review___en"
  }, {
    path: "/en/*",
    component: _2540d3b0,
    name: "all___en"
  }, {
    path: "/ar/*",
    component: _2540d3b0,
    name: "all___ar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
