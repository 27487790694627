

































































import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { useEventBus } from '~/features/events';
import { useRouter } from '~/features/router';
import { useSearchSuggestions } from '~/features/search';
import { useStoreConfig } from '~/features/storeConfig';

export default defineComponent({
  setup() {
    const searchBy = ref('');

    const { redirect } = useRouter();
    const { emit } = useEventBus();
    const { blackFridayThemeEnabled } = useStoreConfig();

    const searchResults = ref<any[]>([]);
    const isFetching = ref(false);

    const isOpen = ref(false);

    const { getSearchSuggestions } = useSearchSuggestions();

    function navigateToSearchPage() {
      if (!searchBy.value.length) {
        return;
      }
      isOpen.value = false;
      const searchParam = encodeURIComponent(searchBy.value);
      redirect(`/search/${searchParam}`, { query: { search: searchBy.value } });
    }

    function openSearchMenu() {
      emit('TOGGLE_SEARCH_MENU', true);
    }

    watch(
      searchBy,
      debounce(async (value: string) => {
        isFetching.value = true;
        isOpen.value = Boolean(value.length);

        try {
          if (value.length) {
            const { data } = await getSearchSuggestions({ variables: { search: value, pageSize: 4 } });
            searchResults.value = data?.connection?.nodes || [];
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        } finally {
          isFetching.value = false;
          if (!value.length) {
            searchResults.value = [];
          }
        }
      }, 300)
    );

    function navigateTo(to: string) {
      isOpen.value = false;
      redirect(`/${to}`);
    }

    return {
      searchBy,
      searchResults,
      isOpen,
      navigateToSearchPage,
      openSearchMenu,
      navigateTo,
      isFetching,
      blackFridayThemeEnabled,
    };
  },
});
