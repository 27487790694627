import { decodeBase64, encodeBase64 } from './base64';
import { OfferCardFragment } from '~/graphql/fragments';

export const ELITE_STORE = 'eliteStore';
export const B2C_VIEW = 'isB2cView';

const disallowedPaths: Array<RegExp> = [
  // disable access to the home page
  /^\/(en|ar)?\/?$/,
];

// make sure that the incoming path is valid by allowing only specific paths to be accessed
export const isValidPath = (path: string): boolean => {
  return !disallowedPaths.some(disallowedPath => {
    return disallowedPath.test(path);
  });
};

export type extendedOffer = OfferCardFragment & { href: string };

export const offerToUrl = (offer: extendedOffer): string => {
  return `/elite-offer?q=${encodeBase64(offer)}`;
};

export const queryToOffer = (url: string): extendedOffer => {
  return decodeBase64(url);
};
