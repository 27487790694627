import { onBeforeUnmount, onMounted, ref, getCurrentInstance } from '@nuxtjs/composition-api';

export function useDropdown() {
  const isOpen = ref(false);
  function toggle(force?: Event | boolean) {
    if (typeof force === 'boolean') {
      isOpen.value = force;
      return;
    }

    isOpen.value = !isOpen.value;
  }

  const instance = getCurrentInstance()?.proxy;
  function onClick(e: MouseEvent) {
    if (instance?.$el.contains(e.target as any)) {
      return;
    }

    toggle(false);
  }

  onMounted(() => {
    window.addEventListener('click', onClick);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('click', onClick);
  });

  return {
    isOpen,
    toggle,
  };
}
