









import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useEvent } from '~/features/events';

export default defineComponent({
  setup() {
    const displayPaymentPlans = ref(false);
    const installmentPlans = ref<Array<any>>([]);
    const price = ref(0);

    useEvent('TOGGLE_PAYMENT_PLANS', ({ plans, totalPrice }: { plans: Array<any>; totalPrice: number }) => {
      displayPaymentPlans.value = true;
      installmentPlans.value = plans;
      price.value = totalPrice;
    });

    return {
      displayPaymentPlans,
      installmentPlans,
      price,
    };
  },
});
