import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { getUser } from '~/features/auth';
import { isGuestUserVerified } from '~/features/cart';

export default defineNuxtMiddleware(async function AuthMiddleware({ redirect, app: { i18n, router }, res }) {
  const user = await getUser();
  const isGuest = router?.currentRoute.query.guest === 'true';
  const guestUserVerified = isGuest ? await isGuestUserVerified() : false;
  const locale = (i18n as VueI18n).locale;
  // Set cookie for guest user to use it in thank you pages for 1 hour
  if (guestUserVerified) {
    if (process.browser) {
      document.cookie = `guest=${guestUserVerified}; Max-Age=3600; Path=/; SameSite=Strict`;
    } else {
      res.setHeader('Set-Cookie', [`guest=${guestUserVerified}; Max-Age=3600; Path=/; SameSite=Strict`]);
    }
  }

  if (!user && !isGuest) {
    redirect(`/${locale}/login`);
  }

  if (!guestUserVerified && isGuest) {
    return redirect(`/${locale}/checkout/shipping?guest=true`);
  }
});
