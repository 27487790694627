











































































import { computed, defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { useI18n } from '../features/i18n';
import { useCartAttributes } from '@/features/cart';
import { useEventBus } from '~/features/events';
import { useAuth } from '~/features/auth';
import { useFetchFeatureFlags, useStoreConfig } from '~/features/storeConfig';
import { TRACKING_EVENTS } from '~/features/trackingHandlers';
import { useFlashSale } from '~/features/flashsale';
import useCookies from '~/features/cookies';
import { ELITE_STORE } from '~/features/elite';

export default defineComponent({
  name: 'TheHeader',
  setup() {
    const { isLoggedIn, user } = useAuth();
    const { count } = useCartAttributes();
    const { emit } = useEventBus();
    const { cookies } = useCookies();
    const { headerPromotedLabel, blackFridayThemeEnabled } = useStoreConfig();
    const { getLocale } = useI18n();
    const { flashSale } = useFlashSale();
    const { featureFlags } = useFetchFeatureFlags();

    const isFlagMenuOpen = ref(false);

    function toggleFlagMenu() {
      isFlagMenuOpen.value = !isFlagMenuOpen.value;
    }

    function openAsideCart() {
      emit('TOGGLE_ASIDE_CART', true);
    }

    function openSideMenu() {
      emit('TOGGLE_SIDE_MENU', true);
    }

    function trackLocaleSwitch() {
      emit(TRACKING_EVENTS.LANGUAGE_SELECTED, {
        language: getLocale() === 'en' ? 'ar' : 'en',
      });
    }

    const isElite = computed(() => {
      return cookies[ELITE_STORE];
    });

    /**
     * uses the navigator useAgent to detect the current OS
     */
    function getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
      }

      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
      }

      return 'unknown';
    }

    const mobileBannerUrl = ref('');

    onMounted(() => {
      const mobileOperatingSystem = getMobileOperatingSystem();

      // if no mobileBanner stored -> then it hasnt been displayed before within current session
      // save it and set ref to display banner
      if (!sessionStorage.getItem('mobileBanner')) {
        if (mobileOperatingSystem === 'Android') {
          mobileBannerUrl.value = 'https://rayashopapp.page.link/googleplay';
        } else if (mobileOperatingSystem === 'iOS') {
          mobileBannerUrl.value = 'https://rayashopapp.page.link/appstore';
        }
        sessionStorage.setItem('mobileBanner', mobileBannerUrl.value);
      } else {
        mobileBannerUrl.value = '';
      }
    });

    return {
      isLoggedIn,
      user,
      cartCount: count,
      openAsideCart,
      openSideMenu,
      headerPromotedLabel,
      blackFridayThemeEnabled,
      trackLocaleSwitch,
      flashSale,
      mobileBannerUrl,
      featureFlags,
      toggleFlagMenu,
      isFlagMenuOpen,
      isElite,
    };
  },
});
