





























































































import { defineComponent, watch, useContext, computed } from '@nuxtjs/composition-api';
import { useDropdown } from '~/features/dropdown';
import { useAuth, useLogout } from '~/features/auth';
import { useI18n } from '~/features/i18n';
import { useAlerts } from '~/features/alerts';
import useCookies from '~/features/cookies';
import { ELITE_STORE } from '~/features/elite';

export default defineComponent({
  name: 'ProfileDropdown',
  setup() {
    const { isOpen, toggle } = useDropdown();
    const { isLoggedIn, user } = useAuth();
    const { route } = useContext();
    const { logout } = useLogout();
    const { success } = useAlerts();
    const { cookies } = useCookies();

    const { t } = useI18n();

    // close menu when navigating
    watch(route, () => {
      toggle(false);
    });

    /**
     * Used to logout and go back to the home page
     */
    function loggingOut() {
      logout();
      toggle(false);
      success(t('loggedOut') as string);
    }

    const isElite = computed(() => {
      return cookies[ELITE_STORE];
    });
    return {
      isOpen,
      toggle,
      isLoggedIn,
      user,
      loggingOut,
      isElite,
    };
  },
});
