import type { ClientPlugin } from 'villus';
import { clone } from 'lodash-es';
import { normalizeQuery } from './villus';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exclude = [
  // Skips all mutations
  /mutation/,
  /query Identity/,
  /query GetPhoneNumberByEmail/,
  /query RequestToVerifyId /,
  /query ActiveCampaign /,
  /query EliteHome/,
  /PaymentMethods/,
  /query Contests/,
  /query TriviaContests/,
  /query GetUserContestPrize/,
  /query MatchResults/,
  /query GetFlipAndWinActiveCampaign/,
  /Cart/i,
  /SelectedAddress/,
  /Order/i,
  /Customer/i,
  /Wishlist/i,
  /StockSource/i,
  /StockSources/i,
  /deliveryDateAndTime/i,
  /NextDeliveryInterval/i,
  /CustomerCart/i,
  /GetInstallmentsMPGSData/i,
  /Flashsale/i,
  /GetInstallmentsAcceptUrl/i,
];

export function varnishPlugin(locale: string, routeFullPath: string): ClientPlugin {
  return function varnishPlugin({ opContext, operation }) {
    if (operation.type === 'query') {
      const normalizedQuery = normalizeQuery(operation.query)?.replace(/\n|\r/g, '') || '';
      const headers = clone(opContext.headers);
      // const corporateId = headers.corporateId;
      const SourceCode = headers.SourceCode;
      // do not stringify the search query
      if (normalizedQuery.startsWith('query SearchProductsDetails')) {
        return;
      }
      if (locale === 'en') {
        headers.store = locale;
      }

      /**
       * adding custom headers to control the returned aggregations per page
       */
      if (normalizedQuery.startsWith('query DiscountedProducts')) {
        headers.productsPage = 'deals';
      } else if (normalizedQuery.startsWith('query CustomDeals')) {
        headers.productsPage = 'custom-deals';
      } else if (
        normalizedQuery.startsWith('query Products') &&
        routeFullPath.includes('brands') &&
        operation.variables?.filter?.product_brand_id?.eq
      ) {
        headers.productsPage = 'brand_' + operation.variables?.filter?.product_brand_id?.eq;
      } else if (normalizedQuery.startsWith('query Products') && operation.variables?.filter?.sku?.in?.length) {
        // search page will always contain only a filter by sku
        headers.productsPage = 'search-result';
      } else if (normalizedQuery.startsWith('query Products') && operation.variables?.filter?.category_uid) {
        headers.productsPage = 'category_' + operation.variables?.filter?.category_uid?.eq;
      }

      if (!exclude.some(r => r.test(normalizedQuery))) delete headers.authorization;
      opContext.method = 'GET';
      opContext.url =
        opContext.url +
        `?query=${encodeURIComponent(normalizedQuery)}&variables=${encodeURIComponent(
          JSON.stringify(operation.variables)?.replace(/\n|\r/g, '')
        )}&storeCode=${locale}${SourceCode === 'elite' ? `&sourcecode=elite` : ``}`;

      opContext.headers = headers;

      opContext.body = undefined;
    }
  };
}
