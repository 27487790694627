





import { computed, defineComponent, inject, ref, watch } from '@nuxtjs/composition-api';
import { useI18n } from '~/features/i18n';
import { CONFIG_STORE_CURRENCY } from '~/utils/provides';

export default defineComponent({
  name: 'Money',
  props: {
    value: {
      type: null,
      required: true,
    },
    as: {
      type: String,
      default: 'span',
    },
    fallback: {
      type: String,
      default: undefined,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const currencyCode = inject(CONFIG_STORE_CURRENCY);
    const innerValue = ref(props.value);
    watch(
      () => props.value,
      newValue => {
        innerValue.value = newValue;
      }
    );
    const { t, n } = useI18n();

    return {
      currency: currencyCode?.value || '',
      currencyValue: computed(() => {
        if (innerValue.value === 0) {
          if (props.fallback) return props.fallback;
          return t('free');
        }

        if (innerValue.value) {
          if (props.rounded) {
            return n(Math.round(innerValue.value), 'decimal').replace(/\D00$/, '');
          }

          return n(innerValue.value, 'decimal');
        }

        return t('toBeCalculated');
      }),
    };
  },
});
