










import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { useI18n } from '@/features/i18n';

export default defineComponent({
  layout: 'default',

  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  setup({ error }) {
    const { query } = useContext();
    const { t } = useI18n();
    const message = computed(() => {
      const qMsg = query.value.msg as string | null;
      if (qMsg && qMsg in (t('appErrors') as any)) {
        return t(`appErrors.${qMsg}`);
      }

      if (error.message in (t('appErrors') as any)) {
        return t(`appErrors.${error.message}`);
      }

      if (error.statusCode in (t('appErrors') as any)) {
        return t(`httpErrors.${error.statusCode}`);
      }

      return t('genericMessage');
    });

    return {
      message,
    };
  },
});
