
























import { defineComponent } from '@vue/composition-api';
import Minimal from './minimal.vue';

export default defineComponent({
  name: 'ResetPassword',

  components: {
    Minimal,
  },
  setup() {},
});
