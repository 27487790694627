import type { ClientPlugin } from 'villus';
import { useContext } from '@nuxtjs/composition-api';
import { makeHttpJSONRequest } from '~/server/utils';
import { setUser, removeTokens, REFRESH_TOKEN_COOKIE_NAME, TOKEN_COOKIE_NAME } from '~/features/auth';
import { useContextCookies } from '~/features/cookies';

const RefreshTokenDocument = `
  mutation RefreshToken($refreshToken: String!) {
    response: refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
      refresh_token_expires_at
      token_expires_at
    }
  }
`;

/**
 * villus client plugin to refresh token when expired
 */
let PENDING_REFRESH_TOKEN: Promise<any> | null | 'finalized' = null;

export function refreshAuthPlugin(): ClientPlugin {
  const { $config, res, req } = useContext();
  return async function refreshAuthPlugin({ opContext }) {
    const { cookies, setCookie } = useContextCookies(req, res);

    let authToken = cookies.authToken;

    if (
      cookies.refreshToken &&
      !cookies.authToken &&
      ((process.server && !PENDING_REFRESH_TOKEN) || !PENDING_REFRESH_TOKEN)
    ) {
      // eslint-disable-next-line no-async-promise-executor
      PENDING_REFRESH_TOKEN = new Promise(async function (resolve: any) {
        try {
          const { data, errors } = await makeHttpJSONRequest(
            'post',
            $config.apiURL,
            {
              query: RefreshTokenDocument,
              variables: {
                refreshToken: cookies.refreshToken,
              },
            },
            {}
          );

          if (errors) {
            throw new Error(errors[0].message);
          }

          if (data?.response?.token && data?.response?.refreshToken) {
            setCookie(TOKEN_COOKIE_NAME, data.response.token, {
              expires: new Date(Number(data.response.token_expires_at) * 1000),
            });
            setCookie(REFRESH_TOKEN_COOKIE_NAME, data.response.refreshToken, {
              expires: new Date(Number(data.response.refresh_token_expires_at) * 1000),
            });
            authToken = data.response.token;
          }

          resolve(data);
        } catch (err) {
          // if (/The current customer isn't authorized/.test((err as any)?.message)) {
          setUser(null);
          // }
          removeTokens();
          resolve(null);
        }
      });

      await PENDING_REFRESH_TOKEN;
    } else {
      const data = await PENDING_REFRESH_TOKEN;
      if (data?.response?.token && data?.response?.refreshToken) {
        authToken = data.response.token;
      }
    }
    if (authToken) (opContext.headers as Record<string, any>).authorization = `Bearer ${authToken}`;
    if (process.server) {
      PENDING_REFRESH_TOKEN = 'finalized';
      return;
    }

    PENDING_REFRESH_TOKEN = null;
  };
}
