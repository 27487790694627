


























































































































































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { CombinedError } from 'villus';
import { useSubscribeToNewsletter } from '~/features/contact';
import { useAlerts } from '~/features/alerts';
import { useI18n } from '~/features/i18n';
import { useFetchFeatureFlags } from '~/features/storeConfig';
import { useAuth } from '~/features/auth';

export default defineComponent({
  components: {
    ValidationObserver,
  },
  setup() {
    const { submitForm, input } = useSubscribeToNewsletter();
    const { success, error } = useAlerts();
    const { getLocale } = useI18n();
    const { featureFlags } = useFetchFeatureFlags();
    const { isLoggedIn } = useAuth();

    const payBillsEnabled = computed(() => featureFlags.value.utilities?.enabled);

    async function onSubmit() {
      try {
        await submitForm();
        success('success', `subscribed to ${input.email} `);
      } catch (e) {
        if (e instanceof CombinedError) {
          error('error', e.message);
        }
      }
    }

    return {
      onSubmit,
      input,
      getLocale,
      featureFlags,
      isLoggedIn,
      payBillsEnabled,
    };
  },
});
