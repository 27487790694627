import { computed, provide, reactive, ref, ssrRef, useAsync, useContext } from '@nuxtjs/composition-api';
import { CombinedError, useMutation, useQuery } from 'villus';
import { v4 as uuidv4 } from 'uuid';
import { IsSocialAccountDocument, RequestResetPasswordPhoneDocument } from './../graphql/Auth';
import useCookies from './cookies';
import { clearCart, useCartAttributes } from './cart';
import { useRouter } from './router';
import { useEventBus } from './events';
import { TRACKING_EVENTS } from './trackingHandlers';
import { useI18n } from './i18n';
import { ELITE_STORE, B2C_VIEW } from './elite';
import { isEmail } from '~/utils/text';
import {
  IdentityDocument,
  IdentityQuery,
  LoginDocument,
  RegisterDocument,
  RevokeTokenDocument,
  ResetPasswordDocument,
  ResetPasswordMutationVariables,
} from '~/graphql/Auth';
import { AUTH_IS_LOGGED_IN, AUTH_USER } from '~/utils/provides';
import {
  UpdateCustomerDocument,
  UpdateCustomerMutationVariables,
  UpdateEmailDocument,
  UpdateEmailMutationVariables,
  UpdatePasswordDocument,
  UpdatePasswordMutationVariables,
} from '~/graphql/Customer';
import { keysOf } from '~/utils/collections';
import { topics, useChannels } from '~/features/channels';
import { AUTH_SOURCE } from '~/const/auth';

/* Resource handlers */
let PENDING_IDENTIFICATION: Promise<IdentityQuery['customer'] | undefined> | undefined;

/* Auth Constants */
export const REFRESH_TOKEN_COOKIE_NAME = 'refreshToken';
export const TOKEN_COOKIE_NAME = 'authToken';

/* request life type refrences */
export const user = ref<IdentityQuery['customer'] | null | undefined>(undefined);

export const isPendingAuth = ssrRef<boolean>(false);

export function setUser(data: typeof user['value'] | undefined | null) {
  user.value = data;
}

export async function getUser() {
  if (PENDING_IDENTIFICATION) {
    await PENDING_IDENTIFICATION;
  }

  if (user.value) {
    return user.value;
  }

  return null;
}

export function useAuth() {
  const { cookies, removeCookie } = useCookies();

  if (!cookies.refreshToken) {
    setUser(undefined);
    removeCookie(ELITE_STORE);
    removeCookie(B2C_VIEW);
  }
  if (cookies.refreshToken && !user.value) {
    useAsync(async () => {
      isPendingAuth.value = true;

      if (PENDING_IDENTIFICATION && !user.value) {
        await PENDING_IDENTIFICATION;
        return;
      }

      PENDING_IDENTIFICATION = useIdentity().identify();
      const fetchedUser = await PENDING_IDENTIFICATION;
      setUser(fetchedUser);
      PENDING_IDENTIFICATION = undefined;
      isPendingAuth.value = false;
    });
  }

  const isLoggedIn = computed(() => {
    return !!user.value;
  });

  provide(AUTH_USER, user);

  provide(AUTH_IS_LOGGED_IN, isLoggedIn);

  return {
    isLoggedIn,
    user,
    setUser,
  };
}

export function useLogin() {
  const { execute, isFetching } = useMutation(LoginDocument);
  const { setCookie, removeCookie } = useCookies();
  const { identify } = useIdentity();
  const { emit } = useEventBus();
  const { redirect } = useRouter();
  const { getLocale } = useI18n();

  async function login(input: { identityString: string; password: string }) {
    try {
      const { data, error } = await execute(input);

      if (error) {
        throw new Error(error.message);
      }
      if (!data?.response) {
        throw new Error('No token was generated');
      }

      setCookie(REFRESH_TOKEN_COOKIE_NAME, data?.response?.refreshToken as string, {
        expires: new Date(Number(data?.response?.refresh_token_expires_at) * 1000),
      });
      setCookie(TOKEN_COOKIE_NAME, data?.response?.token as string, {
        expires: new Date(Number(data?.response?.token_expires_at) * 1000),
      });

      const user = await identify({
        accessToken: data?.response?.token ?? '',
        ttl: Number(data?.response?.token_expires_at),
      });
      // token expired
      if (!user) {
        removeCookie(TOKEN_COOKIE_NAME);
        throw new Error('No user was authenticated with token');
      }

      emit(TRACKING_EVENTS.LOGIN, {
        first_name: user.firstname,
        last_name: user.lastname,
        email: user.email,
        phone: user.phone_number,
        language: getLocale(),
      });

      return user;
    } catch (err) {
      if (
        /\[GraphQL\] This account isn't verified. Verify by OTP and try again./.test((err as CombinedError).message) &&
        isEmail(input.identityString)
      ) {
        const randomInt = uuidv4();
        if (process.browser) {
          // store random int key with the phone number
          // to prevent using the phone number in the url
          // using random int key -> to prevent user from hitting the same url for restting password without going through full process
          localStorage.setItem(randomInt, input.identityString);
        }
        redirect('/auth/verify-account', {
          query: {
            key: randomInt,
          },
        });
        throw err;
      }
      if (
        /\[GraphQL\] This account isn't verified. Verify by OTP and try again./.test((err as CombinedError).message)
      ) {
        emit('VERIFY_OTP', { phoneNumber: input.identityString });
        throw err;
      }
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  }

  return {
    login,
    isLoggingIn: isFetching,
  };
}

export function useIdentity() {
  const { setCookie, removeCookie } = useCookies();
  const { register } = useChannels();

  const { execute, isFetching: isFetchingIdentity } = useQuery({
    query: IdentityDocument,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  async function identify(token?: { ttl: number; accessToken: string }) {
    try {
      // Only set the token if provided
      if (token) {
        setCookie(TOKEN_COOKIE_NAME, token.accessToken, {
          expires: new Date(token.ttl * 1000),
        });
      }

      const { data, error } = await execute();

      if (error) {
        throw new Error(error.message);
      }

      setUser(data?.customer ?? undefined);
      register(topics.auth.identify, data?.customer);

      return data?.customer;
    } catch (err: any) {
      removeCookie(TOKEN_COOKIE_NAME);
      // Token expired or invalid, no need to report the error
      if (/The current customer isn't authorized/.test((err as any)?.message)) {
        return;
      }
      // eslint-disable-next-line
      console.log(err);
    }
  }

  return {
    identify,
    isFetchingIdentity,
  };
}

export function useLogout() {
  const { execute: revokeToken } = useMutation(RevokeTokenDocument);
  const { redirect } = useRouter();
  const { cookies, removeCookie } = useCookies();
  const { route } = useContext();
  const { emit } = useEventBus();

  function resetUserData() {
    removeCookie(TOKEN_COOKIE_NAME);
    removeCookie(REFRESH_TOKEN_COOKIE_NAME);
    removeCookie(AUTH_SOURCE);

    removeCookie('cart');
    user.value = undefined;
    clearCart();

    if (cookies[ELITE_STORE] || cookies[B2C_VIEW]) {
      removeCookie(B2C_VIEW);
      removeCookie(ELITE_STORE);
      // force reload the page to update the layout categories and the header
      location.reload();
    }
  }

  function logout() {
    revokeToken({}); // Don't wait for this, revoke token in background
    if (!process.server) window.localStorage.removeItem('user');
    emit(TRACKING_EVENTS.LOGOUT, {});
    emit('LOGOUT');

    // redirect user to homepage if they are in the checkout flow
    // or in the account pages

    if (
      /cart/.test(route.value.fullPath) ||
      /account/.test(route.value.fullPath) ||
      /order-placed/.test(route.value.fullPath) ||
      /success/.test(route.value.fullPath) ||
      /seller-dashboard/.test(route.value.fullPath) ||
      /elite-store/.test(route.value.fullPath)
    ) {
      redirect('/', {
        // FIXME: using callbacks for now because cannot return promises at the moment
        onComplete: () => {
          resetUserData();
        },
      });
    }

    resetUserData();
  }

  return {
    logout,
  };
}

export function removeTokens() {
  if (!process.server) {
    const { removeCookie } = useCookies();
    removeCookie(REFRESH_TOKEN_COOKIE_NAME);
    removeCookie(ELITE_STORE);
    removeCookie(B2C_VIEW);
    setUser(undefined);
  }
}

/**
 * Creates a new customer
 */
export function useCreateCustomer() {
  const { execute } = useMutation(RegisterDocument);
  const { login } = useLogin();
  const { emit } = useEventBus();
  const { cartId } = useCartAttributes();

  const isFetching = ref(false);
  const customerInput = reactive({
    email: '',
    password: '',
    fullName: '',
    phone_number: '',
    dob: '',
    gender: null,
  });

  async function createCustomer() {
    try {
      isFetching.value = true;
      const input = {
        ...customerInput,
        firstname: customerInput.fullName.split(' ')[0],
        lastname: customerInput.fullName.split(' ').slice(1).join(' ') || '',
        fullName: undefined,
        cart_id: cartId.value,
      };
      const { data, error } = await execute({
        input,
      });

      emit(TRACKING_EVENTS.COMPLETE_REGISTRATION, {
        firstname: customerInput.fullName.split(' ')[0],
        lastname: customerInput.fullName.split(' ').slice(1).join(' ') || '',
        email: customerInput.email,
        phone: customerInput.phone_number,
      });

      if (error) {
        throw new Error(error.message);
      }

      if (!data?.response?.customer?.email) {
        throw new Error('No user was created');
      }

      const user = await login({
        identityString: data?.response?.customer.phone_number,
        password: customerInput.password,
      });
      if (!user) {
        throw new Error('No user was authenticated with token');
      }

      return data?.response?.customer;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      isFetching.value = false;
    }
  }
  return {
    createCustomer,
    customerInput,
    isCreatingCustomer: isFetching,
  };
}

export function useUpdateCustomer() {
  const { execute, isFetching } = useMutation(UpdateCustomerDocument);

  async function updateCustomer(input: UpdateCustomerMutationVariables['input']) {
    try {
      const { data, error } = await execute({
        input: {
          ...input,
        },
      });

      if (error) {
        throw error;
      }

      keysOf(user.value).forEach(key => {
        const old = user.value?.[key] || '';
        const newVal = data.response?.customer[key] || old;
        (user as any).value[key] = newVal;
      });

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateCustomer,
    isFetching,
  };
}

export function useUpdateCustomerPassword() {
  const { execute, isFetching } = useMutation(UpdatePasswordDocument);

  async function updatePassword(input: UpdatePasswordMutationVariables) {
    try {
      const { data, error } = await execute(input);

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updatePassword,
    isFetching,
  };
}

export function useUpdateCustomerEmail() {
  const { execute, isFetching } = useMutation(UpdateEmailDocument);

  async function updateEmail(input: UpdateEmailMutationVariables) {
    try {
      const { data, error } = await execute(input);

      if (error) {
        throw error;
      }

      setUser(data?.response?.customer ?? undefined);
      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateEmail,
    isFetching,
  };
}

export function useResetPassword() {
  const { execute, isFetching } = useMutation(ResetPasswordDocument);

  async function resetPassword(input: ResetPasswordMutationVariables) {
    try {
      const { data, error } = await execute(input);

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    resetPassword,
    isFetching,
  };
}

export function useCleanAuth() {
  user.value = undefined;
}

export function useRequestPasswordResetPhone() {
  const { execute: executeRequestResetPassword, isFetching: isFetchingRequestResetPassword } = useMutation(
    RequestResetPasswordPhoneDocument
  );

  const { execute: executeResetPassword, isFetching: isFetchingResetPassword } = useMutation(ResetPasswordDocument);

  const input = reactive({
    identityString: '',
    otp: '',
    password: '',
  });

  async function requestPasswordReset(identityString?: string) {
    try {
      const { data, error } = await executeRequestResetPassword({
        identityString: identityString ?? input.identityString,
      });

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  async function resetPassword() {
    try {
      const { data, error } = await executeResetPassword({
        identityString: input.identityString,
        token: input.otp,
        newPassword: input.password,
      });

      if (error) {
        throw error;
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    input,
    requestPasswordReset,
    resetPassword,
    isFetchingRequestResetPassword,
    isFetchingResetPassword,
  };
}

export function useIsSocialAccount() {
  const { execute } = useQuery({
    query: IsSocialAccountDocument,
    fetchOnMount: false,
  });

  return {
    execute,
  };
}
