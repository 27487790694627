import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { useCheckableItems } from '~/features/cart';

export default defineNuxtMiddleware(function checkoutMiddleware({ redirect, app: { i18n } }) {
  const { isCheckable } = useCheckableItems();

  if (!isCheckable.value) {
    redirect(`/${(i18n as VueI18n).locale}/`);
  }
});
