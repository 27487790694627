import { reactive, ref, watch, ComputedRef } from '@nuxtjs/composition-api';
import { debounce } from 'lodash';
import { CombinedError, useMutation } from 'villus';
import useCookies from './cookies';
import { REFRESH_TOKEN_COOKIE_NAME, TOKEN_COOKIE_NAME, useIdentity } from './auth';
import {
  ResendVerficationCodeDocument,
  VerifyPhoneNumberDocument,
  EditCustomerPhoneNumberDocument,
  SendOtpToGuestUserDocument,
  VerifyGuestUserOtpDocument,
} from '~/graphql/Auth';
import { MaybeReactive } from '~/types/utils';

export function usePhoneVerification() {
  const phoneVerification = ref('');
  const input = reactive({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
  });

  watch(input, inputValue => {
    inputValue.input1 = inputValue.input1.replace(/[^0-9]/g, '');
    inputValue.input2 = inputValue.input2.replace(/[^0-9]/g, '');
    inputValue.input3 = inputValue.input3.replace(/[^0-9]/g, '');
    inputValue.input4 = inputValue.input4.replace(/[^0-9]/g, '');
  });

  watch(
    input,
    debounce(inputValue => {
      if (inputValue.input1 && inputValue.input2 && inputValue.input3 && inputValue.input4)
        phoneVerification.value = `${inputValue.input1}${inputValue.input2}${inputValue.input3}${inputValue.input4}`;
    }, 400)
  );

  return { input, phoneVerification };
}

export function useVerifyPhoneNumber(phoneNumber: MaybeReactive<string>) {
  const { execute, isFetching } = useMutation(VerifyPhoneNumberDocument);
  const { setCookie } = useCookies();
  const { identify } = useIdentity();

  async function verifyPhoneNumber(code: string) {
    try {
      const number: string = (phoneNumber as ComputedRef<string>).value
        ? (phoneNumber as ComputedRef<string>).value
        : (phoneNumber as string);
      const { error, data } = await execute({ code, phoneNumber: number });
      if (error) throw error;

      setCookie(REFRESH_TOKEN_COOKIE_NAME, data?.response?.refreshToken as string, {
        expires: new Date(Number(data?.response?.refresh_token_expires_at) * 1000),
      });
      setCookie(TOKEN_COOKIE_NAME, data?.response?.token as string, {
        expires: new Date(Number(data?.response?.token_expires_at) * 1000),
      });

      const user = await identify({
        accessToken: data?.response?.token || '',
        ttl: Number(data?.response?.token_expires_at) || 0,
      });

      if (!user) {
        throw new Error('No user was authenticated with token');
      }

      return user;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as CombinedError).message);
      throw e;
    }
  }

  return {
    verifyPhoneNumber,
    isFetching,
  };
}

export function useSendVerficationCode(phoneNumber: string) {
  const { execute, isFetching } = useMutation(ResendVerficationCodeDocument);

  async function sendVerficationCode() {
    try {
      const { error } = await execute({ phoneNumber, phoneOnly: true });
      if (error) throw error;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as CombinedError).message);
      throw e;
    }
  }

  async function reSendVerficationCode(phoneNumber: string) {
    const { error } = await execute({ phoneNumber, phoneOnly: true });
    if (error) throw error;
  }

  return {
    sendVerficationCode,
    reSendVerficationCode,
    isFetching,
  };
}

export function useEditPhoneNumber() {
  const { execute, isFetching: isEditingCustomerPhone } = useMutation(EditCustomerPhoneNumberDocument);

  async function editPhoneNumber(oldPhoneNumber: string, phoneNumber: string) {
    try {
      const { error } = await execute({ old: oldPhoneNumber, new: phoneNumber });
      if (error) throw error;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as CombinedError).message);
      throw e;
    }
  }

  return {
    editPhoneNumber,
    isEditingCustomerPhone,
  };
}

export function useSendOtpToGuestUser() {
  const { execute, isFetching } = useMutation(SendOtpToGuestUserDocument);
  const { cookies } = useCookies();

  async function sendOtpToGuestUser() {
    const cartId = cookies.cart;
    try {
      const { data, error } = await execute({ cartId });
      if (error) throw error;
      return {
        otpSent: data?.response?.otp_sent,
        message: data?.response?.message,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as CombinedError).message);
      throw e;
    }
  }

  return {
    sendOtpToGuestUser,
    isFetching,
  };
}

export function useVerifyGuestUserOtp() {
  const { execute, isFetching } = useMutation(VerifyGuestUserOtpDocument);
  const { cookies } = useCookies();

  async function verifyGuestUserOtp(otp: string) {
    const cartId = cookies.cart;
    try {
      const { data, error } = await execute({ cartId, otp });
      if (error) throw error;
      return { success: data?.response?.success };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log((e as CombinedError).message);
      throw e;
    }
  }

  return {
    verifyGuestUserOtp,
    isFetching,
  };
}
