import { computed, ComputedRef, ref, watch } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
// import { uniqBy } from 'lodash-es';
import { isAfter, isBefore } from 'date-fns';
import { useQuery } from 'villus';
import { has } from 'lodash-es';
import {
  GetSearchExcludedFacetsDocument,
  GetTopPicksDocument,
  SearchProductsDocument,
  SearchProductsQueryVariables,
} from '../graphql/Product';
import { ProductsCompareDocument, CustomDealsProductsDocument } from './../graphql/Product';
import { installmentAmountPerMonth } from './installments';
import { useGetEliteInfo } from './elite';
import {
  ProductsQueryVariables,
  ProductsDocument,
  DiscountedProductsDocument,
  DiscountedProductsQueryVariables,
} from '~/graphql/Product';
import { MaybeReactive } from '~/types/utils';
import { toNonNullable } from '~/utils/collections';
import { resolveProductStock } from '~/utils/product';
import { ProductCardFragment } from '~/graphql/fragments';
import { CustomAttribute } from '~/graphql-types.gen';
import { getSearchResults, mapSearchProductItem } from '~/utils/search';
import { BestSellersDocument } from '~/graphql/Config';

export type ProductNodes = Array<ReturnType<typeof mapProductListing>>;

type ProductsReducer = (old: ProductNodes, curr: ProductNodes) => ProductNodes;
export type ProductListingType = 'default' | 'discounted' | 'search' | 'customDiscount';

const replaceReducer: ProductsReducer = (_, curr) => {
  return curr;
};

export const appendReducer: ProductsReducer = (old, curr) => {
  return [...old, ...curr];
  // return uniqBy([...old, ...curr], 'id');
};

interface Options {
  fetchOnMount: boolean;
}

export function useProducts(variables?: MaybeReactive<ProductsQueryVariables>, opts?: Partial<Options>) {
  const products: Ref<ProductNodes> = ref([]);
  const totalCount = ref(0);
  const { attachedCorporate } = useGetEliteInfo();

  const { data, error, isFetching } = useQuery({
    query: ProductsDocument,
    variables: computed(() => ({
      ...extractValueFromReactive(variables),
      corporateId: attachedCorporate?.value?.id,
    })),
    fetchOnMount: opts?.fetchOnMount ?? true,
  });

  watch(data, value => {
    const fetchedPage = data.value?.connection?.page_info?.current_page;
    const reducer = fetchedPage === 1 || !fetchedPage ? replaceReducer : appendReducer;
    products.value = reducer(products.value, toNonNullable(value?.connection?.nodes).map(mapProductListing as any));
    totalCount.value = value?.connection?.total_count || 0;
  });

  return {
    products,
    totalCount,
    data,
    error,
    isFetching,
  };
}

function extractValueFromReactive<T>(value: MaybeReactive<T>): T {
  if (has(value, 'value')) {
    return (value as Ref<T> | ComputedRef<T>).value;
  }
  return value as T;
}

export function useCompareProducts(variables?: MaybeReactive<ProductsQueryVariables>, opts?: Partial<Options>) {
  const products: Ref<ProductNodes> = ref([]);
  const totalCount = ref(0);
  const { attachedCorporate } = useGetEliteInfo();

  const { data, error, isFetching } = useQuery({
    query: ProductsCompareDocument,
    variables: computed(() => ({ ...extractValueFromReactive(variables), corporateId: attachedCorporate?.value?.id })),
    fetchOnMount: opts?.fetchOnMount ?? true,
  });

  watch(data, value => {
    const fetchedPage = data.value?.connection?.page_info?.current_page;
    const reducer = fetchedPage === 1 || !fetchedPage ? replaceReducer : appendReducer;
    products.value = reducer(products.value, toNonNullable(value?.connection?.nodes).map(mapProductListing as any));
    totalCount.value = value?.connection?.total_count || 0;
  });

  return {
    products,
    totalCount,
    data,
    error,
    isFetching,
  };
}

// handles the search feature
export function useSearchProducts(variables?: MaybeReactive<SearchProductsQueryVariables>, opts?: Partial<Options>) {
  const { attachedCorporate } = useGetEliteInfo();
  const products: Ref<any> = ref([]);
  const { data, error, isFetching, execute } = useQuery({
    query: SearchProductsDocument,
    variables: { ...extractValueFromReactive(variables), corporateId: attachedCorporate?.value?.id },
    fetchOnMount: opts?.fetchOnMount ?? true,
  });

  watch(data, value => {
    return (products.value = value?.connection?.nodes);
  });

  return {
    products,
    isFetching,
    error,
    execute,
  };
}

/**
 * handles fetching the search results from searchanise
 * @param searchValue search phrase entered by the user
 * @param startPaginationIndex index for pagination
 * @param locale current locale
 * @returns { items, currentItemCount, startIndex, totalItems, facets}
 */
export async function useFetchSearchData(
  searchValue?: string,
  startPaginationIndex?: number,
  locale?: string,
  restrictAttributes?: string[],
  restrictValues?: any[],
  sortBy?: string,
  sortOrder?: string
) {
  const pending = getSearchResults(
    computed(() => ({
      maxResults: 20,
      query: searchValue,
      locale: locale || 'ar',
      startIndex: startPaginationIndex || 0,
      restrictAttributes: restrictAttributes?.length ? [...restrictAttributes] : [],
      restrictValues: restrictValues?.length ? [...restrictValues] : [],
      sortBy,
      sortOrder,
    }))
  );

  const { items, currentItemCount, startIndex, totalItems, facets } = await pending;
  return {
    items: items?.map((item: any) => mapSearchProductItem(item)) || [],
    currentItemCount,
    startIndex,
    totalItems,
    facets,
  };
}

/**
 * A generic products listing function that fetched different product queries according to the type passed
 * @param variables
 * @param type type of the query to be fetched : default or discounted or search
 * @returns
 */
export function useProductsGeneric(
  variables?: ComputedRef<Partial<ProductsQueryVariables>>,
  type: ProductListingType = 'default'
) {
  const products: Ref<ProductNodes> = ref([]);
  const totalCount = ref(0);

  const { data, error, isFetching, execute } = useQuery({
    query: resolveProductTypeQuery(type),
    variables,
    fetchOnMount: true,
  });

  watch(data, value => {
    const fetchedPage = value?.connection?.page_info?.current_page;
    const reducer = !fetchedPage || fetchedPage <= 1 ? replaceReducer : appendReducer;

    products.value = reducer(products.value, toNonNullable(value?.connection?.nodes).map(mapProductListing as any));
    totalCount.value = value?.connection?.total_count || 0;
  });

  return {
    products,
    totalCount,
    data,
    error,
    isFetching,
    fetchProducts: execute,
  };
}

export function useDiscountedProducts(
  variables?: MaybeReactive<DiscountedProductsQueryVariables>,
  opts?: Partial<Options>
) {
  const products: Ref<ProductNodes> = ref([]);
  const totalCount = ref(0);
  const { attachedCorporate } = useGetEliteInfo();

  const { data, error, isFetching } = useQuery({
    query: DiscountedProductsDocument,
    variables: { ...extractValueFromReactive(variables), corporateId: attachedCorporate?.value?.id },
    fetchOnMount: opts?.fetchOnMount ?? true,
  });

  watch(data, value => {
    const fetchedPage = data.value?.connection?.page_info?.current_page;
    const reducer = fetchedPage === 1 || !fetchedPage ? replaceReducer : appendReducer;
    products.value = reducer(products.value, toNonNullable(value?.connection?.nodes).map(mapProductListing as any));
    totalCount.value = value?.connection?.total_count || 0;
  });

  return {
    products,
    totalCount,
    data,
    error,
    isFetching,
  };
}

export function useBestSellers() {
  const products: Ref<ProductNodes> = ref([]);
  const { attachedCorporate } = useGetEliteInfo();

  const { data, isFetching } = useQuery({
    query: BestSellersDocument,
    variables: {
      corporateId: attachedCorporate?.value?.id,
      sellerIds: attachedCorporate.value?.assignedSellerIds ?? [],
    },
    fetchOnMount: true,
  });

  watch(data, value => {
    products.value = toNonNullable(value?.bestSellers?.bestsellers?.items).map(mapProductListing as any);
  });

  return {
    products,
    isFetching,
  };
}

export function useGetExcludedFacets() {
  const { attachedCorporate } = useGetEliteInfo();
  const { execute } = useQuery({
    query: GetSearchExcludedFacetsDocument,
    fetchOnMount: false,
    variables: { corporateId: attachedCorporate?.value?.id },
  });

  return { fetchExcludedSearchFacets: execute };
}
/**
 * Maps a product listing to props compatible with the card component.
 */
export function mapProductListing(apiProduct: ProductCardFragment) {
  const newFrom = new Date(apiProduct.new_from_date || 0);
  const newTo = new Date(apiProduct.new_to_date || 0);
  const now = new Date();
  const isNew = isAfter(newTo, now) && isBefore(newFrom, now);
  const currentPrice =
    apiProduct.__typename === 'ConfigurableProduct'
      ? apiProduct.price_range.minimum_price?.final_price.value ?? 0
      : apiProduct.price_range.maximum_price?.final_price.value ?? 0;
  const oldPrice =
    apiProduct.__typename === 'ConfigurableProduct'
      ? apiProduct.price_range.minimum_price?.regular_price.value ?? currentPrice
      : apiProduct.price_range.maximum_price?.regular_price.value ?? currentPrice;

  // plan with the highest number of months
  const highestInstallmentPlan = apiProduct.installments?.installment_data
    ?.find(banks => banks?.method_name === 'Raya')
    ?.plans?.reduce(
      (accu, curr) => {
        return (curr?.months || Number.MIN_SAFE_INTEGER) > (accu?.months || Number.MIN_SAFE_INTEGER) ? curr : accu;
      },
      { months: Number.MIN_SAFE_INTEGER }
    );

  // highest number of months
  const highestInstallmentMonths = highestInstallmentPlan?.months || 0;
  // extracting the plan with the highest number of months
  const highestPlanData = apiProduct.installments?.installment_data
    ?.find(banks => banks?.method_name === 'Raya')
    ?.plans?.find(plan => plan?.months === highestInstallmentMonths);
  // smallest amount payable per month
  const smallestAmount = installmentAmountPerMonth(
    currentPrice,
    highestPlanData?.interest || 0,
    highestPlanData?.down_payment || 0,
    highestInstallmentMonths
  );

  return {
    id: apiProduct.id,
    name: apiProduct.name,
    sku: apiProduct.sku,
    isNew,
    stock: resolveProductStock(apiProduct),
    price: currentPrice,
    priceBefore: currentPrice < oldPrice ? oldPrice : undefined,
    slug: apiProduct.url_key,
    thumb: {
      src: apiProduct.thumbnail?.url,
      alt: apiProduct.thumbnail?.label || apiProduct.name,
    },
    categories: apiProduct.categories,
    brand: apiProduct.brand,
    // attributes: (apiProduct as any).attributes as CustomAttribute[] | undefined,
    type: apiProduct.__typename,
    smallestAmount,
    highestNumberOfMonths: highestInstallmentMonths,
    averageRating: apiProduct.overallRating?.overallRating,
    bestSeller: apiProduct.is_best_seller,

    attributes: (apiProduct as any).attributes as CustomAttribute[] | undefined,
    customLabel: apiProduct.custom_label_1,
    customLabel2: apiProduct.custom_label_2,
    seller: apiProduct.seller?.email,
    /** indicates if the product is a bundle product and at the same time a component not a standalone product
     * used to remove the bundle takes from these products if isAC is true
     */
    // isAC: apiProduct.is_air_condition,
  };
}

export function resolveProductTypeQuery(type: ProductListingType) {
  if (type === 'discounted') {
    return DiscountedProductsDocument;
  }
  if (type === 'customDiscount') {
    return CustomDealsProductsDocument;
  }
  if (type === 'search') {
    return SearchProductsDocument;
  }
  if (type === 'default') {
    return ProductsDocument;
  }
  return ProductsDocument;
}

export function useTopPicks(keyword: string) {
  const { data, isFetching } = useQuery({
    query: GetTopPicksDocument,
    variables: {
      keyword,
    },
  });

  return {
    sliderTitle: computed(() => data.value?.getSliderProducts.slider.title || ''),
    topPicks: computed(() => data.value?.getSliderProducts.products.map(mapProductListing as any) || []),
    isFetching,
  };
}
