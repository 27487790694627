import * as Types from '../graphql-types.gen';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SpinTheWheelMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SpinTheWheelMutation = { __typename?: 'Mutation', response: { __typename?: 'WheelResult', id: number, couponCode: string } };

export type ActiveCampaignQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ActiveCampaignQuery = { __typename?: 'Query', response: { __typename?: 'Campaign', description?: string | null | undefined, id: number, name: string, title?: string | null | undefined, userWon: boolean, segments: Array<{ __typename?: 'Segment', id: number, name: string, title?: string | null | undefined, description?: string | null | undefined, discountPercentage: string } | null | undefined> } };

export declare const SpinTheWheel: import("graphql").DocumentNode;
export declare const ActiveCampaign: import("graphql").DocumentNode;

export const SpinTheWheelDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SpinTheWheel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"spinTheWheel"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"couponCode"},"name":{"kind":"Name","value":"coupon_code"}}]}}]}}]} as unknown as DocumentNode<SpinTheWheelMutation, SpinTheWheelMutationVariables>;
export const ActiveCampaignDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ActiveCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"response"},"name":{"kind":"Name","value":"activeCampaign"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","alias":{"kind":"Name","value":"userWon"},"name":{"kind":"Name","value":"user_won"}},{"kind":"Field","name":{"kind":"Name","value":"segments"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","alias":{"kind":"Name","value":"discountPercentage"},"name":{"kind":"Name","value":"discount_percentage"}}]}}]}}]}}]} as unknown as DocumentNode<ActiveCampaignQuery, ActiveCampaignQueryVariables>;