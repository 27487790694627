import { Plugin } from '@nuxt/types';

const ALLOWED_FORMATS = ['webp', 'jpg', 'jpeg', 'png'];
type ImageFormat = 'webp' | 'jpeg' | 'jpg' | 'png';

interface ImageSource {
  srcset: string;
  type: string;
}

interface ImageApi {
  all(url: string): ImageSource[];
}

/**
 * Updates the URL of images to generate optimized image sources object
 */
const ImageLink: Plugin = ({ $config }, inject) => {
  const baseURL = $config.imageServiceURL;

  function generateOptimizedFormatURL(url: string, format: ImageFormat) {
    if (!ALLOWED_FORMATS.includes(format)) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(`Cannot generate optimized URL for image with format ${format}`);
      }

      return url;
    }

    try {
      const parsedURL = new URL(url);
      const imageURL = `${baseURL}/filters:format(${format})${parsedURL.pathname.replace('/media/', '/')}`;

      return imageURL;
    } catch (err) {
      return url;
    }
  }

  function generateResizedURL(url: string, width: number, height: number) {
    try {
      const parsedURL = new URL(url);
      const imageURL = `${baseURL}/fit-in/${width}x${height}${parsedURL.pathname.replace('/media/', '/')}`;

      return imageURL;
    } catch (err) {
      return url;
    }
  }

  /**
   * Generates optimized JPG URLs
   */
  function jpg(url: string) {
    return generateOptimizedFormatURL(url, 'jpeg');
  }

  /**
   * Generates optimized WEBP URLs
   */
  function webp(url: string) {
    return generateOptimizedFormatURL(url, 'webp');
  }

  /**
   * Generates optimized PNG URLs
   */
  function png(url: string) {
    return generateOptimizedFormatURL(url, 'png');
  }

  /**
   * Backend generated links are incorrect.
   */
  function originalURL(url: string) {
    return url && url.replace('/media/', '/');
  }

  /**
   * Filters out empty sources
   */
  function isSource(x: ImageSource | undefined): x is ImageSource {
    return Boolean(x);
  }

  /**
   * Generates optimized images compatible with a <picture /> element <source /> element
   */
  function all(url: string, formats = ['webp', 'jpg']): ImageSource[] {
    const defaultFormat = url && url.split('.').pop();

    return [
      formats.includes('webp') ? { type: 'image/webp', srcset: webp(url) } : undefined,
      formats.includes('jpg') ? { type: 'image/jpeg', srcset: jpg(url) } : undefined,
      formats.includes('png') ? { type: 'image/png', srcset: png(url) } : undefined,
      url ? { type: `image/${defaultFormat}`, srcset: originalURL(url) } : undefined,
    ].filter(isSource);
  }

  const api: ImageApi & { resize(width: number, height: number): ImageApi } = {
    all,
    resize(width, height) {
      return {
        all(url) {
          url = generateResizedURL(url, width, height);

          return all(url);
        },
      };
    },
  };

  // Inject the $imageLink helper in all components
  inject('imageLink', api);
};

export default ImageLink;
