import { defineNuxtPlugin, onGlobalSetup, useContext } from '@nuxtjs/composition-api';
import { defaultPlugins, useClient } from 'villus';
import { refreshAuthPlugin } from './refresh-token';
import { useAuth, useCleanAuth } from '~/features/auth';
import { useSetupCart } from '~/features/cart';
import { localePlugin, useI18n } from '~/features/i18n';
import { useStoreConfig } from '~/features/storeConfig';
import { useTrackingHandlers } from '~/features/trackingHandlers';
import { varnishPlugin } from '~/utils/varnish';
import { useRouter } from '~/features/router';
import { eliteCorporatePlugin } from '~/features/elite';

export default defineNuxtPlugin(() => {
  onGlobalSetup(() => {
    const { $config } = useContext();
    const { getLocale } = useI18n();
    const { route } = useRouter();
    useClient({
      url: $config.apiURL,
      use: [
        refreshAuthPlugin(),
        eliteCorporatePlugin(),
        varnishPlugin(getLocale(), route.value.fullPath),
        localePlugin(),
        ...defaultPlugins(),
      ],
    });
    useCleanAuth();
    useAuth();
    useStoreConfig();
    useSetupCart();
    useTrackingHandlers();
  });
});
