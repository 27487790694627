






























import { defineComponent, watchEffect } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SideOverlayView',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: false,
    },
  },
  setup(props, { emit }) {
    function close() {
      emit('input', false);
    }

    watchEffect(() => {
      if (process.server) {
        return;
      }

      window.document.body.classList.toggle('overflow-hidden', props.value);
    });

    return {
      close,
    };
  },
});
