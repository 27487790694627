
























import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'MobileBanner',
  props: {
    url: {
      type: String,
      default: '',
    },
    /**
     * is true if the current scroll offest is greater than the mobile banner (meaning we have scrolled to the bottom to it)
     * used to toggle the z-index of the banner to slide it below the headr on scroll
     */
    isScrolling: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const isOpen = ref(true);
    function close() {
      isOpen.value = false;
      // emit close event to restore header placement after banner removal
      emit('closeBanner');
    }

    return {
      close,
      isOpen,
    };
  },
});
