


































import { defineComponent, watch } from '@nuxtjs/composition-api';
import { useStoreConfig } from '~/features/storeConfig';

export default defineComponent({
  name: 'ModalDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: false,
    },
    disableMobile: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'login',
      validator(value: string) {
        return ['login', 'delete-address', 'wheel', 'flip-and-win'].includes(value);
      },
    },
  },
  setup(props: any, { emit }: any) {
    function close() {
      emit('input', false);
    }
    const { ramadanThemeEnabled } = useStoreConfig();

    watch(
      () => props.value,
      newValue => {
        if (newValue) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      }
    );

    return {
      close,
      ramadanThemeEnabled,
    };
  },
});
