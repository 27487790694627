





















































import { computed, onMounted, ref, watch } from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { CombinedError } from 'villus';
import { useAlerts } from '~/features/alerts';
import { useRequestPasswordResetPhone } from '~/features/auth';
import { useVerifyCustomerInfo } from '~/features/customer';
import { useEventBus } from '~/features/events';
import { useI18n } from '~/features/i18n';
import { useVerifyNationalId } from '~/features/installments';
import { usePhoneVerification, useSendVerficationCode } from '~/features/phoneVerification';
import { handleMoveToNextInput } from '~/utils/events';
import { isEmail } from '~/utils/text';

export default defineComponent({
  name: 'VerifyNationalId',
  components: {
    ValidationObserver,
  },
  model: {
    prop: 'modelValue',
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    alignment: {
      type: String,
      default: 'center',
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    changePhone: {
      type: Boolean,
      default: true,
    },
    passive: {
      type: Boolean,
      default: false,
    },
    /**
     * indicates if we're verifying the edit customer info flows
     */
    customerInfo: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { reSendVerficationCode, isFetching: isSendingVerificationCode } = useSendVerficationCode(props.modelValue);
    const { requestPasswordReset, isFetchingRequestResetPassword } = useRequestPasswordResetPhone();

    const identityString = ref(props.modelValue);
    const isSendingCode = computed(() => isFetchingRequestResetPassword.value || isSendingVerificationCode.value);

    // grouping the otp entered into one string
    const { input, phoneVerification } = usePhoneVerification();
    const { error, success } = useAlerts();
    const { t } = useI18n();
    const { verifyNationalId } = useVerifyNationalId();
    const { verifyCustomer } = useVerifyCustomerInfo();
    const { emit: emitEventBus } = useEventBus();

    const firstInput = ref<HTMLInputElement | null>(null);

    async function handleResendCode() {
      if (isEmail(identityString.value)) {
        await onResendCodeEmail();
        return;
      }
      await onResendCode();
    }

    async function onResendCode() {
      try {
        await reSendVerficationCode(identityString.value);
        success(t('otp').toString(), t('otpCodeSent').toString());
      } catch (err: any) {
        error(t('resendError').toString(), err.message.replace('[GraphQL] ', ''));
      }
    }

    async function onResendCodeEmail() {
      try {
        await requestPasswordReset(identityString.value);
        success(t('otp').toString(), t('otpCodeSent').toString());
      } catch (err: any) {
        error(t('resendError').toString(), err.message.replace('[GraphQL] ', ''));
      }
    }

    onMounted(() => {
      firstInput.value?.focus();
    });

    watch(phoneVerification, async value => {
      if (!value) return;

      if (props.passive) {
        emit('otp', value);
        return;
      }

      try {
        if (props.customerInfo) {
          const { error } = await verifyCustomer({ otp: phoneVerification.value });
          if (error) {
            throw new Error(error.message);
          }
        } else {
          const { error } = await verifyNationalId({ otp: phoneVerification.value });
          if (error) {
            throw new Error(error.message);
          }
        }

        emitEventBus('CLOSE_VERIFY_OTP');
        emitEventBus('ACCOUNT_GOT_VERIFIED');
      } catch (e) {
        error('error', (e as CombinedError).message);
      }
    });

    return {
      handleMoveToNextInput,
      input,
      identityString,
      isSendingCode,
      reSendVerficationCode,
      handleResendCode,
      firstInput,
    };
  },
});
