






























export default {
  name: 'AppButton',
  props: {
    as: {
      type: String,
      default: 'button',
    },
    /**
     * controls different button styles
     * e.g. default, inverted, danger
     */
    variant: {
      type: String,
      default: 'default',
      validator: (value: string) => ['default', 'inverted', 'danger'].includes(value),
    },
    ariaLabel: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * controls the arrow direction of the button
     * expects one of the following values: up-right, down
     */
    arrowDirection: {
      type: String,
      default: 'up-right',
    },
  },
};
