import { Context } from '@nuxt/types';

/**
 * Runs code when the client-side is ready, doesn't run in server-side
 */
export default function ClientInitPlugin({ store }: Context) {
  (window as any).onNuxtReady(() => {
    // initialize cart id
    // if (!store.state.cart.id) {
    //   store.dispatch('cart/fetch');
    // }
  });
}
