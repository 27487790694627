import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { getUser } from '~/features/auth';
/**
 * If cookies doesn't include eliteStore key or user is not logged in then redirect to login page
 */
export default defineNuxtMiddleware(async function EliteUserMiddleware({ redirect, app: { i18n }, req }) {
  const user = await getUser();
  const locale = (i18n as VueI18n).locale;

  if (!user) {
    redirect(`/${locale}/login`);
  }
  // if the cookie is not set, then the user is not elite
  if (process.server && (!req.headers.cookie || !req.headers.cookie.includes('eliteStore'))) {
    redirect(`/${locale}/login`);
  }

  // handle client side cookies
  if (process.client && !document.cookie.includes('eliteStore')) {
    redirect(`/${locale}/login`);
  }
});
