import { useMutation, useQuery } from 'villus';
import { computed, watch, ref, onMounted } from '@nuxtjs/composition-api';
import { useAuth } from './auth';
import { useCartAttributes } from './cart';
import { useEvent, useEventBus } from './events';
import {
  CorporatesInstallmentPlansDocument,
  SetEliteOrderDownPaymentAndPlanOnCartDocument,
  SetEliteOrderDownPaymentMethodOnCartDocument,
  VerifyEliteCartDocument,
} from '~/graphql/EliteCheckout';
import { CorporatePlan, DownPaymentPaymentMethod } from '~/graphql-types.gen';

const useInstallmentPlans = () => {
  const { user } = useAuth();
  const error = ref<string | null>(null);
  const plans = ref<CorporatePlan[]>([]);

  const { execute, isFetching: isFetchingPlans } = useQuery({
    query: CorporatesInstallmentPlansDocument,
    fetchOnMount: false,
  });

  const fetchInstallmentPlans = async (id?: number) => {
    if (!id) {
      error.value = 'No corporate id Found in this user';
      return;
    }

    try {
      const { data, error: e } = await execute();
      if (e) {
        throw new Error(e.message);
      }
      const apiPlans = data?.corporates?.find(corporate => corporate?.id === id)?.plans || [];
      const plans = apiPlans?.filter((plan): plan is CorporatePlan => !!plan?.plan) ?? [];
      if (!plans) {
        error.value = 'No plans found for this corporate';
        throw new Error('No plans found for this corporate');
      }

      return plans;
    } catch (e) {
      error.value = (e as Error).message;
    }
  };

  watch(user, async user => {
    if (user) {
      plans.value = (await fetchInstallmentPlans(user.raya_elite_information?.corporate?.id)) ?? [];
    }
  });

  onMounted(async () => {
    if (user.value) {
      plans.value = (await fetchInstallmentPlans(user.value.raya_elite_information?.corporate?.id)) ?? [];
    }
  });

  const plansWithInstallment = computed(() => {
    return plans.value.map(fromCorporatePlanToInstallmentPlan());
  });

  const isFetching = computed(() => {
    return isFetchingPlans.value;
  });

  return {
    isFetching,
    error,
    plansWithInstallment,
  };
};

type InstallmentPlan = {
  months: number;
  interest: number;
  down_payment: number;
  down_payment_percentage: number;
  amount: number;
};

const fromCorporatePlanToInstallmentPlan = () => (plan: CorporatePlan): InstallmentPlan => {
  return {
    months: plan.plan ?? 0,
    interest: plan.interest_rate ?? 0,
    down_payment: 1,
    down_payment_percentage: 0,
    amount: 0,
  };
};

function useSetEliteOrderDownPaymentAndPlanOnCart() {
  const { execute, isFetching } = useMutation(SetEliteOrderDownPaymentAndPlanOnCartDocument);
  const { cartId } = useCartAttributes();
  const { emit } = useEventBus();
  const { user } = useAuth();

  async function setEliteOrderDownPaymentAndPlanOnCartDocument(downPayment: number, plan: number) {
    try {
      const { error } = await execute({
        cartId: cartId.value,
        downPayment,
        plan,
      });
      if (error) {
        throw new Error(error.message);
      }

      emit('VERIFY_OTP', { phoneNumber: user.value?.phone_number ?? '', verifyElite: true });
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  return { setEliteOrderDownPaymentAndPlanOnCartDocument, isFetching };
}

function useVerifyElite() {
  const { execute, isFetching } = useMutation(VerifyEliteCartDocument);
  const { cartId } = useCartAttributes();
  const success = ref(false);
  const error = ref<string | null>(null);

  async function verifyElite(otp: string) {
    try {
      const { error, data } = await execute({
        otp,
        cartId: cartId.value,
      });
      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (e) {
      throw new Error((e as Error).message);
    }
  }

  useEvent('CLOSE_VERIFY_OTP', () => {
    try {
      success.value = true;
    } catch (e) {
      error.value = (e as Error).message;
    }
  });

  const reset = () => {
    success.value = false;
    error.value = null;
  };

  return { isFetching, error, success, verifyElite, reset };
}

function setEliteOrderDownPaymentMethodOnCartDocument() {
  const { execute } = useMutation(SetEliteOrderDownPaymentMethodOnCartDocument);
  const { cartId } = useCartAttributes();

  async function setPaymentMethod(method: DownPaymentPaymentMethod) {
    const { data } = await execute({
      cartId: cartId.value,
      downPaymentPaymentMethod: method,
    });

    return data.setEliteOrderDownPaymentMethodOnCart?.cart?.id;
  }

  return {
    setPaymentMethod,
  };
}

export {
  useInstallmentPlans,
  useSetEliteOrderDownPaymentAndPlanOnCart,
  useVerifyElite,
  setEliteOrderDownPaymentMethodOnCartDocument,
};
